import React from "react";
import {FallbackRender} from "@sentry/react/types/errorboundary";

export const ErrorFallback: FallbackRender= ({ error, componentStack, resetError }) => {
  return (
    <React.Fragment>
      <div>You have encountered an error</div>
      <div>{error.toString()}</div>
      <div>{componentStack}</div>
      <button
        onClick={() => {
          // todo: should reset the state here
          // this.setState({ message: "This is my app" });

          resetError();
        }}
      >
        Click here to reset!
      </button>
    </React.Fragment>
  );
};
