import "@vseth/vseth-theme/dist/vseth-bootstrap-theme.css";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./locals/i18n";
import "./index.css";
import store from "./app/store";
import {Provider} from "react-redux";
import { createBrowserHistory } from 'history';
import {ErrorFallback} from "./components/ErrorFallback";

// import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();

Sentry.init({
  dsn: "https://1ed6490bd14c4709b782fcf6870d9998@sentry.vseth.ethz.ch/3",
  integrations: [new Integrations.BrowserTracing({
    // Can also use reactRouterV4Instrumentation
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.05,
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
  normalizeDepth: 10,
});

const render = () => {
    const App = require("./app/App").default;
    ReactDOM.render(
      <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
        <Provider store={store}>
            <App />
        </Provider>
      </Sentry.ErrorBoundary>, document.getElementById("root")
    )
}

render();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
