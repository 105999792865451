import React from "react";
import { useTranslation } from "react-i18next";

import { VoteCard } from "../../components/voting/VoteCard";
import {changeToSpecificVote, OpenVote, SavedVote} from "../../slices/votingSlice";
import {useDispatch} from "react-redux";

interface Props {
    openVotes: OpenVote[] | undefined;
    savedVotes: SavedVote[];
}

export const VotingOverviewPage: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="voting__overview">
      <h2 className="pb-4 pt-5">
        {t("votingPage.title")}{" "}
        <span className="voting__subtitle">
          - {t("votingPage.status.inProgress")}
        </span>
      </h2>

      <p>{t("votingPage.generalExplanation")}</p>
      <div className="voting__cards">
        {props.openVotes?.map((vote, i) => (
          <VoteCard
            key={vote.relationId}
            onClick={() => dispatch(changeToSpecificVote(i))}
            openVote={vote}
            savedVote={props.savedVotes[i]}
          />
        ))}
      </div>
    </div>
  );
};
