import {Button} from "@vseth/components";
import React, {useEffect} from "react";
import {useMatomo} from "@jonkoops/matomo-tracker-react/es";

import {LecturerSearch} from "../../components/admin/LecturerSearch";
import {CreatePollPage} from "./CreatePollPage";
import {UpdatePollPage} from "./UpdatePollPage";
import {ImportWinnersPage} from "./ImportWinnersPage";
import {ImportPicturesPage} from "./ImportPicturesPage";

import {
    changeAdminView,
    closeEvaluation,
    fetchEmailEntries,
    fetchJobs,
    fetchPolls,
    Job,
    Poll,
    selectAdminView,
    selectIsAdminStateLoading,
    selectJobs,
    selectPolls,
    selectSelectedJob,
    selectSelectedPoll,
    selectSelectedWinner,
    selectUrlEmailEntries,
    updateJob,
} from "../../slices/adminSlice";
import {useDispatch, useSelector} from "react-redux";
import {UpdateWinnerPage} from "./UpdateWinnerPage";
import {PollsTable} from "../../components/admin/PollsTable";
import {JobsTable} from "../../components/admin/JobsTable";
import {WinnersTable} from "../../components/admin/WinnersTable";
import {JobUpdateAction, PollStatus} from "../../proto/eule_pb";
import {ReloadButton} from "../../components/admin/ReloadButton";
import {deleteWinner, fetchWinners, selectWinners, Winner} from "../../slices/winnersSlice";
import {AppDispatch} from "../../app/store";

// TODO: Add translation

export const AdminPage = () => {
    // track the page view
    const { trackPageView } = useMatomo();
    useEffect( () => {trackPageView({});}, [trackPageView]);
    const dispatch:AppDispatch = useDispatch();

    const view = useSelector(selectAdminView);
    const polls = useSelector(selectPolls);
    const jobs = useSelector(selectJobs);
    const winners = useSelector(selectWinners);
    const urlEntries = useSelector(selectUrlEmailEntries);

    const selectedPoll = useSelector(selectSelectedPoll);
    const selectedJob = useSelector(selectSelectedJob);
    const selectedWinner = useSelector(selectSelectedWinner);

    const isLoading = useSelector(selectIsAdminStateLoading);

    useEffect(() => {
        if (polls === undefined){
            dispatch(fetchPolls());
        }
        if (jobs === undefined && polls !== undefined){
            dispatch(fetchJobs());
        }
        if (urlEntries === undefined && polls !== undefined && jobs !== undefined){
            dispatch(fetchEmailEntries());
        }
        if (winners === undefined && polls!== undefined && jobs !== undefined && urlEntries !== undefined){
            dispatch(fetchWinners());
        }
    }, [dispatch, polls, jobs, winners, urlEntries])

    // Time that refreshes the polls and jobs every 20 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(fetchPolls());
            dispatch(fetchJobs());
        }, 20000)
        return () => clearTimeout(timer);
    }, [dispatch, polls, jobs])

    const downloadEntries = (e: any): void => {
        e.preventDefault();
        window.open(urlEntries);
    }

    const buttonStyle = {justifyContent:'center', marginRight: 10, marginBottom: 10}
    return(
        <>
        {view === "main" && (
            <div className="mt-5 container">
                <h1>Admin</h1>
                <div className="mt-5">
                    <h2>Lecturers</h2>
                    <LecturerSearch/>
                </div>
                <div className="mt-5">
                    <h2>Polls</h2>
                    <p>
                        <i>
                            The evaluation can only be closed if the poll has the status <strong>Evaluation Open.</strong>
                        </i>
                        <br/>
                        <i>
                            <strong>Voters</strong> is only available for the latest poll as database
                            entries for students get deleted when the data of a new poll is imported.
                            If a new poll has status Planned, Voters will wrongly appear as belonging to this poll.
                            It will be fixed as soon as the data is imported and the poll has reached status Poll Ready.
                        </i>
                    </p>
                    <Button size="sm"
                            style={buttonStyle}
                            onClick={ () => {dispatch(changeAdminView("createPoll"))} }>
                        Create poll
                    </Button>
                    <Button size="sm"
                            style={buttonStyle}
                            onClick={ () => {dispatch(changeAdminView("updatePoll"))} }
                            disabled={selectedPoll===undefined}>
                        Update poll (ID: {selectedPoll?.pollId})
                    </Button>
                    <Button size="sm"
                            style={buttonStyle}
                            onClick={ () => {dispatch(closeEvaluation())} }
                            color="danger"
                            outline
                            disabled={selectedPoll===undefined ? true : !(selectedPoll.status === PollStatus.EVALUATION_OPEN)}>
                        Close evaluation
                    </Button>
                    <Button size="sm"
                            style={buttonStyle}
                            onClick={(e) => downloadEntries(e)}
                            disabled = {urlEntries===undefined}
                            >
                        Download email entries
                    </Button>
                    <ReloadButton isLoading={isLoading} reloadAction={() => dispatch(fetchPolls())} />
                    <PollsTable data={polls as Poll[]} isLoading={isLoading}/>
                </div>
                <div className="mt-5">
                    <h2>Jobs</h2>
                    <Button size="sm"
                            style={buttonStyle}
                            onClick={() => {
                                dispatch(updateJob(selectedJob as Job, JobUpdateAction.RESTART));
                                dispatch(fetchJobs());
                            }}
                            disabled={selectedJob===undefined}>
                        Restart job (ID: {selectedJob?.jobId})
                    </Button>
                    <Button size="sm"
                            style={buttonStyle}
                            onClick={() => {
                                dispatch(updateJob(selectedJob as Job, JobUpdateAction.ABORT));
                                dispatch(fetchJobs());
                            }}
                            outline
                            color="danger"
                            disabled={selectedJob===undefined}>
                        Abort job (ID: {selectedJob?.jobId})
                    </Button>
                    <ReloadButton isLoading={isLoading} reloadAction={() => dispatch(fetchJobs())} />
                    <JobsTable data={jobs as Job[]} isLoading={isLoading}/>
                </div>
                <div className="mt-5">
                    <h2>Winners</h2>
                    <Button size="sm"
                            style={buttonStyle}
                            onClick={ () => {dispatch(changeAdminView("importWinners"))} }>
                        Import winners
                    </Button>
                    <Button size="sm"
                            style={buttonStyle}
                            onClick={ () => {dispatch(changeAdminView("importPictures"))} }>
                        Import pictures
                    </Button>
                    <Button size="sm"
                            style={buttonStyle}
                            onClick={ () => {dispatch(changeAdminView("updateWinner"))} }
                            disabled={selectedWinner===undefined}>
                        Update winner (ID: {selectedWinner?.winnerId})
                    </Button>
                    <Button size="sm"
                            style={buttonStyle}
                            onClick={ () => {
                                dispatch(deleteWinner(selectedWinner as Winner));
                                dispatch(fetchWinners());
                            }}
                            color="danger"
                            outline
                            disabled={selectedWinner===undefined}>
                        Delete Winner (ID: {selectedWinner?.winnerId})
                    </Button>
                    <ReloadButton isLoading={isLoading} reloadAction={() => dispatch(fetchWinners())} />
                    <WinnersTable data={winners as Winner[]} isLoading={isLoading}/>
                </div>
            </div>
        )}
        {view === "createPoll" && (<CreatePollPage/>)}
        {view === "updatePoll" && (<UpdatePollPage poll={selectedPoll as Poll}/>)}
        {view === "importWinners" && (<ImportWinnersPage/>)}
        {view === "importPictures" && (<ImportPicturesPage/>)}
        {view === "updateWinner" && (<UpdateWinnerPage winner={selectedWinner as Winner}/>)}
        </>
    )
}
