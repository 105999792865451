import React from "react";
import classnames from "classnames";
import {useTranslation} from "react-i18next";

import {Card} from "@vseth/components";
import {AwardType} from "../proto/eule_pb";
import {Winner} from "../slices/winnersSlice";
import no_avatar from "../images/no_avatar.png";

export const WinnerCard: React.FC<{winner: Winner }> = ({ winner }) => {
    const {t} = useTranslation();

    let url = winner.pictureUrl?.fileUrl;
    if (url===""){
        url = no_avatar
    }

    return (
        <Card className={classnames("professor__card card", {winners__csaward: winner?.award?.awardType === AwardType.CSAWARD})}>
            <img alt={`${winner?.firstName} ${winner?.lastName}`}
                 className="card-img-top"
                 src={url}
                 style={{ backgroundColor: "rgba(0,0,0,0.3)" }}/>
            <div className="card-body">
                <h5 className="">{`${winner?.academicTitle ? winner?.academicTitle : ""} 
                           ${winner?.firstName} ${winner?.lastName}`}</h5>
                <span className="">{winner?.department}</span>
                <br />
                {winner?.award?.awardType === AwardType.CSAWARD ? (
                    <div className="winners__badge winners__badge--csaward">

                        <span>CS Award</span>
                    </div>
                ) : (
                    <div className="winners__badge winners__badge--eule">
            <span>
                {t("app.name")}
            </span>
                    </div>
                )}
            </div>
        </Card>
    );
}
