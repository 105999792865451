const i18n_german = {
    translation: {
        lang: "de",
        app: {
            name: "Goldene Eule",
        },
        general: {
            home: "Startseite",
            pleaseLogIn: "Melde dich bitte an, um diese App zu benutzen",
        },
        landingPage: {
            voteNow: "Jetzt abstimmen",
            title: "Goldene Eule",
            titleExplanation: "Was ist die Goldene Eule?",
            textExplanation: "Hier geht es zur Abstimmung!",
            titleEule: "Was ist die Goldene Eule?",
            textEule: "Die Goldene Eule zeichnet besonders engagierte Lehrpersonen aus, die ihren Studierenden eine exzellente Lehre bieten. Verliehen wird der Preis seit seiner Initiierung vom Verband der Studierenden an der ETH Zürich (VSETH). Sämtliche ETH-Angehörige mit einem Lehrauftrag können die Goldene Eule gewinnen. Pro Departement wird eine Lehrperson ausgezeichnet. Ab Mitte Mai werden alle Studierenden in einer anonymen Online-Umfrage des VSETH zu ihren Dozierenden der zwei letzten Semester befragt. Die Resultate werden den Fachvereinen zugestellt, welche unter Berücksichtigung weiterer Kriterien die Gewinner*innen auswählen. Die Gewinner*innen werden am ETH-Tag im folgenden November vom VSETH-Präsidium ausgezeichnet.",
            titleCS: "Was ist der CS Award?",
            textCS: "Der Credit Suisse Award for Best Teaching würdigt besonders innovative und engagierte Lehre. Alle Dozierenden, welche schon einmal eine Goldene Eule gewonnen haben oder noch im selben Jahr eine gewinnen, können mit diesem Preis ausgezeichnet werden. Eine einzige Person wird jedes Jahr mit dem Credit Suisse Award ausgezeichnet. Fachvereine können eine Person aus ihrem Departement nominieren. Der VSETH-Vorstand entscheidet anhand mehrerer Kriterien, wer gewinnt. Der Preis wird am ETH-Tag überreicht.",
            moreInfoTitle: "Weitere Informationen",
            moreInfoText: "",
            picturesLink: "Bilder der Preisverleihung",
            ethLink: "ETH-Seite für die Goldene Eule",
            ethURL: "https://www.ethz.ch/de/die-eth-zuerich/lehre/auszeichnungen-und-preise/goldene-eule.html",
            pictureURL: "https://www.skyfish.com/p/eth-tag/1369795",
        },
        votingPage: {
            title: "Abstimmung",
            generalExplanation: "Klicke eine Karte an, um jemanden zu bewerten. Drücke auf «Absenden», wenn du alle Dozierenden bewertet hast, die du bewerten willst. Das Absenden kann nicht mehr rückgängig gemacht werden. Deine Stimmen werden dann anonym gespeichert.",
            status: {
                inProgress: "Läuft",
            },
            actions: {
                submitVoting: "Absenden",
            },
            confirmText: "Du hast noch nicht überall abgestimmt. Bist du dir sicher, dass du deine Stimmen so absenden willst? Du kannst danach nicht mehr abstimmen.",
        },
        singleVote: {
            generalExplanation: "Bewerte diese*n Dozierende*n:",
            commentExplanation: "Bitte schreibe einen Kommentar! Kommentare helfen sehr beim Auswählen der Gewinnenden.",

            comment: "Kommentar",
            helpText: "Wir bitten dich, Kommentare zu unterlassen, welche die Persönlichkeit oder Ehre der Dozierenden verletzen.",
            actions: {
                backToOverview: "Zurück zur Übersicht",
            },
        },
        formerWinners: {
            title: "Ehemalige Eulen",
            thankYou: "Dies sind alle Dozierende, die in der Vergangenheit mit einer Goldenen Eule für ihre ausserordentlichen Leistungen in der Lehre ausgezeichnet wurden. Wir sind ihnen sehr dankbar für ihr Engagement für die Studierenden.",
            winners: "Gewinnerinnen und Gewinner",
        },
        countdown: {
            starts_day: "Abstimmung startet in einem Tag.",
            starts_day_plural: "Abstimmung startet in {{days}} Tagen.",
            starts_hour: "Abstimmung startet in {{hours}}:{{minutes}}:{{seconds}}",
            ends_day: "Abstimmung endet in einem Tag.",
            ends_day_plural: "Abstimmung endet in {{days}} Tagen.",
            ends_hour: "Abstimmung endet in {{hours}}:{{minutes}}:{{seconds}}",
        },
        waitForResult: {
            title: "Die Abstimmung ist vorbei",
            text: "Wir werden die Resultate am ETH Tag im November verkünden.",
        },
        successVoting: {
            title: "Vielen Dank für deine Stimme",
            text: "Wir werden die Resultate am ETH Tag im November verkünden",
        },
        notEligible: {
            title: "Es scheint, als gäbe es nichts Abzustimmen",
            text: "Hast du die letzten 2 Semester Vorlesungen belegt?",
            text2: "Falls du denkst, dass dies ein Fehler ist, dann schreibe bitte eine Email an ",
        },
        getNotified: {
            text: "Würdest du gerne per E-Mail über die Resultate benachrichtigt werden",
            button: "E-Mail erhalten",
        },
        impress: {
            titleMatomo: "Datenschutzerklärung für die Nutzung von Matomo",
            textMatomo: "Wir setzen eine selbst betriebene Instanz von Matomo (https://matomo.org) ein. Mittels Matomo koennen wir grundlegende Analytics Daten wie Page View analysieren. Ziel ist es zu verstehen wo Probleme in der Bedienbarkeit unser Website liegen, um diese Aspekte für die Zukunft zu verbessen. Die Daten stehen innerhalb des VSETH nur einem kleinen Personenkreis zur Verfügung und werden ausschliesslich zur Verbesserung der Bedienbarkeit unserer zukünftigen Web-Applikationen verwendet.",
            placeholderText: `Placeholder image by https://commons.wikimedia.org/wiki/User:Dianakc under license https://creativecommons.org/licenses/by-sa/3.0/, image was cropped`,
        },
        successfullySubscribed: {
            title: "Wir werden dich benachrichtigen",
            text: "Du wirst eine Mail erhalten, nachdem wir die Gewinner*innen bekannt gegeben haben.",
            button: "Zurück zur Hauptseite",
        },
        footer: {
            privacy: "Datenschutz",
            impressum: "Impressum",
        },

        adminPage: {
            goBack: "Go back",
            submit: "Submit",

            generalParameters: "General parameters",
            year: "Year",
            yearRequirements: "Requirements: must be at least this year",
            phdsAllowed: "PhDs students are allowed to vote",
            startDateTimeTitle: "Start date and time",
            startDateTimeRequirements: "Requirements: Must be at least this year",
            endDateTimeTitle: "End date and time",
            endDateTimeRequirements: "Requirements: Must be after the start date and time",
            announcementDateTimeTitle: "Winners announcement date and time",
            announcementDateTimeRequirements: "Requirements: should be after the ETH day ceremony, you must check the date yourself.",
            date: "Date",
            time: "Time",

            warnings: {
                year: "Year entered must be at least ",
            },

            createPoll: {
                title: "Create a new poll",
                expectedFileFormat: {
                    title: "Expected file format",
                    description: "The file must be a .csv file encoded in UTF-8. All columns listed below must be present and described in a header at the top of the file. " +
                        "If the header is missing, you must add it yourself and/or complain to ETH. Any other column than described will simply be ignored. " +
                        "As the file is uploaded to a cloud storage instance, it is advised to leave as little additional information as possible for privacy reasons.",
                },
                pollCreatedMessage: "Poll created! The file upload may take a few minutes, please be patient. Do not close your browser to be safe.",
                headerFormat: {
                    semester: "shorthand code of semester (e.g. \"FS 2064\")",
                    departmentLecturer: "shorthand code of department of lecturer (e.g. \"D-INFK\")",
                    dozide: "lecturer id used by ETH (e.g. 1000127), must be a number",
                    academicTitleLecturer: "academic title of lecturer (e.g. \"Prof. Dr.\", \"Dr.\" or empty)",
                    surnameLecturer: "surname of lecturer (e.g. \"Doe\")",
                    nameLecturer: "name of lecturer (e.g. \"John\")",
                    codeLecture: "ETH lecture code (e.g. \"123-1230-1234\")",
                    typeLecture: "ETH lecture type: [\"V\", \"G\", \"S\", \"K\", \"P\"], others are ignored",
                    titleLecture: "title of lecture (e.g. \"Owl Flight Mechanics\")",
                    nethz: "ETH username of student (e.g. \"owlg\")",
                    semestersStudent: "number of semesters (e.g. 7) spent as",
                    departmentStudent: "shorthand code of department of student (e.g. \"D-MATH\")",
                    statusStudent: "status of student within ETH (e.g. 1 for Bachelor, 2 for Master, etc.), must be a number",
                    statusStudentDesc: "description of HOERER_STATUS (e.g. \"Studierender\" if HOERER_STATUS is 1), this column is not needed but helpful to see if the HOERER_STATUS makes sense",
                },
                attendanceData: "Attendance data CSV",
                attendanceDataRequirements: "Requirements: see \"Expected file format\"",
            },

        },

        resultsPage: {
            title: "Ergebnisse",
            department: "Departement",
            year: "Jahr",
            nothingToShow: "Nichts zum anzeigen",
            forPoll: " für die Abstimmung",
            currentlySelectedWinner: "Derzeit ausgewählt",
            noWinnerSelected: " Niemand",
            selectForEule: "Für Eule auswählen",
            totalVotes: "Anzahl Stimmen",
            averageGrade: "Dursch. Note",
            ranking: "Rang am",
            rankingETH: "Rang an der ETH",
            byStatus: "Pro Status",
            byDepartment: "Pro Departement",
            wins: "Auszeichnungen",
            noWins: "Keine",
            lectures: "Vorlesungen",

            votes: "Stimmen",
            comments: "Kommentare",
            noComments: "Keine Kommentare",
            averageByDept: "Dursch. pro Departement",
            averageByStatus: "Dursch. pro Status",
            votesByDept: "Anzahl Stimmen pro Departement",
            votesByStatus: "Anzahl Stimmen pro Status"
        },
    },
};

export default i18n_german;
