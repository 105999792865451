import React from "react";
import {useDispatch} from "react-redux";
import {Button, Form, InputField} from "@vseth/components";

import { fetchWinners, importWinners } from "../../slices/winnersSlice"
import { changeAdminView } from "../../slices/adminSlice";
import {AppDispatch} from "../../app/store";

export const ImportWinnersPage = () => {
    const dispatch:AppDispatch = useDispatch();

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        const filesHtml = document.getElementById("files") as HTMLInputElement
        if (filesHtml != null) {
            const filesList = filesHtml.files
            if (filesList != null) {
                let file = filesList[0];
                dispatch(importWinners(file));
            }
        }
        dispatch(fetchWinners());
        window.alert("Winners imported!");
        dispatch(changeAdminView("main"));
    }

    return (
        <div className="mt-5 container">
            <h2>Import winners</h2>

            <h5>Expected file format</h5>
            <p>
                The file must be a .csv file encoded in UTF-8. All columns listed below must be present. Any other column will simply be ignored.
                As the file is uploaded to a cloud storage instance, it is advised to leave as little additional information as possible for privacy reasons. <br/><br/>
                <code>YEAR</code>: year of award, (e.g. 2020)<br/>
                <code>AWARD</code>: award won by lecturer, must be "csaward" or "goldene-eule"<br/>
                <code>DOZ_DEPT</code>: shorthand code of department of lecturer (e.g. "D-INFK")<br/>
                <code>DOZIDE</code>: lecturer id used by ETH (e.g. 1000127)<br/>
                <code>DOZ_ACA_TITLE</code>: academic title of lecturer (e.g. "Prof. Dr.")<br/>
                <code>DOZ_NAME</code>: first name of lecturer (e.g. "John")<br/>
                <code>DOZ_SURNAME</code>: surname of lecturer (e.g. "Doe")<br/>
                <code>DOZ_PICTURE_CONSENT</code>: whether the lecturer consented to have their picture shown, must be TRUE or FALSE<br/>
            </p>

            <h5>Import</h5>
            <Form style={{textAlign: "left"}} onSubmit={(e) => handleSubmit(e)}>
                <InputField type="file" id="files" accept=".csv" label="Winner CSV (see requirements above)"/>
                <Button color="primary" type="submit" className="btn btn-primary" style={{justifyContent:'center'}}>Submit</Button>
                <Button onClick={() => { dispatch(changeAdminView("main"));} } style={{justifyContent:'center', margin: 3}}>Go back</Button>
            </Form>

            <br/>
        </div>
    )
}

