import React from "react";
import { useTranslation } from "react-i18next";
import { GetNotified } from "./GetNotified";
import {Centered} from "../Centered";

// Message that the user is not allowed to vote and whether they want to be notified
export const NotEligible: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Centered>
      <h3 className="mb-5">{t("notEligible.title")}</h3>

      <p>{t("notEligible.text")}</p>
      <p>
        {t("notEligible.text2")}
        <a href="mailto:vseth-it-support@ethz.ch">vseth-it-support@ethz.ch</a>.
      </p>
      <GetNotified />
    </Centered>
  );
};
