import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {AppThunk} from "../app/store";
import {selectAccessToken} from "./authSlice";
import {
    Award,
    AwardType,
    CreateWinnersRequest,
    DeleteWinnerRequest,
    GetFileUploadUrlRequest,
    ListWinnersRequest,
    UpdateWinnerRequest,
    Winner as WinnerMessage
} from "../proto/eule_pb"
import {getAuthMetadata} from "../util/proto";

export type Winner = WinnerMessage.AsObject;

interface WinnerState{
    winners? : Winner[];
    isLoading: boolean;
    error?: Error;
}

export const initialState: WinnerState = {
    winners: undefined,
    isLoading: false,
    error: undefined,
}

const winners = createSlice({
    name: "winners",
    initialState,
    reducers: {
        listWinnersStart: (state) => {
            state.isLoading = true;
        },
        listWinnersSuccess: (state, { payload }: PayloadAction<Winner[]>) => {
            state.winners = payload;
            state.isLoading = false;
            state.error = undefined;
        },
        listWinnersFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload;
            state.isLoading = false;
        },

        importWinnersStart: (state) => {
            state.isLoading = false;
        },
        importWinnersSuccess: (state) => {
            state.isLoading = false;
            state.error = undefined;
        },
        importWinnersFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        updateWinnerStart: (state) => {
            state.isLoading = false;
        },
        updateWinnerSuccess: (state) => {
            state.isLoading = false;
            state.error = undefined;
        },
        updateWinnerFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        deleteWinnerStart: (state) => {
            state.isLoading = false;
        },
        deleteWinnerSuccess: (state) => {
            state.isLoading = false;
            state.error = undefined;
        },
        deleteWinnerFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        importPicturesStart: (state) => {
            state.isLoading = false;
        },
        importPicturesSuccess: (state) => {
            state.isLoading = false;
            state.error = undefined;
        },
        importPicturesFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

const {
    listWinnersStart,
    listWinnersSuccess,
    listWinnersFailure,

    importWinnersStart,
    importWinnersSuccess,
    importWinnersFailure,

    importPicturesStart,
    importPicturesSuccess,
    importPicturesFailure,

    updateWinnerStart,
    updateWinnerSuccess,
    updateWinnerFailure,

    deleteWinnerStart,
    deleteWinnerSuccess,
    deleteWinnerFailure,
} = winners.actions;

export default winners.reducer;

// Actions
export const fetchWinners = (): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(listWinnersStart());
    const state = getState();
    const token = selectAccessToken(state);
    const request = new ListWinnersRequest();
    return euleClient.listWinners(request, getAuthMetadata(token))
        .then((winners) => {
            dispatch(
                listWinnersSuccess(
                    winners.getWinnersList().map((w) => w.toObject())
                )
            );
        })
        .catch((err) => {dispatch(listWinnersFailure(err));});
}

export const importWinners = (file: File): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(importWinnersStart());
    const state = getState();
    const token = selectAccessToken(state);
    const metadata = getAuthMetadata(token);

    let request = new GetFileUploadUrlRequest();
    request.setFileName("winners_import.csv");
    return euleClient.getFileUploadUrl(request, metadata)
        .then((url) => {
            fetch(url.getFileUrl(), {method: "PUT", body: file})
                .then((answer) => {
                    console.log("Request for file " + file.name + " answered with " + answer.status);
                    euleClient.createWinners(new CreateWinnersRequest(), metadata).then(() => {
                        dispatch(importWinnersSuccess());
                    })
                        .catch((err) => {
                            dispatch(importWinnersFailure(err));

                        });
                })
                .catch((err) => {
                    dispatch(importWinnersFailure(err));
                });
        })
        .catch((err) => {
            dispatch(importWinnersFailure(err));
        });
}

export const importPictures = (filesList: FileList): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(importPicturesStart());
    const state = getState();
    const token = selectAccessToken(state);

    for(let i = 0; i < filesList.length; i++) {
        let file = filesList[i]
        console.log("Reading file " + i as string)

        let request = new GetFileUploadUrlRequest();
        request.setFileName(file.name);
        euleClient.getFileUploadUrl(request, getAuthMetadata(token))
            .then((url) => {
                fetch(url.getFileUrl(), {method: "PUT", body: file})
                    .then((answer) => {console.log("Request for file " + file.name + " answered with " + answer.status)})
                    .catch((err) => {
                        return dispatch(importPicturesFailure(err));
                    });
            })
            .catch((err) => {
                return dispatch(importPicturesFailure(err));
            });
    }
    return dispatch(importPicturesSuccess());
}

export const updateWinner = (winnerId: number,
                             lecturerId: number,
                             year: number,
                             awardType: string,
                             department: string): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(updateWinnerStart());
    const state = getState();
    const token = selectAccessToken(state);

    let request = new UpdateWinnerRequest();
    request.setWinnerId(winnerId);
    request.setLecturerId(lecturerId);
    request.setDepartment(department);

    let award = new Award();

    award.setYear(year);
    if(awardType === "golden-eule"){
        award.setAwardType(AwardType.EULE);
    } else{
        award.setAwardType(AwardType.CSAWARD);
    }
    request.setAward(award);

    return euleClient.updateWinner(request, getAuthMetadata(token))
        .then(() => {
            dispatch(updateWinnerSuccess());
        })
        .catch((err) => {
            dispatch(updateWinnerFailure(err));
        });
}

export const deleteWinner = (winner: Winner): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(deleteWinnerStart());
    const state = getState();
    const token = selectAccessToken(state);

    let request = new DeleteWinnerRequest();
    request.setWinnerId(winner.winnerId);
    return euleClient.deleteWinner(request, getAuthMetadata(token))
        .then(() => {
            dispatch(deleteWinnerSuccess());
        })
        .catch((err) => {
            dispatch(deleteWinnerFailure(err));
        });
}

// Selectors
type WinnersSliceRoot = { winners: ReturnType<typeof winners.reducer> };

export const selectIsLoading = (state: WinnersSliceRoot) => state.winners.isLoading;
export const selectWinners = (state: WinnersSliceRoot) => state.winners.winners;
export const selectError = (state: WinnersSliceRoot) => state.winners.error;
