import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../app/store";
import {selectAccessToken} from "./authSlice";
import {getAuthMetadata} from "../util/proto";
import {
    ListDepartmentsRequest,
    CreateTemporaryWinnerRequest,
    GetResultsDepartmentRequest,
    ResultsDepartment, AwardType
} from "../proto/eule_pb"
import {Winner} from "./winnersSlice";


interface ResultsState{
    departments?: string[];
    results?: any;
    temporaryEuleWinner?: any;
    currentDepartment?: string;
    currentPoll?: number;
    currentLecturerId?: number,
    currentLecturer?: {},
    currentLectureId?: number,
    currentLecture?: {},
    isLoading: boolean;
    error?: Error;
}

export const initialState: ResultsState = {
    departments: undefined,
    results: undefined,
    temporaryEuleWinner: undefined,
    currentDepartment: undefined,
    currentPoll: undefined,
    currentLecturerId: undefined,
    currentLecturer: undefined,
    currentLectureId: undefined,
    currentLecture: undefined,
    isLoading: false,
    error: undefined,
}

const results = createSlice({
    name: "results",
    initialState,
    reducers: {
        getDepartmentsStart: (state) => {
            state.isLoading = true;
            state.error = undefined;
        },
        getDepartmentsSuccess: (state, { payload }: PayloadAction<string[]>) => {
            state.departments = payload;
            state.isLoading = false;
            state.error = undefined;
        },
        getDepartmentsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        getResultsDepartmentStart: (state) => {
            state.isLoading = true;
            state.error = undefined;
        },
        getResultsDepartmentSuccess: (state, { payload }: PayloadAction<ResultsDepartment>) => {
            state.error = undefined;
            let data = JSON.parse(payload.getRawData());
            state.results = data;
            // Set default lecturer and lecture values
            state.currentLecturerId = data.lecturerResult[0].id;
            state.currentLecturer = data.lecturerResult[0];
            state.currentLectureId = data.lecturerResult[0].lectures[0].id;
            state.currentLecture = data.lecturerResult[0].lectures[0];
            state.temporaryEuleWinner = data.winnerSubmitted;
            state.isLoading = false;
        },
        getResultsDepartmentFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        submitTemporaryWinnerStart: (state) => {
            state.isLoading = true;
            state.error = undefined;
        },
        submitTemporaryWinnerSuccess: (state, { payload }: PayloadAction<Winner>) => {
            state.temporaryEuleWinner = payload.lecturerId;
            state.isLoading = false;
            state.error = undefined;
        },
        submitTemporaryWinnerFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        setCurrentDepartment: (state, {payload}: PayloadAction<string>) => {
            state.currentDepartment = payload;
        },
        setCurrentPoll: (state, {payload}: PayloadAction<number>) => {
            state.currentPoll = payload;
        },
        setCurrentLecturer: (state, {payload}: PayloadAction<any>) => {
            state.currentLecturerId = payload.id;
            state.currentLecturer = payload;
            state.currentLectureId = payload.lectures[0].id;
            state.currentLecture = payload.lectures[0];
        },
        setCurrentLecture: (state, {payload}: PayloadAction<any>) => {
            state.currentLectureId = payload.id;
            state.currentLecture = payload;
        }

    },
});

const {
    getDepartmentsStart,
    getDepartmentsSuccess,
    getDepartmentsFailure,

    getResultsDepartmentStart,
    getResultsDepartmentSuccess,
    getResultsDepartmentFailure,

    submitTemporaryWinnerStart,
    submitTemporaryWinnerSuccess,
    submitTemporaryWinnerFailure,

    setCurrentDepartment,
    setCurrentPoll,
    setCurrentLecturer,
    setCurrentLecture,
} = results.actions;

export default results.reducer;

// Actions
export const getDepartments = (): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(getDepartmentsStart());
    const state = getState();
    const token = selectAccessToken(state);

    let request = new ListDepartmentsRequest();
    return euleClient.listDepartments(request, getAuthMetadata(token))
        .then((listDepartments) => {
            dispatch(getDepartmentsSuccess(listDepartments.getDepartmentsList()));
        })
        .catch((err) => {
            const serializableError = {
                message: err.message,
                name: err.name,
            };
            dispatch(getDepartmentsFailure(serializableError));
        });
};

export const getResultDepartment = (department: string, pollId: number): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(getResultsDepartmentStart());
    const state = getState();
    const token = selectAccessToken(state);

    let request = new GetResultsDepartmentRequest();
    request.setPollId(pollId);
    request.setDepartment(department);
    request.setGetCurrent(false);
    return euleClient.getResultsDepartment(request, getAuthMetadata(token))
        .then((result) => {
            dispatch(getResultsDepartmentSuccess(result));
        })
        .catch((err) => {
            dispatch(getResultsDepartmentFailure(err));
        });
}

export const submitTemporaryWinner = (department: string,
                                      lecturerId: number,
                                      award: AwardType): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(submitTemporaryWinnerStart());
    const state = getState();
    const token = selectAccessToken(state);

    let request = new CreateTemporaryWinnerRequest();
    request.setAwardType(award);
    request.setDepartment(department);
    request.setLecturerId(lecturerId);

    return euleClient.createTemporaryWinner(request, getAuthMetadata(token))
        .then((winner) => {
            dispatch(submitTemporaryWinnerSuccess(winner.toObject()));
        })
        .catch((err) => {
            dispatch(submitTemporaryWinnerFailure(err));
        });
}

export {
    setCurrentDepartment,
    setCurrentPoll,
    setCurrentLecturer,
    setCurrentLecture,
}

type ResultsSliceRoot = {results: ReturnType<typeof results.reducer>};

export const selectDepartments = (state: ResultsSliceRoot) => state.results.departments;
export const selectSelectedEuleWinner = (state: ResultsSliceRoot) => state.results.temporaryEuleWinner;
export const selectResults = (state: ResultsSliceRoot) => state.results.results;
export const selectResultsError = (state: ResultsSliceRoot) => state.results.error;
export const selectCurrentDepartment = (state: ResultsSliceRoot) => state.results.currentDepartment;
export const selectCurrentPollId = (state: ResultsSliceRoot) => state.results.currentPoll;
export const selectCurrentLecturer = (state: ResultsSliceRoot) => state.results.currentLecturer;
export const selectCurrentLecture = (state: ResultsSliceRoot) => state.results.currentLecture;
export const selectCurrentLecturerId = (state: ResultsSliceRoot) => state.results.currentLecturerId;
export const selectCurrentLectureId = (state: ResultsSliceRoot) => state.results.currentLectureId;
export const selectIsResultsStateLoading = (state: ResultsSliceRoot) => state.results.isLoading;