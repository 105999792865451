import React from "react";
import {useTranslation} from "react-i18next";
import {Winner} from "../../slices/winnersSlice";
import {WinnerCard} from "../../components/WinnerCard"
import {AwardType} from "../../proto/eule_pb";

export const WinnersYearSection: React.FC<{ year: string; winners: Winner[]; }> = ({ year, winners }) => {
  const { t } = useTranslation();
  const csAwardWinner = winners.filter((winner: Winner) => winner.award?.awardType === AwardType.CSAWARD)[0];
  let euleWinners = winners.filter((winner: Winner) => winner.award?.awardType === AwardType.EULE);
  euleWinners = euleWinners.sort((first, second) => first.department.localeCompare(second.department));

  return (
    <>
      <h2 className="mb-4 mt-5">
        {t("formerWinners.winners")} {year}
      </h2>
      <div className="professor__cards">
          {csAwardWinner && <WinnerCard key={0} winner={csAwardWinner}/>}
        {euleWinners.map((professor, i) => (<WinnerCard key={i} winner={professor} />))}
      </div>
    </>
  );
};
