/* eslint-disable */ 
// source: eule.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.vseth.sip.eule.Answer', null, global);
goog.exportSymbol('proto.vseth.sip.eule.Award', null, global);
goog.exportSymbol('proto.vseth.sip.eule.AwardType', null, global);
goog.exportSymbol('proto.vseth.sip.eule.CloseEvaluationRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.CreateNotificationMailEntryRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.CreatePollRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.CreateTemporaryWinnerRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.CreateVotesRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.CreateWinnersRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.CreatedVotes', null, global);
goog.exportSymbol('proto.vseth.sip.eule.DeleteWinnerRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.FileUrl', null, global);
goog.exportSymbol('proto.vseth.sip.eule.GetFileDownloadUrlRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.GetFileUploadUrlRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.GetJobRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.GetLecturerRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.GetNotificationMailEntriesRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.GetPollRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.GetResultsDepartmentRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.GetVotingDataRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.Job', null, global);
goog.exportSymbol('proto.vseth.sip.eule.JobName', null, global);
goog.exportSymbol('proto.vseth.sip.eule.JobStatus', null, global);
goog.exportSymbol('proto.vseth.sip.eule.JobUpdateAction', null, global);
goog.exportSymbol('proto.vseth.sip.eule.Lecture', null, global);
goog.exportSymbol('proto.vseth.sip.eule.Lecturer', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ListDepartmentsRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ListDepartmentsResponse', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ListJobsRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ListJobsResponse', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ListLecturersRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ListLecturersResponse', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ListPollsRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ListPollsResponse', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ListWinnersRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ListWinnersResponse', null, global);
goog.exportSymbol('proto.vseth.sip.eule.NotificationMailEntry', null, global);
goog.exportSymbol('proto.vseth.sip.eule.OpenVote', null, global);
goog.exportSymbol('proto.vseth.sip.eule.Poll', null, global);
goog.exportSymbol('proto.vseth.sip.eule.PollStatus', null, global);
goog.exportSymbol('proto.vseth.sip.eule.PollTimestampLabel', null, global);
goog.exportSymbol('proto.vseth.sip.eule.ResultsDepartment', null, global);
goog.exportSymbol('proto.vseth.sip.eule.UpdateJobRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.UpdatePollRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.UpdateWinnerRequest', null, global);
goog.exportSymbol('proto.vseth.sip.eule.Vote', null, global);
goog.exportSymbol('proto.vseth.sip.eule.VotingData', null, global);
goog.exportSymbol('proto.vseth.sip.eule.Winner', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.GetJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.GetJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.GetJobRequest.displayName = 'proto.vseth.sip.eule.GetJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.Job.displayName = 'proto.vseth.sip.eule.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ListJobsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.ListJobsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ListJobsRequest.displayName = 'proto.vseth.sip.eule.ListJobsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ListJobsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.eule.ListJobsResponse.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.eule.ListJobsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ListJobsResponse.displayName = 'proto.vseth.sip.eule.ListJobsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.UpdateJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.UpdateJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.UpdateJobRequest.displayName = 'proto.vseth.sip.eule.UpdateJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.GetLecturerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.GetLecturerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.GetLecturerRequest.displayName = 'proto.vseth.sip.eule.GetLecturerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.Lecture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.Lecture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.Lecture.displayName = 'proto.vseth.sip.eule.Lecture';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.Lecturer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.eule.Lecturer.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.eule.Lecturer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.Lecturer.displayName = 'proto.vseth.sip.eule.Lecturer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ListLecturersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.ListLecturersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ListLecturersRequest.displayName = 'proto.vseth.sip.eule.ListLecturersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ListLecturersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.eule.ListLecturersResponse.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.eule.ListLecturersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ListLecturersResponse.displayName = 'proto.vseth.sip.eule.ListLecturersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.GetPollRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.GetPollRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.GetPollRequest.displayName = 'proto.vseth.sip.eule.GetPollRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.Poll = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.Poll, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.Poll.displayName = 'proto.vseth.sip.eule.Poll';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ListPollsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.ListPollsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ListPollsRequest.displayName = 'proto.vseth.sip.eule.ListPollsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ListPollsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.eule.ListPollsResponse.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.eule.ListPollsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ListPollsResponse.displayName = 'proto.vseth.sip.eule.ListPollsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.CreatePollRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.CreatePollRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.CreatePollRequest.displayName = 'proto.vseth.sip.eule.CreatePollRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.UpdatePollRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.UpdatePollRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.UpdatePollRequest.displayName = 'proto.vseth.sip.eule.UpdatePollRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.CloseEvaluationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.CloseEvaluationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.CloseEvaluationRequest.displayName = 'proto.vseth.sip.eule.CloseEvaluationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.GetFileUploadUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.GetFileUploadUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.GetFileUploadUrlRequest.displayName = 'proto.vseth.sip.eule.GetFileUploadUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.GetFileDownloadUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.GetFileDownloadUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.GetFileDownloadUrlRequest.displayName = 'proto.vseth.sip.eule.GetFileDownloadUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.FileUrl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.FileUrl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.FileUrl.displayName = 'proto.vseth.sip.eule.FileUrl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.GetVotingDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.GetVotingDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.GetVotingDataRequest.displayName = 'proto.vseth.sip.eule.GetVotingDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.VotingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.eule.VotingData.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.eule.VotingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.VotingData.displayName = 'proto.vseth.sip.eule.VotingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.OpenVote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.OpenVote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.OpenVote.displayName = 'proto.vseth.sip.eule.OpenVote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.CreateVotesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.eule.CreateVotesRequest.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.eule.CreateVotesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.CreateVotesRequest.displayName = 'proto.vseth.sip.eule.CreateVotesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.Vote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.eule.Vote.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.eule.Vote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.Vote.displayName = 'proto.vseth.sip.eule.Vote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.CreatedVotes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.eule.CreatedVotes.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.eule.CreatedVotes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.CreatedVotes.displayName = 'proto.vseth.sip.eule.CreatedVotes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.Answer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.Answer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.Answer.displayName = 'proto.vseth.sip.eule.Answer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.CreateNotificationMailEntryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.CreateNotificationMailEntryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.CreateNotificationMailEntryRequest.displayName = 'proto.vseth.sip.eule.CreateNotificationMailEntryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.GetNotificationMailEntriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.GetNotificationMailEntriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.GetNotificationMailEntriesRequest.displayName = 'proto.vseth.sip.eule.GetNotificationMailEntriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.NotificationMailEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.NotificationMailEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.NotificationMailEntry.displayName = 'proto.vseth.sip.eule.NotificationMailEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ListWinnersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.ListWinnersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ListWinnersRequest.displayName = 'proto.vseth.sip.eule.ListWinnersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.Award = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.Award, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.Award.displayName = 'proto.vseth.sip.eule.Award';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.Winner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.Winner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.Winner.displayName = 'proto.vseth.sip.eule.Winner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ListWinnersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.eule.ListWinnersResponse.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.eule.ListWinnersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ListWinnersResponse.displayName = 'proto.vseth.sip.eule.ListWinnersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.CreateWinnersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.CreateWinnersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.CreateWinnersRequest.displayName = 'proto.vseth.sip.eule.CreateWinnersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.UpdateWinnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.UpdateWinnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.UpdateWinnerRequest.displayName = 'proto.vseth.sip.eule.UpdateWinnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.DeleteWinnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.DeleteWinnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.DeleteWinnerRequest.displayName = 'proto.vseth.sip.eule.DeleteWinnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.CreateTemporaryWinnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.CreateTemporaryWinnerRequest.displayName = 'proto.vseth.sip.eule.CreateTemporaryWinnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ListDepartmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.ListDepartmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ListDepartmentsRequest.displayName = 'proto.vseth.sip.eule.ListDepartmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ListDepartmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.eule.ListDepartmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.eule.ListDepartmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ListDepartmentsResponse.displayName = 'proto.vseth.sip.eule.ListDepartmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.GetResultsDepartmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.GetResultsDepartmentRequest.displayName = 'proto.vseth.sip.eule.GetResultsDepartmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.eule.ResultsDepartment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.eule.ResultsDepartment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.eule.ResultsDepartment.displayName = 'proto.vseth.sip.eule.ResultsDepartment';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.GetJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.GetJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.GetJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    getCurrent: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.GetJobRequest}
 */
proto.vseth.sip.eule.GetJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.GetJobRequest;
  return proto.vseth.sip.eule.GetJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.GetJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.GetJobRequest}
 */
proto.vseth.sip.eule.GetJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.GetJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.GetJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.GetJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGetCurrent();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 job_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.GetJobRequest.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.GetJobRequest} returns this
 */
proto.vseth.sip.eule.GetJobRequest.prototype.setJobId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool get_current = 2;
 * @return {boolean}
 */
proto.vseth.sip.eule.GetJobRequest.prototype.getGetCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.eule.GetJobRequest} returns this
 */
proto.vseth.sip.eule.GetJobRequest.prototype.setGetCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jobName: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    logMessage: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pollId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pollStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    pollTimestampLabel: jspb.Message.getFieldWithDefault(msg, 9, 0),
    argument: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.Job}
 */
proto.vseth.sip.eule.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.Job;
  return proto.vseth.sip.eule.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.Job}
 */
proto.vseth.sip.eule.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 2:
      var value = /** @type {!proto.vseth.sip.eule.JobName} */ (reader.readEnum());
      msg.setJobName(value);
      break;
    case 3:
      var value = /** @type {!proto.vseth.sip.eule.JobStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogMessage(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPollId(value);
      break;
    case 8:
      var value = /** @type {!proto.vseth.sip.eule.PollStatus} */ (reader.readEnum());
      msg.setPollStatus(value);
      break;
    case 9:
      var value = /** @type {!proto.vseth.sip.eule.PollTimestampLabel} */ (reader.readEnum());
      msg.setPollTimestampLabel(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setArgument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getJobName();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLogMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPollStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPollTimestampLabel();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getArgument();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int32 job_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.Job.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Job} returns this
 */
proto.vseth.sip.eule.Job.prototype.setJobId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional JobName job_name = 2;
 * @return {!proto.vseth.sip.eule.JobName}
 */
proto.vseth.sip.eule.Job.prototype.getJobName = function() {
  return /** @type {!proto.vseth.sip.eule.JobName} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vseth.sip.eule.JobName} value
 * @return {!proto.vseth.sip.eule.Job} returns this
 */
proto.vseth.sip.eule.Job.prototype.setJobName = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional JobStatus status = 3;
 * @return {!proto.vseth.sip.eule.JobStatus}
 */
proto.vseth.sip.eule.Job.prototype.getStatus = function() {
  return /** @type {!proto.vseth.sip.eule.JobStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vseth.sip.eule.JobStatus} value
 * @return {!proto.vseth.sip.eule.Job} returns this
 */
proto.vseth.sip.eule.Job.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string log_message = 4;
 * @return {string}
 */
proto.vseth.sip.eule.Job.prototype.getLogMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Job} returns this
 */
proto.vseth.sip.eule.Job.prototype.setLogMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.Job.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.Job} returns this
*/
proto.vseth.sip.eule.Job.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.Job} returns this
 */
proto.vseth.sip.eule.Job.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.Job.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.Job.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.Job} returns this
*/
proto.vseth.sip.eule.Job.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.Job} returns this
 */
proto.vseth.sip.eule.Job.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.Job.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 poll_id = 7;
 * @return {number}
 */
proto.vseth.sip.eule.Job.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Job} returns this
 */
proto.vseth.sip.eule.Job.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional PollStatus poll_status = 8;
 * @return {!proto.vseth.sip.eule.PollStatus}
 */
proto.vseth.sip.eule.Job.prototype.getPollStatus = function() {
  return /** @type {!proto.vseth.sip.eule.PollStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vseth.sip.eule.PollStatus} value
 * @return {!proto.vseth.sip.eule.Job} returns this
 */
proto.vseth.sip.eule.Job.prototype.setPollStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional PollTimestampLabel poll_timestamp_label = 9;
 * @return {!proto.vseth.sip.eule.PollTimestampLabel}
 */
proto.vseth.sip.eule.Job.prototype.getPollTimestampLabel = function() {
  return /** @type {!proto.vseth.sip.eule.PollTimestampLabel} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.vseth.sip.eule.PollTimestampLabel} value
 * @return {!proto.vseth.sip.eule.Job} returns this
 */
proto.vseth.sip.eule.Job.prototype.setPollTimestampLabel = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string argument = 10;
 * @return {string}
 */
proto.vseth.sip.eule.Job.prototype.getArgument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Job} returns this
 */
proto.vseth.sip.eule.Job.prototype.setArgument = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ListJobsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ListJobsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ListJobsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListJobsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ListJobsRequest}
 */
proto.vseth.sip.eule.ListJobsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ListJobsRequest;
  return proto.vseth.sip.eule.ListJobsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ListJobsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ListJobsRequest}
 */
proto.vseth.sip.eule.ListJobsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ListJobsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ListJobsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ListJobsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListJobsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.eule.ListJobsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ListJobsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ListJobsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ListJobsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListJobsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobsList: jspb.Message.toObjectList(msg.getJobsList(),
    proto.vseth.sip.eule.Job.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ListJobsResponse}
 */
proto.vseth.sip.eule.ListJobsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ListJobsResponse;
  return proto.vseth.sip.eule.ListJobsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ListJobsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ListJobsResponse}
 */
proto.vseth.sip.eule.ListJobsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.eule.Job;
      reader.readMessage(value,proto.vseth.sip.eule.Job.deserializeBinaryFromReader);
      msg.addJobs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ListJobsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ListJobsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ListJobsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListJobsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.eule.Job.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Job jobs = 1;
 * @return {!Array<!proto.vseth.sip.eule.Job>}
 */
proto.vseth.sip.eule.ListJobsResponse.prototype.getJobsList = function() {
  return /** @type{!Array<!proto.vseth.sip.eule.Job>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.eule.Job, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.eule.Job>} value
 * @return {!proto.vseth.sip.eule.ListJobsResponse} returns this
*/
proto.vseth.sip.eule.ListJobsResponse.prototype.setJobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.eule.Job=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.Job}
 */
proto.vseth.sip.eule.ListJobsResponse.prototype.addJobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.eule.Job, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.ListJobsResponse} returns this
 */
proto.vseth.sip.eule.ListJobsResponse.prototype.clearJobsList = function() {
  return this.setJobsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.UpdateJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.UpdateJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.UpdateJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.UpdateJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.UpdateJobRequest}
 */
proto.vseth.sip.eule.UpdateJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.UpdateJobRequest;
  return proto.vseth.sip.eule.UpdateJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.UpdateJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.UpdateJobRequest}
 */
proto.vseth.sip.eule.UpdateJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 2:
      var value = /** @type {!proto.vseth.sip.eule.JobUpdateAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.UpdateJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.UpdateJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.UpdateJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.UpdateJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 job_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.UpdateJobRequest.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.UpdateJobRequest} returns this
 */
proto.vseth.sip.eule.UpdateJobRequest.prototype.setJobId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional JobUpdateAction action = 2;
 * @return {!proto.vseth.sip.eule.JobUpdateAction}
 */
proto.vseth.sip.eule.UpdateJobRequest.prototype.getAction = function() {
  return /** @type {!proto.vseth.sip.eule.JobUpdateAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vseth.sip.eule.JobUpdateAction} value
 * @return {!proto.vseth.sip.eule.UpdateJobRequest} returns this
 */
proto.vseth.sip.eule.UpdateJobRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.GetLecturerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.GetLecturerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.GetLecturerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetLecturerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lecturerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dozide: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.GetLecturerRequest}
 */
proto.vseth.sip.eule.GetLecturerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.GetLecturerRequest;
  return proto.vseth.sip.eule.GetLecturerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.GetLecturerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.GetLecturerRequest}
 */
proto.vseth.sip.eule.GetLecturerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLecturerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDozide(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.GetLecturerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.GetLecturerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.GetLecturerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetLecturerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLecturerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDozide();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 lecturer_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.GetLecturerRequest.prototype.getLecturerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.GetLecturerRequest} returns this
 */
proto.vseth.sip.eule.GetLecturerRequest.prototype.setLecturerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 dozide = 2;
 * @return {number}
 */
proto.vseth.sip.eule.GetLecturerRequest.prototype.getDozide = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.GetLecturerRequest} returns this
 */
proto.vseth.sip.eule.GetLecturerRequest.prototype.setDozide = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vseth.sip.eule.GetLecturerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.GetLecturerRequest} returns this
 */
proto.vseth.sip.eule.GetLecturerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.Lecture.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.Lecture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.Lecture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Lecture.toObject = function(includeInstance, msg) {
  var f, obj = {
    lectureId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    courseNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    semester: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lectureName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.Lecture}
 */
proto.vseth.sip.eule.Lecture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.Lecture;
  return proto.vseth.sip.eule.Lecture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.Lecture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.Lecture}
 */
proto.vseth.sip.eule.Lecture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLectureId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCourseNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSemester(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLectureName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.Lecture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.Lecture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.Lecture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Lecture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLectureId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCourseNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSemester();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLectureName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 lecture_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.Lecture.prototype.getLectureId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Lecture} returns this
 */
proto.vseth.sip.eule.Lecture.prototype.setLectureId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string course_number = 2;
 * @return {string}
 */
proto.vseth.sip.eule.Lecture.prototype.getCourseNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Lecture} returns this
 */
proto.vseth.sip.eule.Lecture.prototype.setCourseNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string semester = 3;
 * @return {string}
 */
proto.vseth.sip.eule.Lecture.prototype.getSemester = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Lecture} returns this
 */
proto.vseth.sip.eule.Lecture.prototype.setSemester = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lecture_name = 4;
 * @return {string}
 */
proto.vseth.sip.eule.Lecture.prototype.getLectureName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Lecture} returns this
 */
proto.vseth.sip.eule.Lecture.prototype.setLectureName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.vseth.sip.eule.Lecture.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Lecture} returns this
 */
proto.vseth.sip.eule.Lecture.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.eule.Lecturer.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.Lecturer.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.Lecturer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.Lecturer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Lecturer.toObject = function(includeInstance, msg) {
  var f, obj = {
    lecturerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    awardsList: jspb.Message.toObjectList(msg.getAwardsList(),
    proto.vseth.sip.eule.Award.toObject, includeInstance),
    lecturesList: jspb.Message.toObjectList(msg.getLecturesList(),
    proto.vseth.sip.eule.Lecture.toObject, includeInstance),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    academicTitle: jspb.Message.getFieldWithDefault(msg, 7, ""),
    department: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.Lecturer}
 */
proto.vseth.sip.eule.Lecturer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.Lecturer;
  return proto.vseth.sip.eule.Lecturer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.Lecturer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.Lecturer}
 */
proto.vseth.sip.eule.Lecturer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLecturerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = new proto.vseth.sip.eule.Award;
      reader.readMessage(value,proto.vseth.sip.eule.Award.deserializeBinaryFromReader);
      msg.addAwards(value);
      break;
    case 5:
      var value = new proto.vseth.sip.eule.Lecture;
      reader.readMessage(value,proto.vseth.sip.eule.Lecture.deserializeBinaryFromReader);
      msg.addLectures(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcademicTitle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.Lecturer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.Lecturer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.Lecturer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Lecturer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLecturerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAwardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vseth.sip.eule.Award.serializeBinaryToWriter
    );
  }
  f = message.getLecturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.vseth.sip.eule.Lecture.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAcademicTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 lecturer_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.Lecturer.prototype.getLecturerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Lecturer} returns this
 */
proto.vseth.sip.eule.Lecturer.prototype.setLecturerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.vseth.sip.eule.Lecturer.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Lecturer} returns this
 */
proto.vseth.sip.eule.Lecturer.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.vseth.sip.eule.Lecturer.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Lecturer} returns this
 */
proto.vseth.sip.eule.Lecturer.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Award awards = 4;
 * @return {!Array<!proto.vseth.sip.eule.Award>}
 */
proto.vseth.sip.eule.Lecturer.prototype.getAwardsList = function() {
  return /** @type{!Array<!proto.vseth.sip.eule.Award>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.eule.Award, 4));
};


/**
 * @param {!Array<!proto.vseth.sip.eule.Award>} value
 * @return {!proto.vseth.sip.eule.Lecturer} returns this
*/
proto.vseth.sip.eule.Lecturer.prototype.setAwardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vseth.sip.eule.Award=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.Award}
 */
proto.vseth.sip.eule.Lecturer.prototype.addAwards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vseth.sip.eule.Award, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.Lecturer} returns this
 */
proto.vseth.sip.eule.Lecturer.prototype.clearAwardsList = function() {
  return this.setAwardsList([]);
};


/**
 * repeated Lecture lectures = 5;
 * @return {!Array<!proto.vseth.sip.eule.Lecture>}
 */
proto.vseth.sip.eule.Lecturer.prototype.getLecturesList = function() {
  return /** @type{!Array<!proto.vseth.sip.eule.Lecture>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.eule.Lecture, 5));
};


/**
 * @param {!Array<!proto.vseth.sip.eule.Lecture>} value
 * @return {!proto.vseth.sip.eule.Lecturer} returns this
*/
proto.vseth.sip.eule.Lecturer.prototype.setLecturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.vseth.sip.eule.Lecture=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.Lecture}
 */
proto.vseth.sip.eule.Lecturer.prototype.addLectures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.vseth.sip.eule.Lecture, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.Lecturer} returns this
 */
proto.vseth.sip.eule.Lecturer.prototype.clearLecturesList = function() {
  return this.setLecturesList([]);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.vseth.sip.eule.Lecturer.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Lecturer} returns this
 */
proto.vseth.sip.eule.Lecturer.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string academic_title = 7;
 * @return {string}
 */
proto.vseth.sip.eule.Lecturer.prototype.getAcademicTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Lecturer} returns this
 */
proto.vseth.sip.eule.Lecturer.prototype.setAcademicTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string department = 8;
 * @return {string}
 */
proto.vseth.sip.eule.Lecturer.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Lecturer} returns this
 */
proto.vseth.sip.eule.Lecturer.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ListLecturersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ListLecturersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ListLecturersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListLecturersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ListLecturersRequest}
 */
proto.vseth.sip.eule.ListLecturersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ListLecturersRequest;
  return proto.vseth.sip.eule.ListLecturersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ListLecturersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ListLecturersRequest}
 */
proto.vseth.sip.eule.ListLecturersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ListLecturersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ListLecturersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ListLecturersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListLecturersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.eule.ListLecturersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ListLecturersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ListLecturersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ListLecturersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListLecturersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lecturersList: jspb.Message.toObjectList(msg.getLecturersList(),
    proto.vseth.sip.eule.Lecturer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ListLecturersResponse}
 */
proto.vseth.sip.eule.ListLecturersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ListLecturersResponse;
  return proto.vseth.sip.eule.ListLecturersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ListLecturersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ListLecturersResponse}
 */
proto.vseth.sip.eule.ListLecturersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.eule.Lecturer;
      reader.readMessage(value,proto.vseth.sip.eule.Lecturer.deserializeBinaryFromReader);
      msg.addLecturers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ListLecturersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ListLecturersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ListLecturersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListLecturersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLecturersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.eule.Lecturer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Lecturer lecturers = 1;
 * @return {!Array<!proto.vseth.sip.eule.Lecturer>}
 */
proto.vseth.sip.eule.ListLecturersResponse.prototype.getLecturersList = function() {
  return /** @type{!Array<!proto.vseth.sip.eule.Lecturer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.eule.Lecturer, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.eule.Lecturer>} value
 * @return {!proto.vseth.sip.eule.ListLecturersResponse} returns this
*/
proto.vseth.sip.eule.ListLecturersResponse.prototype.setLecturersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.eule.Lecturer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.Lecturer}
 */
proto.vseth.sip.eule.ListLecturersResponse.prototype.addLecturers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.eule.Lecturer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.ListLecturersResponse} returns this
 */
proto.vseth.sip.eule.ListLecturersResponse.prototype.clearLecturersList = function() {
  return this.setLecturersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.GetPollRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.GetPollRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.GetPollRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetPollRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    getCurrent: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.GetPollRequest}
 */
proto.vseth.sip.eule.GetPollRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.GetPollRequest;
  return proto.vseth.sip.eule.GetPollRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.GetPollRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.GetPollRequest}
 */
proto.vseth.sip.eule.GetPollRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPollId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.GetPollRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.GetPollRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.GetPollRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetPollRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGetCurrent();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 poll_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.GetPollRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.GetPollRequest} returns this
 */
proto.vseth.sip.eule.GetPollRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool get_current = 2;
 * @return {boolean}
 */
proto.vseth.sip.eule.GetPollRequest.prototype.getGetCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.eule.GetPollRequest} returns this
 */
proto.vseth.sip.eule.GetPollRequest.prototype.setGetCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.Poll.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.Poll.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.Poll} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Poll.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    phdsCanVote: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    announcementTime: (f = msg.getAnnouncementTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    votesCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    votersCount: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.Poll}
 */
proto.vseth.sip.eule.Poll.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.Poll;
  return proto.vseth.sip.eule.Poll.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.Poll} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.Poll}
 */
proto.vseth.sip.eule.Poll.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPollId(value);
      break;
    case 2:
      var value = /** @type {!proto.vseth.sip.eule.PollStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhdsCanVote(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAnnouncementTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVotesCount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVotersCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.Poll.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.Poll.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.Poll} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Poll.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPhdsCanVote();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAnnouncementTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVotesCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getVotersCount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional int32 poll_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.Poll.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Poll} returns this
 */
proto.vseth.sip.eule.Poll.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PollStatus status = 2;
 * @return {!proto.vseth.sip.eule.PollStatus}
 */
proto.vseth.sip.eule.Poll.prototype.getStatus = function() {
  return /** @type {!proto.vseth.sip.eule.PollStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vseth.sip.eule.PollStatus} value
 * @return {!proto.vseth.sip.eule.Poll} returns this
 */
proto.vseth.sip.eule.Poll.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.vseth.sip.eule.Poll.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Poll} returns this
 */
proto.vseth.sip.eule.Poll.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool phds_can_vote = 4;
 * @return {boolean}
 */
proto.vseth.sip.eule.Poll.prototype.getPhdsCanVote = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.eule.Poll} returns this
 */
proto.vseth.sip.eule.Poll.prototype.setPhdsCanVote = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.Poll.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.Poll} returns this
*/
proto.vseth.sip.eule.Poll.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.Poll} returns this
 */
proto.vseth.sip.eule.Poll.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.Poll.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.Poll.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.Poll} returns this
*/
proto.vseth.sip.eule.Poll.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.Poll} returns this
 */
proto.vseth.sip.eule.Poll.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.Poll.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.Poll.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.Poll} returns this
*/
proto.vseth.sip.eule.Poll.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.Poll} returns this
 */
proto.vseth.sip.eule.Poll.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.Poll.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp announcement_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.Poll.prototype.getAnnouncementTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.Poll} returns this
*/
proto.vseth.sip.eule.Poll.prototype.setAnnouncementTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.Poll} returns this
 */
proto.vseth.sip.eule.Poll.prototype.clearAnnouncementTime = function() {
  return this.setAnnouncementTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.Poll.prototype.hasAnnouncementTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 votes_count = 9;
 * @return {number}
 */
proto.vseth.sip.eule.Poll.prototype.getVotesCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Poll} returns this
 */
proto.vseth.sip.eule.Poll.prototype.setVotesCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 voters_count = 10;
 * @return {number}
 */
proto.vseth.sip.eule.Poll.prototype.getVotersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Poll} returns this
 */
proto.vseth.sip.eule.Poll.prototype.setVotersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ListPollsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ListPollsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ListPollsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListPollsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ListPollsRequest}
 */
proto.vseth.sip.eule.ListPollsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ListPollsRequest;
  return proto.vseth.sip.eule.ListPollsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ListPollsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ListPollsRequest}
 */
proto.vseth.sip.eule.ListPollsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ListPollsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ListPollsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ListPollsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListPollsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.eule.ListPollsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ListPollsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ListPollsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ListPollsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListPollsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollsList: jspb.Message.toObjectList(msg.getPollsList(),
    proto.vseth.sip.eule.Poll.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ListPollsResponse}
 */
proto.vseth.sip.eule.ListPollsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ListPollsResponse;
  return proto.vseth.sip.eule.ListPollsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ListPollsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ListPollsResponse}
 */
proto.vseth.sip.eule.ListPollsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.eule.Poll;
      reader.readMessage(value,proto.vseth.sip.eule.Poll.deserializeBinaryFromReader);
      msg.addPolls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ListPollsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ListPollsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ListPollsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListPollsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.eule.Poll.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Poll polls = 1;
 * @return {!Array<!proto.vseth.sip.eule.Poll>}
 */
proto.vseth.sip.eule.ListPollsResponse.prototype.getPollsList = function() {
  return /** @type{!Array<!proto.vseth.sip.eule.Poll>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.eule.Poll, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.eule.Poll>} value
 * @return {!proto.vseth.sip.eule.ListPollsResponse} returns this
*/
proto.vseth.sip.eule.ListPollsResponse.prototype.setPollsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.eule.Poll=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.Poll}
 */
proto.vseth.sip.eule.ListPollsResponse.prototype.addPolls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.eule.Poll, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.ListPollsResponse} returns this
 */
proto.vseth.sip.eule.ListPollsResponse.prototype.clearPollsList = function() {
  return this.setPollsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.CreatePollRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.CreatePollRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreatePollRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    announcementTime: (f = msg.getAnnouncementTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    phdsCanVote: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.CreatePollRequest}
 */
proto.vseth.sip.eule.CreatePollRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.CreatePollRequest;
  return proto.vseth.sip.eule.CreatePollRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.CreatePollRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.CreatePollRequest}
 */
proto.vseth.sip.eule.CreatePollRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAnnouncementTime(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhdsCanVote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.CreatePollRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.CreatePollRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreatePollRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAnnouncementTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhdsCanVote();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.CreatePollRequest} returns this
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.CreatePollRequest} returns this
*/
proto.vseth.sip.eule.CreatePollRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.CreatePollRequest} returns this
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.CreatePollRequest} returns this
*/
proto.vseth.sip.eule.CreatePollRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.CreatePollRequest} returns this
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp announcement_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.getAnnouncementTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.CreatePollRequest} returns this
*/
proto.vseth.sip.eule.CreatePollRequest.prototype.setAnnouncementTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.CreatePollRequest} returns this
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.clearAnnouncementTime = function() {
  return this.setAnnouncementTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.hasAnnouncementTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool phds_can_vote = 5;
 * @return {boolean}
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.getPhdsCanVote = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.eule.CreatePollRequest} returns this
 */
proto.vseth.sip.eule.CreatePollRequest.prototype.setPhdsCanVote = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.UpdatePollRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.UpdatePollRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.UpdatePollRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    announcementTime: (f = msg.getAnnouncementTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    phdsCanVote: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.UpdatePollRequest}
 */
proto.vseth.sip.eule.UpdatePollRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.UpdatePollRequest;
  return proto.vseth.sip.eule.UpdatePollRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.UpdatePollRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.UpdatePollRequest}
 */
proto.vseth.sip.eule.UpdatePollRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPollId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAnnouncementTime(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhdsCanVote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.UpdatePollRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.UpdatePollRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.UpdatePollRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAnnouncementTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhdsCanVote();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 poll_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.UpdatePollRequest} returns this
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.UpdatePollRequest} returns this
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.UpdatePollRequest} returns this
*/
proto.vseth.sip.eule.UpdatePollRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.UpdatePollRequest} returns this
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.UpdatePollRequest} returns this
*/
proto.vseth.sip.eule.UpdatePollRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.UpdatePollRequest} returns this
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp announcement_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.getAnnouncementTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.eule.UpdatePollRequest} returns this
*/
proto.vseth.sip.eule.UpdatePollRequest.prototype.setAnnouncementTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.UpdatePollRequest} returns this
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.clearAnnouncementTime = function() {
  return this.setAnnouncementTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.hasAnnouncementTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool phds_can_vote = 6;
 * @return {boolean}
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.getPhdsCanVote = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.eule.UpdatePollRequest} returns this
 */
proto.vseth.sip.eule.UpdatePollRequest.prototype.setPhdsCanVote = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.CloseEvaluationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.CloseEvaluationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.CloseEvaluationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CloseEvaluationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.CloseEvaluationRequest}
 */
proto.vseth.sip.eule.CloseEvaluationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.CloseEvaluationRequest;
  return proto.vseth.sip.eule.CloseEvaluationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.CloseEvaluationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.CloseEvaluationRequest}
 */
proto.vseth.sip.eule.CloseEvaluationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.CloseEvaluationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.CloseEvaluationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.CloseEvaluationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CloseEvaluationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.GetFileUploadUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.GetFileUploadUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.GetFileUploadUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetFileUploadUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.GetFileUploadUrlRequest}
 */
proto.vseth.sip.eule.GetFileUploadUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.GetFileUploadUrlRequest;
  return proto.vseth.sip.eule.GetFileUploadUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.GetFileUploadUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.GetFileUploadUrlRequest}
 */
proto.vseth.sip.eule.GetFileUploadUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.GetFileUploadUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.GetFileUploadUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.GetFileUploadUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetFileUploadUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string file_name = 1;
 * @return {string}
 */
proto.vseth.sip.eule.GetFileUploadUrlRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.GetFileUploadUrlRequest} returns this
 */
proto.vseth.sip.eule.GetFileUploadUrlRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.GetFileDownloadUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.GetFileDownloadUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.GetFileDownloadUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetFileDownloadUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.GetFileDownloadUrlRequest}
 */
proto.vseth.sip.eule.GetFileDownloadUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.GetFileDownloadUrlRequest;
  return proto.vseth.sip.eule.GetFileDownloadUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.GetFileDownloadUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.GetFileDownloadUrlRequest}
 */
proto.vseth.sip.eule.GetFileDownloadUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.GetFileDownloadUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.GetFileDownloadUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.GetFileDownloadUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetFileDownloadUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string file_name = 1;
 * @return {string}
 */
proto.vseth.sip.eule.GetFileDownloadUrlRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.GetFileDownloadUrlRequest} returns this
 */
proto.vseth.sip.eule.GetFileDownloadUrlRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.FileUrl.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.FileUrl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.FileUrl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.FileUrl.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.FileUrl}
 */
proto.vseth.sip.eule.FileUrl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.FileUrl;
  return proto.vseth.sip.eule.FileUrl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.FileUrl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.FileUrl}
 */
proto.vseth.sip.eule.FileUrl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.FileUrl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.FileUrl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.FileUrl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.FileUrl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string file_url = 1;
 * @return {string}
 */
proto.vseth.sip.eule.FileUrl.prototype.getFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.FileUrl} returns this
 */
proto.vseth.sip.eule.FileUrl.prototype.setFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.GetVotingDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.GetVotingDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.GetVotingDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetVotingDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.GetVotingDataRequest}
 */
proto.vseth.sip.eule.GetVotingDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.GetVotingDataRequest;
  return proto.vseth.sip.eule.GetVotingDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.GetVotingDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.GetVotingDataRequest}
 */
proto.vseth.sip.eule.GetVotingDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.GetVotingDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.GetVotingDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.GetVotingDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetVotingDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.eule.VotingData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.VotingData.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.VotingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.VotingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.VotingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    openVotesList: jspb.Message.toObjectList(msg.getOpenVotesList(),
    proto.vseth.sip.eule.OpenVote.toObject, includeInstance),
    hasVoted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    canVote: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.VotingData}
 */
proto.vseth.sip.eule.VotingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.VotingData;
  return proto.vseth.sip.eule.VotingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.VotingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.VotingData}
 */
proto.vseth.sip.eule.VotingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPollId(value);
      break;
    case 2:
      var value = new proto.vseth.sip.eule.OpenVote;
      reader.readMessage(value,proto.vseth.sip.eule.OpenVote.deserializeBinaryFromReader);
      msg.addOpenVotes(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasVoted(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanVote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.VotingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.VotingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.VotingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.VotingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOpenVotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vseth.sip.eule.OpenVote.serializeBinaryToWriter
    );
  }
  f = message.getHasVoted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCanVote();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 poll_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.VotingData.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.VotingData} returns this
 */
proto.vseth.sip.eule.VotingData.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated OpenVote open_votes = 2;
 * @return {!Array<!proto.vseth.sip.eule.OpenVote>}
 */
proto.vseth.sip.eule.VotingData.prototype.getOpenVotesList = function() {
  return /** @type{!Array<!proto.vseth.sip.eule.OpenVote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.eule.OpenVote, 2));
};


/**
 * @param {!Array<!proto.vseth.sip.eule.OpenVote>} value
 * @return {!proto.vseth.sip.eule.VotingData} returns this
*/
proto.vseth.sip.eule.VotingData.prototype.setOpenVotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vseth.sip.eule.OpenVote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.OpenVote}
 */
proto.vseth.sip.eule.VotingData.prototype.addOpenVotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vseth.sip.eule.OpenVote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.VotingData} returns this
 */
proto.vseth.sip.eule.VotingData.prototype.clearOpenVotesList = function() {
  return this.setOpenVotesList([]);
};


/**
 * optional bool has_voted = 3;
 * @return {boolean}
 */
proto.vseth.sip.eule.VotingData.prototype.getHasVoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.eule.VotingData} returns this
 */
proto.vseth.sip.eule.VotingData.prototype.setHasVoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool can_vote = 4;
 * @return {boolean}
 */
proto.vseth.sip.eule.VotingData.prototype.getCanVote = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.eule.VotingData} returns this
 */
proto.vseth.sip.eule.VotingData.prototype.setCanVote = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.OpenVote.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.OpenVote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.OpenVote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.OpenVote.toObject = function(includeInstance, msg) {
  var f, obj = {
    relationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lecturerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lectureCourseNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lectureSemester: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lectureName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.OpenVote}
 */
proto.vseth.sip.eule.OpenVote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.OpenVote;
  return proto.vseth.sip.eule.OpenVote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.OpenVote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.OpenVote}
 */
proto.vseth.sip.eule.OpenVote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRelationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLecturerName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLectureCourseNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLectureSemester(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLectureName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.OpenVote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.OpenVote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.OpenVote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.OpenVote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelationId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLecturerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLectureCourseNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLectureSemester();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLectureName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 relation_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.OpenVote.prototype.getRelationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.OpenVote} returns this
 */
proto.vseth.sip.eule.OpenVote.prototype.setRelationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string lecturer_name = 2;
 * @return {string}
 */
proto.vseth.sip.eule.OpenVote.prototype.getLecturerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.OpenVote} returns this
 */
proto.vseth.sip.eule.OpenVote.prototype.setLecturerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lecture_course_number = 3;
 * @return {string}
 */
proto.vseth.sip.eule.OpenVote.prototype.getLectureCourseNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.OpenVote} returns this
 */
proto.vseth.sip.eule.OpenVote.prototype.setLectureCourseNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lecture_semester = 4;
 * @return {string}
 */
proto.vseth.sip.eule.OpenVote.prototype.getLectureSemester = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.OpenVote} returns this
 */
proto.vseth.sip.eule.OpenVote.prototype.setLectureSemester = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lecture_name = 5;
 * @return {string}
 */
proto.vseth.sip.eule.OpenVote.prototype.getLectureName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.OpenVote} returns this
 */
proto.vseth.sip.eule.OpenVote.prototype.setLectureName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.eule.CreateVotesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.CreateVotesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.CreateVotesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.CreateVotesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreateVotesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    votesList: jspb.Message.toObjectList(msg.getVotesList(),
    proto.vseth.sip.eule.Vote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.CreateVotesRequest}
 */
proto.vseth.sip.eule.CreateVotesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.CreateVotesRequest;
  return proto.vseth.sip.eule.CreateVotesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.CreateVotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.CreateVotesRequest}
 */
proto.vseth.sip.eule.CreateVotesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.eule.Vote;
      reader.readMessage(value,proto.vseth.sip.eule.Vote.deserializeBinaryFromReader);
      msg.addVotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.CreateVotesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.CreateVotesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.CreateVotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreateVotesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.eule.Vote.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Vote votes = 1;
 * @return {!Array<!proto.vseth.sip.eule.Vote>}
 */
proto.vseth.sip.eule.CreateVotesRequest.prototype.getVotesList = function() {
  return /** @type{!Array<!proto.vseth.sip.eule.Vote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.eule.Vote, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.eule.Vote>} value
 * @return {!proto.vseth.sip.eule.CreateVotesRequest} returns this
*/
proto.vseth.sip.eule.CreateVotesRequest.prototype.setVotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.eule.Vote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.Vote}
 */
proto.vseth.sip.eule.CreateVotesRequest.prototype.addVotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.eule.Vote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.CreateVotesRequest} returns this
 */
proto.vseth.sip.eule.CreateVotesRequest.prototype.clearVotesList = function() {
  return this.setVotesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.eule.Vote.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.Vote.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.Vote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.Vote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Vote.toObject = function(includeInstance, msg) {
  var f, obj = {
    relationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.vseth.sip.eule.Answer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.Vote}
 */
proto.vseth.sip.eule.Vote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.Vote;
  return proto.vseth.sip.eule.Vote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.Vote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.Vote}
 */
proto.vseth.sip.eule.Vote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRelationId(value);
      break;
    case 2:
      var value = new proto.vseth.sip.eule.Answer;
      reader.readMessage(value,proto.vseth.sip.eule.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.Vote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.Vote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.Vote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Vote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelationId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vseth.sip.eule.Answer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 relation_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.Vote.prototype.getRelationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Vote} returns this
 */
proto.vseth.sip.eule.Vote.prototype.setRelationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Answer answers = 2;
 * @return {!Array<!proto.vseth.sip.eule.Answer>}
 */
proto.vseth.sip.eule.Vote.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.vseth.sip.eule.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.eule.Answer, 2));
};


/**
 * @param {!Array<!proto.vseth.sip.eule.Answer>} value
 * @return {!proto.vseth.sip.eule.Vote} returns this
*/
proto.vseth.sip.eule.Vote.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vseth.sip.eule.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.Answer}
 */
proto.vseth.sip.eule.Vote.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vseth.sip.eule.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.Vote} returns this
 */
proto.vseth.sip.eule.Vote.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.eule.CreatedVotes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.CreatedVotes.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.CreatedVotes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.CreatedVotes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreatedVotes.toObject = function(includeInstance, msg) {
  var f, obj = {
    votesList: jspb.Message.toObjectList(msg.getVotesList(),
    proto.vseth.sip.eule.Vote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.CreatedVotes}
 */
proto.vseth.sip.eule.CreatedVotes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.CreatedVotes;
  return proto.vseth.sip.eule.CreatedVotes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.CreatedVotes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.CreatedVotes}
 */
proto.vseth.sip.eule.CreatedVotes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.eule.Vote;
      reader.readMessage(value,proto.vseth.sip.eule.Vote.deserializeBinaryFromReader);
      msg.addVotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.CreatedVotes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.CreatedVotes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.CreatedVotes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreatedVotes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.eule.Vote.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Vote votes = 1;
 * @return {!Array<!proto.vseth.sip.eule.Vote>}
 */
proto.vseth.sip.eule.CreatedVotes.prototype.getVotesList = function() {
  return /** @type{!Array<!proto.vseth.sip.eule.Vote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.eule.Vote, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.eule.Vote>} value
 * @return {!proto.vseth.sip.eule.CreatedVotes} returns this
*/
proto.vseth.sip.eule.CreatedVotes.prototype.setVotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.eule.Vote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.Vote}
 */
proto.vseth.sip.eule.CreatedVotes.prototype.addVotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.eule.Vote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.CreatedVotes} returns this
 */
proto.vseth.sip.eule.CreatedVotes.prototype.clearVotesList = function() {
  return this.setVotesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.Answer.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.Answer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.Answer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Answer.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answer: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.Answer}
 */
proto.vseth.sip.eule.Answer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.Answer;
  return proto.vseth.sip.eule.Answer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.Answer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.Answer}
 */
proto.vseth.sip.eule.Answer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnswerType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.Answer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.Answer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.Answer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Answer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 answer_type = 1;
 * @return {number}
 */
proto.vseth.sip.eule.Answer.prototype.getAnswerType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Answer} returns this
 */
proto.vseth.sip.eule.Answer.prototype.setAnswerType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string answer = 2;
 * @return {string}
 */
proto.vseth.sip.eule.Answer.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Answer} returns this
 */
proto.vseth.sip.eule.Answer.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.CreateNotificationMailEntryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.CreateNotificationMailEntryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.CreateNotificationMailEntryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreateNotificationMailEntryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.CreateNotificationMailEntryRequest}
 */
proto.vseth.sip.eule.CreateNotificationMailEntryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.CreateNotificationMailEntryRequest;
  return proto.vseth.sip.eule.CreateNotificationMailEntryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.CreateNotificationMailEntryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.CreateNotificationMailEntryRequest}
 */
proto.vseth.sip.eule.CreateNotificationMailEntryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.CreateNotificationMailEntryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.CreateNotificationMailEntryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.CreateNotificationMailEntryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreateNotificationMailEntryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.GetNotificationMailEntriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.GetNotificationMailEntriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.GetNotificationMailEntriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetNotificationMailEntriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.GetNotificationMailEntriesRequest}
 */
proto.vseth.sip.eule.GetNotificationMailEntriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.GetNotificationMailEntriesRequest;
  return proto.vseth.sip.eule.GetNotificationMailEntriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.GetNotificationMailEntriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.GetNotificationMailEntriesRequest}
 */
proto.vseth.sip.eule.GetNotificationMailEntriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.GetNotificationMailEntriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.GetNotificationMailEntriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.GetNotificationMailEntriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetNotificationMailEntriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.NotificationMailEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.NotificationMailEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.NotificationMailEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.NotificationMailEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.NotificationMailEntry}
 */
proto.vseth.sip.eule.NotificationMailEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.NotificationMailEntry;
  return proto.vseth.sip.eule.NotificationMailEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.NotificationMailEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.NotificationMailEntry}
 */
proto.vseth.sip.eule.NotificationMailEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.NotificationMailEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.NotificationMailEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.NotificationMailEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.NotificationMailEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.vseth.sip.eule.NotificationMailEntry.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.NotificationMailEntry} returns this
 */
proto.vseth.sip.eule.NotificationMailEntry.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ListWinnersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ListWinnersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ListWinnersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListWinnersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ListWinnersRequest}
 */
proto.vseth.sip.eule.ListWinnersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ListWinnersRequest;
  return proto.vseth.sip.eule.ListWinnersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ListWinnersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ListWinnersRequest}
 */
proto.vseth.sip.eule.ListWinnersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ListWinnersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ListWinnersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ListWinnersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListWinnersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.Award.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.Award.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.Award} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Award.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    awardType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.Award}
 */
proto.vseth.sip.eule.Award.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.Award;
  return proto.vseth.sip.eule.Award.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.Award} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.Award}
 */
proto.vseth.sip.eule.Award.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {!proto.vseth.sip.eule.AwardType} */ (reader.readEnum());
      msg.setAwardType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.Award.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.Award.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.Award} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Award.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAwardType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.vseth.sip.eule.Award.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Award} returns this
 */
proto.vseth.sip.eule.Award.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional AwardType award_type = 2;
 * @return {!proto.vseth.sip.eule.AwardType}
 */
proto.vseth.sip.eule.Award.prototype.getAwardType = function() {
  return /** @type {!proto.vseth.sip.eule.AwardType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vseth.sip.eule.AwardType} value
 * @return {!proto.vseth.sip.eule.Award} returns this
 */
proto.vseth.sip.eule.Award.prototype.setAwardType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.Winner.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.Winner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.Winner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Winner.toObject = function(includeInstance, msg) {
  var f, obj = {
    winnerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lecturerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    academicTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    department: jspb.Message.getFieldWithDefault(msg, 6, ""),
    award: (f = msg.getAward()) && proto.vseth.sip.eule.Award.toObject(includeInstance, f),
    dozId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    pictureConsent: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    pictureUrl: (f = msg.getPictureUrl()) && proto.vseth.sip.eule.FileUrl.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.Winner}
 */
proto.vseth.sip.eule.Winner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.Winner;
  return proto.vseth.sip.eule.Winner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.Winner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.Winner}
 */
proto.vseth.sip.eule.Winner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinnerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLecturerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcademicTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 7:
      var value = new proto.vseth.sip.eule.Award;
      reader.readMessage(value,proto.vseth.sip.eule.Award.deserializeBinaryFromReader);
      msg.setAward(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDozId(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPictureConsent(value);
      break;
    case 10:
      var value = new proto.vseth.sip.eule.FileUrl;
      reader.readMessage(value,proto.vseth.sip.eule.FileUrl.deserializeBinaryFromReader);
      msg.setPictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.Winner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.Winner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.Winner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.Winner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWinnerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLecturerId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAcademicTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAward();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vseth.sip.eule.Award.serializeBinaryToWriter
    );
  }
  f = message.getDozId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPictureConsent();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPictureUrl();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vseth.sip.eule.FileUrl.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 winner_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.Winner.prototype.getWinnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Winner} returns this
 */
proto.vseth.sip.eule.Winner.prototype.setWinnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 lecturer_id = 2;
 * @return {number}
 */
proto.vseth.sip.eule.Winner.prototype.getLecturerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Winner} returns this
 */
proto.vseth.sip.eule.Winner.prototype.setLecturerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string academic_title = 3;
 * @return {string}
 */
proto.vseth.sip.eule.Winner.prototype.getAcademicTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Winner} returns this
 */
proto.vseth.sip.eule.Winner.prototype.setAcademicTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.vseth.sip.eule.Winner.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Winner} returns this
 */
proto.vseth.sip.eule.Winner.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.vseth.sip.eule.Winner.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Winner} returns this
 */
proto.vseth.sip.eule.Winner.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string department = 6;
 * @return {string}
 */
proto.vseth.sip.eule.Winner.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.Winner} returns this
 */
proto.vseth.sip.eule.Winner.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Award award = 7;
 * @return {?proto.vseth.sip.eule.Award}
 */
proto.vseth.sip.eule.Winner.prototype.getAward = function() {
  return /** @type{?proto.vseth.sip.eule.Award} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.eule.Award, 7));
};


/**
 * @param {?proto.vseth.sip.eule.Award|undefined} value
 * @return {!proto.vseth.sip.eule.Winner} returns this
*/
proto.vseth.sip.eule.Winner.prototype.setAward = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.Winner} returns this
 */
proto.vseth.sip.eule.Winner.prototype.clearAward = function() {
  return this.setAward(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.Winner.prototype.hasAward = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 doz_id = 8;
 * @return {number}
 */
proto.vseth.sip.eule.Winner.prototype.getDozId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.Winner} returns this
 */
proto.vseth.sip.eule.Winner.prototype.setDozId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool picture_consent = 9;
 * @return {boolean}
 */
proto.vseth.sip.eule.Winner.prototype.getPictureConsent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.eule.Winner} returns this
 */
proto.vseth.sip.eule.Winner.prototype.setPictureConsent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional FileUrl picture_url = 10;
 * @return {?proto.vseth.sip.eule.FileUrl}
 */
proto.vseth.sip.eule.Winner.prototype.getPictureUrl = function() {
  return /** @type{?proto.vseth.sip.eule.FileUrl} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.eule.FileUrl, 10));
};


/**
 * @param {?proto.vseth.sip.eule.FileUrl|undefined} value
 * @return {!proto.vseth.sip.eule.Winner} returns this
*/
proto.vseth.sip.eule.Winner.prototype.setPictureUrl = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.Winner} returns this
 */
proto.vseth.sip.eule.Winner.prototype.clearPictureUrl = function() {
  return this.setPictureUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.Winner.prototype.hasPictureUrl = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.eule.ListWinnersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ListWinnersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ListWinnersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ListWinnersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListWinnersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    winnersList: jspb.Message.toObjectList(msg.getWinnersList(),
    proto.vseth.sip.eule.Winner.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ListWinnersResponse}
 */
proto.vseth.sip.eule.ListWinnersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ListWinnersResponse;
  return proto.vseth.sip.eule.ListWinnersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ListWinnersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ListWinnersResponse}
 */
proto.vseth.sip.eule.ListWinnersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.eule.Winner;
      reader.readMessage(value,proto.vseth.sip.eule.Winner.deserializeBinaryFromReader);
      msg.addWinners(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ListWinnersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ListWinnersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ListWinnersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListWinnersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWinnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.eule.Winner.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Winner winners = 1;
 * @return {!Array<!proto.vseth.sip.eule.Winner>}
 */
proto.vseth.sip.eule.ListWinnersResponse.prototype.getWinnersList = function() {
  return /** @type{!Array<!proto.vseth.sip.eule.Winner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.eule.Winner, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.eule.Winner>} value
 * @return {!proto.vseth.sip.eule.ListWinnersResponse} returns this
*/
proto.vseth.sip.eule.ListWinnersResponse.prototype.setWinnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.eule.Winner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.Winner}
 */
proto.vseth.sip.eule.ListWinnersResponse.prototype.addWinners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.eule.Winner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.ListWinnersResponse} returns this
 */
proto.vseth.sip.eule.ListWinnersResponse.prototype.clearWinnersList = function() {
  return this.setWinnersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.CreateWinnersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.CreateWinnersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.CreateWinnersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreateWinnersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.CreateWinnersRequest}
 */
proto.vseth.sip.eule.CreateWinnersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.CreateWinnersRequest;
  return proto.vseth.sip.eule.CreateWinnersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.CreateWinnersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.CreateWinnersRequest}
 */
proto.vseth.sip.eule.CreateWinnersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.CreateWinnersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.CreateWinnersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.CreateWinnersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreateWinnersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.UpdateWinnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.UpdateWinnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.UpdateWinnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    winnerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lecturerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    department: jspb.Message.getFieldWithDefault(msg, 3, ""),
    award: (f = msg.getAward()) && proto.vseth.sip.eule.Award.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.UpdateWinnerRequest}
 */
proto.vseth.sip.eule.UpdateWinnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.UpdateWinnerRequest;
  return proto.vseth.sip.eule.UpdateWinnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.UpdateWinnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.UpdateWinnerRequest}
 */
proto.vseth.sip.eule.UpdateWinnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinnerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLecturerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 4:
      var value = new proto.vseth.sip.eule.Award;
      reader.readMessage(value,proto.vseth.sip.eule.Award.deserializeBinaryFromReader);
      msg.setAward(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.UpdateWinnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.UpdateWinnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.UpdateWinnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWinnerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLecturerId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAward();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vseth.sip.eule.Award.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 winner_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.getWinnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.UpdateWinnerRequest} returns this
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.setWinnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 lecturer_id = 2;
 * @return {number}
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.getLecturerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.UpdateWinnerRequest} returns this
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.setLecturerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string department = 3;
 * @return {string}
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.UpdateWinnerRequest} returns this
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Award award = 4;
 * @return {?proto.vseth.sip.eule.Award}
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.getAward = function() {
  return /** @type{?proto.vseth.sip.eule.Award} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.eule.Award, 4));
};


/**
 * @param {?proto.vseth.sip.eule.Award|undefined} value
 * @return {!proto.vseth.sip.eule.UpdateWinnerRequest} returns this
*/
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.setAward = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.eule.UpdateWinnerRequest} returns this
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.clearAward = function() {
  return this.setAward(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.eule.UpdateWinnerRequest.prototype.hasAward = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.DeleteWinnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.DeleteWinnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.DeleteWinnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.DeleteWinnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    winnerId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.DeleteWinnerRequest}
 */
proto.vseth.sip.eule.DeleteWinnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.DeleteWinnerRequest;
  return proto.vseth.sip.eule.DeleteWinnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.DeleteWinnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.DeleteWinnerRequest}
 */
proto.vseth.sip.eule.DeleteWinnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.DeleteWinnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.DeleteWinnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.DeleteWinnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.DeleteWinnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWinnerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 winner_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.DeleteWinnerRequest.prototype.getWinnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.DeleteWinnerRequest} returns this
 */
proto.vseth.sip.eule.DeleteWinnerRequest.prototype.setWinnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.CreateTemporaryWinnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.CreateTemporaryWinnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lecturerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    department: jspb.Message.getFieldWithDefault(msg, 2, ""),
    awardType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.CreateTemporaryWinnerRequest}
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.CreateTemporaryWinnerRequest;
  return proto.vseth.sip.eule.CreateTemporaryWinnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.CreateTemporaryWinnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.CreateTemporaryWinnerRequest}
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLecturerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 3:
      var value = /** @type {!proto.vseth.sip.eule.AwardType} */ (reader.readEnum());
      msg.setAwardType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.CreateTemporaryWinnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.CreateTemporaryWinnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLecturerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAwardType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 lecturer_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.prototype.getLecturerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.CreateTemporaryWinnerRequest} returns this
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.prototype.setLecturerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string department = 2;
 * @return {string}
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.CreateTemporaryWinnerRequest} returns this
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AwardType award_type = 3;
 * @return {!proto.vseth.sip.eule.AwardType}
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.prototype.getAwardType = function() {
  return /** @type {!proto.vseth.sip.eule.AwardType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vseth.sip.eule.AwardType} value
 * @return {!proto.vseth.sip.eule.CreateTemporaryWinnerRequest} returns this
 */
proto.vseth.sip.eule.CreateTemporaryWinnerRequest.prototype.setAwardType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ListDepartmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ListDepartmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ListDepartmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListDepartmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ListDepartmentsRequest}
 */
proto.vseth.sip.eule.ListDepartmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ListDepartmentsRequest;
  return proto.vseth.sip.eule.ListDepartmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ListDepartmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ListDepartmentsRequest}
 */
proto.vseth.sip.eule.ListDepartmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ListDepartmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ListDepartmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ListDepartmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListDepartmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.eule.ListDepartmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ListDepartmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ListDepartmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ListDepartmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListDepartmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    departmentsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    canSubmitCsaward: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ListDepartmentsResponse}
 */
proto.vseth.sip.eule.ListDepartmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ListDepartmentsResponse;
  return proto.vseth.sip.eule.ListDepartmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ListDepartmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ListDepartmentsResponse}
 */
proto.vseth.sip.eule.ListDepartmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDepartments(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanSubmitCsaward(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ListDepartmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ListDepartmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ListDepartmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ListDepartmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCanSubmitCsaward();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated string departments = 1;
 * @return {!Array<string>}
 */
proto.vseth.sip.eule.ListDepartmentsResponse.prototype.getDepartmentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vseth.sip.eule.ListDepartmentsResponse} returns this
 */
proto.vseth.sip.eule.ListDepartmentsResponse.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.eule.ListDepartmentsResponse} returns this
 */
proto.vseth.sip.eule.ListDepartmentsResponse.prototype.addDepartments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.eule.ListDepartmentsResponse} returns this
 */
proto.vseth.sip.eule.ListDepartmentsResponse.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * optional bool can_submit_csaward = 2;
 * @return {boolean}
 */
proto.vseth.sip.eule.ListDepartmentsResponse.prototype.getCanSubmitCsaward = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.eule.ListDepartmentsResponse} returns this
 */
proto.vseth.sip.eule.ListDepartmentsResponse.prototype.setCanSubmitCsaward = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.GetResultsDepartmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.GetResultsDepartmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    department: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pollId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    getCurrent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.GetResultsDepartmentRequest}
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.GetResultsDepartmentRequest;
  return proto.vseth.sip.eule.GetResultsDepartmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.GetResultsDepartmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.GetResultsDepartmentRequest}
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPollId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.GetResultsDepartmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.GetResultsDepartmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGetCurrent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string department = 1;
 * @return {string}
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.GetResultsDepartmentRequest} returns this
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 poll_id = 2;
 * @return {number}
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.GetResultsDepartmentRequest} returns this
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool get_current = 3;
 * @return {boolean}
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.prototype.getGetCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.eule.GetResultsDepartmentRequest} returns this
 */
proto.vseth.sip.eule.GetResultsDepartmentRequest.prototype.setGetCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.eule.ResultsDepartment.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.eule.ResultsDepartment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.eule.ResultsDepartment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ResultsDepartment.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    department: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rawData: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.eule.ResultsDepartment}
 */
proto.vseth.sip.eule.ResultsDepartment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.eule.ResultsDepartment;
  return proto.vseth.sip.eule.ResultsDepartment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.eule.ResultsDepartment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.eule.ResultsDepartment}
 */
proto.vseth.sip.eule.ResultsDepartment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPollId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.eule.ResultsDepartment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.eule.ResultsDepartment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.eule.ResultsDepartment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.eule.ResultsDepartment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRawData();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 poll_id = 1;
 * @return {number}
 */
proto.vseth.sip.eule.ResultsDepartment.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.eule.ResultsDepartment} returns this
 */
proto.vseth.sip.eule.ResultsDepartment.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string department = 2;
 * @return {string}
 */
proto.vseth.sip.eule.ResultsDepartment.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.ResultsDepartment} returns this
 */
proto.vseth.sip.eule.ResultsDepartment.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string raw_data = 3;
 * @return {string}
 */
proto.vseth.sip.eule.ResultsDepartment.prototype.getRawData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.eule.ResultsDepartment} returns this
 */
proto.vseth.sip.eule.ResultsDepartment.prototype.setRawData = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.vseth.sip.eule.JobStatus = {
  INITIATED: 0,
  STARTING: 1,
  RUNNING: 2,
  FINISHED: 3,
  ABORTED: 4,
  ERROR: 5
};

/**
 * @enum {number}
 */
proto.vseth.sip.eule.JobUpdateAction = {
  ABORT: 0,
  RESTART: 1
};

/**
 * @enum {number}
 */
proto.vseth.sip.eule.JobName = {
  IMPORT_JOB: 0,
  OPEN_POLL: 1,
  CLOSE_POLL: 2,
  VOTES_ANALYSIS: 3,
  OPEN_EVALUATION: 4,
  FINALISE_RESULTS: 5,
  ANNOUNCE_RESULTS: 6
};

/**
 * @enum {number}
 */
proto.vseth.sip.eule.PollStatus = {
  POLL_PLANNED: 0,
  POLL_READY: 1,
  POLL_OPEN: 2,
  POLL_CLOSED: 3,
  EVALUATION_READY: 4,
  EVALUATION_OPEN: 5,
  EVALUATION_CLOSED: 6,
  RESULTS_ANNOUNCED: 7
};

/**
 * @enum {number}
 */
proto.vseth.sip.eule.PollTimestampLabel = {
  CREATION: 0,
  START: 1,
  END: 2,
  ANNOUNCEMENT: 3
};

/**
 * @enum {number}
 */
proto.vseth.sip.eule.AwardType = {
  EULE: 0,
  CSAWARD: 1
};

goog.object.extend(exports, proto.vseth.sip.eule);
