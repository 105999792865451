import React from "react";
import { useTranslation } from "react-i18next";
import {Button} from "@vseth/components";
import {useHistory} from "react-router-dom";

// Ask whether the user wants to be notified by email of the winners
export const GetNotified = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <>
            <p>{t("getNotified.text")}</p>
            <Button onClick={() => {history.push("/subscribed")}}>
                {t("getNotified.button")}
            </Button>
        </>
    );
};