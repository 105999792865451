import React from "react";
import { useTranslation } from "react-i18next";
import { Centered } from "../Centered";
import { GetNotified } from "./GetNotified";

// Message that the user successfully voted and whether they want to be notified
export const SuccessVoting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Centered>
      <h3>{t("successVoting.title")}</h3>
      <p>{t("successVoting.text")}</p>
      <GetNotified />
    </Centered>
  );
};
