import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18n from "../locals/i18n";

import { AppThunk } from "../app/store";
import {selectAccessToken} from "./authSlice";
import {
    VotingData as VotingDataMessage,
    OpenVote as OpenVoteMessage,
    Vote as VoteMessage,
    CreatedVotes as CreatedVotesMessage,
    GetVotingDataRequest, CreateVotesRequest, Answer
} from "../proto/eule_pb"
import {NotificationMailEntry as NotificationMailEntryMessage,
        CreateNotificationMailEntryRequest} from "../proto/eule_pb";
import {getAuthMetadata} from "../util/proto";


export type Voting = VotingDataMessage.AsObject;
export type OpenVote = OpenVoteMessage.AsObject;
export type CreatedVotes = CreatedVotesMessage.AsObject;
export type NotificationMailEntry = NotificationMailEntryMessage.AsObject;

export interface SavedVote {
    relationId: number;
    rating?: number;
    comment?: string;
}

export interface UpdateSavedVotesPayload{
    relationId: number,
    field: string,
    value: string | number
}

interface VotingState{
    view: {page: string; currentVote: number}
    openVotes?: OpenVote[];
    savedVotes: SavedVote[];
    allowedToVote: boolean;
    hasVoted: boolean;
    isSubscribed: boolean;
    isLoading: boolean;
    error?: Error;
}

export const initialState: VotingState = {
    view: {page: "overview", currentVote: 0},
    openVotes: undefined,
    savedVotes: new Array<SavedVote>(),
    allowedToVote: false,
    hasVoted: false,
    isSubscribed: false,
    isLoading: false,
    error: undefined,
}

const voting = createSlice({
    name: "voting",
    initialState,
    reducers: {
        getVotingDataStart: (state) => {
            state.isLoading = true;
        },
        getVotingDataSuccess: (state, { payload }: PayloadAction<Voting>) => {
            state.openVotes = payload.openVotesList;
            for (let i = 0; i < state.openVotes.length; i++) {
                let savedVote = {relationId: state.openVotes[i].relationId, rating: undefined, comment: undefined};
                state.savedVotes.push(savedVote);
            }
            state.allowedToVote = payload.canVote;
            state.hasVoted = payload.hasVoted;
            state.isLoading = false;
            state.error = undefined;
        },
        getVotingDataFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },
        setVotingDataStart: (state) => {
            state.isLoading = true;
        },
        setVotingDataSuccess: (state, { payload }: PayloadAction<CreatedVotes>) => {
            state.openVotes = undefined;
            state.allowedToVote = false;
            state.hasVoted = true;
            state.isLoading = false;
            state.error = undefined;
        },
        setVotingDataFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },
        setEmailEntryStart: (state) => {
            state.isLoading = true;
        },
        setEmailEntrySuccess: (state, { payload }: PayloadAction<NotificationMailEntry>) => {
            state.isSubscribed = true;
            state.isLoading = false;
            state.error = undefined;
        },
        setEmailEntryFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        changeToVotingOverview: (state) => {
            state.view.page = "overview";
        },
        changeToSpecificVote: (state, { payload }: PayloadAction<number>) => {
                state.view.page = "single";
                state.view.currentVote = payload;
        },
        nextVote: (state) => {
            state.view.page = "single";
            state.view.currentVote = (state.view.currentVote + 1) % state.savedVotes.length;
        },
        previousVote: (state) => {
            state.view.page = "single";
            state.view.currentVote = (state.savedVotes.length + state.view.currentVote - 1) % state.savedVotes.length;
        },

        updateSavedVotes: (state, {payload}: PayloadAction<UpdateSavedVotesPayload>) => {
            for (let i = 0; i<state.savedVotes.length; i++){
                let savedVote = state.savedVotes[i];
                if (savedVote.relationId === payload.relationId){
                    if (payload.field === "rating"){
                        savedVote.rating = payload.value as number;
                    } else{
                        savedVote.comment = payload.value as string;
                    }
                }
            }
        }
    },
});

const {
    getVotingDataStart,
    getVotingDataSuccess,
    getVotingDataFailure,

    setVotingDataStart,
    setVotingDataSuccess,
    setVotingDataFailure,

    setEmailEntryStart,
    setEmailEntrySuccess,
    setEmailEntryFailure,

    changeToVotingOverview,
    changeToSpecificVote,
    nextVote,
    previousVote,
    updateSavedVotes,
} = voting.actions;

export default voting.reducer;

// Actions
export const fetchVotingData = (): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(getVotingDataStart());
    const state = getState();
    const token = selectAccessToken(state);

    const request = new GetVotingDataRequest();
    return euleClient.getVotingData(request, getAuthMetadata(token))
        .then((votingData) => {
            dispatch(getVotingDataSuccess(votingData.toObject()));
        })
        .catch((err) => {
            dispatch(getVotingDataFailure(err));
        });
};

export const setVotingData = (savedVotes: SavedVote[]): AppThunk => async (dispatch, getState, euleClient) => {
    const state = getState();
    const token = selectAccessToken(state);

    const request = new CreateVotesRequest();
    let allDone = true;
    for (let i = 0; i<savedVotes.length; i++){
        let savedVote = savedVotes[i] as SavedVote;
        let vote = new VoteMessage();
        vote.setRelationId(savedVote.relationId);

        // Parse 5-star rating and add it to Vote Message
        let rating = new Answer();
        rating.setAnswerType(0);
        rating.setAnswer(savedVote.rating?.toString() as string);
        // Check if the rating is not empty, if not show confirm prompt (see below)
        if (rating.getAnswer() === ""){
            allDone = false;
        }
        vote.addAnswers(rating);

        // Parse comment and add it to Vote Message
        let comment = new Answer();
        comment.setAnswerType(1);
        comment.setAnswer(savedVote.comment as string);
        vote.addAnswers(comment);

        request.addVotes(vote);
    }

    // If one of the votes has no rating and the user has not confirmed submission, do not submit
    if (!allDone && !window.confirm(i18n.t("votingPage.confirmText")|| "")) {
        return;
    }

    dispatch(setVotingDataStart());
    return euleClient.createVotes(request, getAuthMetadata(token))
        .then((createdVotes) => {
            dispatch(setVotingDataSuccess(createdVotes.toObject()));
        })
        .catch((err) => {
            dispatch(setVotingDataFailure(err));
        });
};

export const setEmailEntry = (): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(setEmailEntryStart());
    const state = getState();
    const token = selectAccessToken(state);

    const request = new CreateNotificationMailEntryRequest();
    return euleClient.createNotificationMailEntry(request, getAuthMetadata(token))
        .then((emailEntry) => {
            dispatch(setEmailEntrySuccess(emailEntry.toObject()));
        })
        .catch((err) => {
            dispatch(setEmailEntryFailure(err));
        });
}

export {
    changeToVotingOverview,
    changeToSpecificVote,
    nextVote,
    previousVote,
    updateSavedVotes
}

// Selectors
type VotingSliceRoot = {voting: ReturnType<typeof voting.reducer>};

export const selectVotingView = (state: VotingSliceRoot) => state.voting.view;
export const selectOpenVotes = (state: VotingSliceRoot) => state.voting.openVotes;
export const selectSavedVotes = (state: VotingSliceRoot) => state.voting.savedVotes;
export const selectAllowedToVote = (state: VotingSliceRoot) => state.voting.allowedToVote;
export const selectHasVoted = (state: VotingSliceRoot) => state.voting.hasVoted;
export const selectIsSubscribed = (state: VotingSliceRoot) => state.voting.isSubscribed;
export const selectIsLoading = (state: VotingSliceRoot) => state.voting.isLoading;
export const selectError = (state: VotingSliceRoot) => state.voting.error;
