import { Card, Rating } from "@vseth/components";
import React from "react";

import {OpenVote, SavedVote} from "../../slices/votingSlice";

export const VoteCard: React.FunctionComponent<{ openVote: OpenVote; savedVote: SavedVote, onClick: React.MouseEventHandler<HTMLDivElement>; }> = ({ openVote, savedVote, onClick }) => {
  return (
    <Card
      className="voting__card card card-hover"
      style={{ backgroundColor: "rgba(255,255,255)" }}
      onClick={onClick}
    >
      <div className="card-body">
        <h5 className="card-title mb-0">{openVote.lecturerName}</h5>

        <div className="mb-2" style={{ color: "#009FE3" }}>
          <Rating value={savedVote.rating} emptyIcon="" />
        </div>

        <span className="card-text">{openVote.lectureName}</span>
      </div>
    </Card>
  );
};
