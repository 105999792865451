import React from "react";
import {useTranslation} from "react-i18next";

export const Footer: React.FC = ({children}) => {
    const { t } = useTranslation();
    return (
    <footer className="page-footer mt-5 pb-3">
      <div className="container">
        <hr className="footer-hr"/>
        <div className="footer-copyright text-center">
            © {new Date().getFullYear()} VSETH<br/>
            <a className="ml-2" href="https://account.vseth.ethz.ch/impressum">{t("footer.impressum")}</a>
            <a className="ml-2" href="https://account.vseth.ethz.ch/privacy">{t("footer.privacy")}</a>
        </div>
      </div>
    </footer>
  );
};