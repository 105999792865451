import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Centered} from "../../components/Centered";
import {setEmailEntry} from "../../slices/votingSlice";
import {selectIsAuthenticated} from "../../slices/authSlice";
import {AppDispatch} from "../../app/store";


export const SuccessfullySubscribed = () => {
    const {t} = useTranslation();
    const dispatch:AppDispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(setEmailEntry());
        }
    }, [dispatch, isAuthenticated]);

    return (
        <Centered>
            <>
                <h3>{t("successfullySubscribed.title")}</h3>
                <p>{t("successfullySubscribed.text")}</p>
                <Link to="/">
                    <button className="btn btn-primary">
                        {t("successfullySubscribed.button")}
                    </button>
                </Link>
            </>
        </Centered>
    );
};

