export const enumToStringJobStatus = (status: number) : string => {
    let statusText : string;
    switch (status) {
        case 0:
            statusText = "Initiated";
            break;
        case 1:
            statusText = "Starting";
            break;
        case 2:
            statusText = "Running";
            break;
        case 3:
            statusText = "Finished";
            break;
        case 4:
            statusText = "Aborted";
            break;
        case 5:
            statusText = "Error";
            break;
        default:
            statusText = "Undefined";
    }
    return statusText;
}

export const enumToStringJobName = (name: number) : string => {
    let nameText : string;
    switch (name) {
        case 0:
            nameText = "Import Data";
            break;
        case 1:
            nameText = "Open Poll";
            break;
        case 2:
            nameText = "Close Poll";
            break;
        case 3:
            nameText = "Votes Analysis";
            break;
        case 4:
            nameText = "Open Evaluation";
            break;
        case 5:
            nameText = "Close Evaluation";
            break;
        case 6:
            nameText = "Announce Results";
            break;
        default:
            nameText = "Undefined";
    }
    return nameText;
}

export const enumToStringStatus = (status: number) : string => {
    let statusText : string;
    switch (status) {
        case 0:
            statusText = "Planned";
            break;
        case 1:
            statusText = "Ready";
            break;
        case 2:
            statusText = "Open";
            break;
        case 3:
            statusText = "Closed";
            break;
        case 4:
            statusText = "Evaluation ready";
            break;
        case 5:
            statusText = "Evaluation open";
            break;
        case 6:
            statusText = "Evaluation closed";
            break;
        case 7:
            statusText = "Results announced";
            break;
        default:
            statusText = "Undefined";
    }
    return statusText;
}

export const enumToStringPollTimestampLabel = (timestampLabel: number) : string => {
    let timestampLabelText : string;
    switch (timestampLabel) {
        case 0:
            timestampLabelText = "";
            break;
        case 1:
            timestampLabelText = "Poll Start";
            break;
        case 2:
            timestampLabelText = "Poll End";
            break;
        case 3:
            timestampLabelText = "Winner Announcement";
            break;
        default:
            timestampLabelText = "Undefined";
    }
    return timestampLabelText;
}

export const enumToStringAward = (award: number) : string => {
    let awardText : string;
    switch (award) {
        case 0:
            awardText = "Goldene Eule";
            break;
        case 1:
            awardText = "CS Award";
            break;
        default:
            awardText = "Undefined";
    }
    return awardText;
}


export const enumToStringAwardCode = (award: number) : string => {
    let awardText : string;
    switch (award) {
        case 0:
            awardText = "goldene-eule";
            break;
        case 1:
            awardText = "cs-award";
            break;
        default:
            awardText = "undefined";
    }
    return awardText;
}