import React from "react";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useTranslation} from "react-i18next";

export const LectureResultComponent: React.FC<{ data: any; }> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="row mt-4">
                <div className="col-6">
                    <p>{t("resultsPage.averageGrade")}: {data.average}</p>
                </div>
                <div className="col-6">
                    <p>{t("resultsPage.totalVotes")}: {data.totalVotes}</p>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-6">
                    <h5>{t("resultsPage.averageByDept")}</h5>

                    <div style={{ width: "100%", height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                height={300}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                data={data.avgByDepartment}
                            >
                                <Tooltip />

                                <XAxis dataKey="key" />
                                <YAxis domain={[0, 5]} type="number" tickCount={6} />
                                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                <Bar dataKey="value" fill="#009FE3" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="col-6">
                    <h5>{t("resultsPage.votesByDept")}</h5>

                    <div style={{ width: "100%", height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                height={300}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                data={data.votesByDepartment}
                            >
                                <Tooltip />

                                <XAxis dataKey="key" />
                                <YAxis domain={[0, 5]} type="number" tickCount={6} />
                                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                <Bar dataKey="value" fill="#009FE3" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-6">
                    <h5>{t("resultsPage.averageByStatus")}</h5>

                    <div style={{ width: "100%", height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                height={300}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                data={data.avgByStudentStatus}
                            >
                                <Tooltip />

                                <XAxis dataKey="key" />
                                <YAxis domain={[0, 5]} type="number" tickCount={6} />
                                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                <Bar dataKey="value" fill="#009FE3" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="col-6">
                    <h5>{t("resultsPage.votesByStatus")}</h5>

                    <div style={{ width: "100%", height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                height={300}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                data={data.votesByStudentStatus}
                            >
                                <Tooltip />

                                <XAxis dataKey="key" />
                                <YAxis domain={[0, 5]} type="number" tickCount={6} />
                                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                <Bar dataKey="value" fill="#009FE3" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                    <h5>{t("resultsPage.comments")}</h5>
                    {data.comments.length === 0 && <p>{t("resultsPage.noComments")}</p>}
                    {data.comments.map((comment: string, i: number) => {
                        return (
                            <div
                                key={i}
                                style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                                className="my-3 p-3"
                            >
                                <blockquote className="blockquote">
                                    <cite>{comment}</cite>
                                </blockquote>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
