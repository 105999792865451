import React from "react";
import {Form, Button, InputField} from "@vseth/components";
import {useDispatch} from "react-redux";
import {changeAdminView} from "../../slices/adminSlice";
import {importPictures} from "../../slices/winnersSlice"
import {AppDispatch} from "../../app/store";

// TODO: Add translation

export const ImportPicturesPage = () => {
    const dispatch:AppDispatch = useDispatch();

    const handleSubmit = (e: any): void => {
        e.preventDefault();
        const filesHtml = document.getElementById('files') as HTMLInputElement;
        if (filesHtml != null) {
            const filesList = filesHtml.files;
            if (filesList != null) {
                dispatch(importPictures(filesList));
            }
        }
    }
    return (
        <div className="mt-5 container">
            <h2>Import pictures</h2>
            <Button>Go back</Button>
            <h5>Expected file format</h5>
            <p>
                The form accepts pictures in the .jgp/.jpeg format. These pictures must be named with the DOZIDE from ETH
                (not the winner_id or lecturer_id internal to this website). To find the DOZIDE of a lecturer, please use the search bar in the admin interface.
                As the file is uploaded to a cloud storage instance, it is advised to only upload the necessary pictures for privacy reasons.
                <br/><br/>
            </p>

            <h5>Import</h5>
            <Form style={{textAlign: "left"}} onSubmit={(e) => handleSubmit(e)}>
                <InputField type="file" id="files" accept=".jpg, .jpeg" label="Pictures (see requirements above)" multiple/>
                <Button color="primary" type="submit" className="btn btn-primary" style={{justifyContent:'center'}}>Submit</Button>
                <Button onClick={() => { dispatch(changeAdminView("main"));} } style={{justifyContent:'center', margin: 3}}>Go back</Button>
            </Form>
            <br/>
        </div>
    )

}
