// Created by Marc Widmer

import * as google_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";
import * as google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";

export function getAuthMetadata(token?: string) {
    return {
        authorization: `Bearer ${token}`,
    };
}

/**
 * Helper function to turn a protobuf Timestamp.AsObject into Date
 */
export const timestampObjToDate = (
    time?: google_timestamp_pb.Timestamp.AsObject
): Date => {
    const date = new Date(0);
    if (time) date.setUTCSeconds(time.seconds);
    return date;
};

/**
 * Helper method that turns Timestamp.AsObject into Timestamp
 */
export const timeObjToMessage = (
    time?: google_timestamp_pb.Timestamp.AsObject
): google_timestamp_pb.Timestamp => {
    const timestamp = new google_timestamp_pb.Timestamp();
    if (time) {
        timestamp.setSeconds(time.seconds);
        timestamp.setNanos(time.nanos);
    }
    return timestamp;
};

/**
 * Helper method that turns Date into Timestamp.AsObject
 */
export const dateToTimestampObj = (
    date?: Date
): google_timestamp_pb.Timestamp.AsObject => {
    const timestamp = new google_timestamp_pb.Timestamp();
    if (date) {
        timestamp.fromDate(date);
    }
    return timestamp.toObject();
};

/**
 * Helper method that turns Date-Time string pair into Timestamp
 */

export const dateTimeStringsToTimestamp = (date: string, time: string): google_timestamp_pb.Timestamp => {
    const timestamp = new google_protobuf_timestamp_pb.Timestamp();
    let startDate = new Date(date.concat("T").concat(time));
    timestamp.setSeconds(startDate.getTime()/1000);
    return timestamp;
}