import React from "react";
import {InputField, Form, Button} from "@vseth/components";
import {useDispatch} from "react-redux";
import {updatePoll, changeAdminView, Poll, fetchPolls} from "../../slices/adminSlice";
import {timestampObjToDate} from "../../util/proto";
import {format} from "date-fns";
import {AppDispatch} from "../../app/store";

export const UpdatePollPage: React.FunctionComponent<{ poll: Poll }> = (props) => {
    const dispatch:AppDispatch = useDispatch();

    const handleSubmit = (e: any): void => {
        e.preventDefault();

        const phdsAllowed =  (document.getElementById("phds_allowed") as HTMLInputElement).checked;
        const year = parseInt((document.getElementById("year") as HTMLInputElement).value);

        const start = {
            date: (document.getElementById("start_date") as HTMLInputElement).value,
            time: (document.getElementById("start_time") as HTMLInputElement).value,
        }
        const end = {
            date: (document.getElementById("end_date") as HTMLInputElement).value,
            time: (document.getElementById("end_time") as HTMLInputElement).value,
        }

        const announcement = {
            date: (document.getElementById("announcement_date") as HTMLInputElement).value,
            time: (document.getElementById("announcement_time") as HTMLInputElement).value,
        }
        dispatch(updatePoll(props.poll.pollId, phdsAllowed, year, start, end, announcement));
        window.alert("Poll updated!");
        dispatch(fetchPolls());
        dispatch(changeAdminView("main"));
    }

    const start = timestampObjToDate(props.poll.startTime);
    const end = timestampObjToDate(props.poll.endTime);
    const announcement = timestampObjToDate(props.poll.announcementTime);
    const datefmt = 'yyyy-MM-dd'
    const timefmt = 'HH:mm'

    return (
        <div className="mt-5 container">
            <h2>Update an existing poll</h2>

            <Form style={{textAlign: "left", alignItems: "left"}} onSubmit={ (e) => {handleSubmit(e)} }>
                <InputField type="number" id="year" label="Year (must be at least this year)" defaultValue={props.poll.year}/>
                <InputField type="checkbox" id="phds_allowed" label="PhDs are allowed to vote" defaultChecked={props.poll.phdsCanVote}/>
                <InputField type="date" id="start_date" label="Start date (must be at least this year)" defaultValue={format(start,datefmt)}/>
                <InputField type="time" id="start_time" label="Start time" defaultValue={format(start,timefmt)}/>
                <InputField type="date" id="end_date" label="End date (must be after the start date)" defaultValue={format(end,datefmt)}/>
                <InputField type="time" id="end_time" label="End time (must be after the start date)" defaultValue={format(end,timefmt)}/>
                <InputField type="date" id="announcement_date" label="Announcement date (at least the next ETH Day)" defaultValue={format(announcement,datefmt)}/>
                <InputField type="time" id="announcement_time" label="Announcement time (make sure it is after the ceremony)" defaultValue={format(announcement,timefmt)}/>
                <Button color="primary" type="submit" style={{justifyContent:'center'}}>Submit</Button>
                <Button onClick={() => { dispatch(changeAdminView("main"));} } style={{justifyContent:'center', margin: 3}}>Go back</Button>
            </Form>
            <br/>
        </div>
    )
}


