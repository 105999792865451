import React from "react";
import {useTranslation} from "react-i18next";

import {
    changeToSpecificVote,
    selectOpenVotes,
    selectSavedVotes,
    selectVotingView,
    setVotingData
} from "../../slices/votingSlice";

import { ProgressBar, ProgressItem } from "../../components/voting/ProgressBar";
import { SingleVotePage } from "./SingleVotePage";
import { VotingOverviewPage } from "./VotingOverviewPage";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@vseth/components";
import {useHistory} from "react-router-dom";
import {AppDispatch} from "../../app/store";


export const VotingSectionComponent: React.FC<{}> = () => {
    const { t } = useTranslation();

    const openVotes = useSelector(selectOpenVotes);
    const savedVotes = useSelector(selectSavedVotes);
    const view = useSelector(selectVotingView);
    const dispatch:AppDispatch = useDispatch();
    const history = useHistory();

    const finished = () => {
        return savedVotes.filter((vote) => !vote.rating).length === 0;
    }

    const getCurrentProgress = () => {
        const items = [] as ProgressItem[];
        savedVotes.forEach((vote, i) => {
            items.push({
                filled: !!vote.rating,
                onClick: () => {
                    dispatch(changeToSpecificVote(i))
                },
                active: view.currentVote === i,
            });
        });
        return items;
    }
    return (
        <>
            <div className="d-flex">
                <ProgressBar items={getCurrentProgress()} />
                <div className="voting__formLine">
                    <Button onClick={() => {
                        dispatch(setVotingData(savedVotes));
                        history.push("/vote");
                    }}>
                            {t("votingPage.actions.submitVoting")}
                    </Button>
                </div>
            </div>
            {view.page === "overview" && (
                <>
                    <VotingOverviewPage
                        openVotes={openVotes}
                        savedVotes={savedVotes}
                    />
                </>
            )}
            {view.page === "single" && (
                <>
                    <SingleVotePage
                        openVote={openVotes?.[view.currentVote]}
                        savedVote={savedVotes[view.currentVote]}
                        finished={finished()}
                    />
                </>
            )}
        </>
    );
}
