import React from "react";
import {Button, Form, InputField} from "@vseth/components";
// import {useDispatch} from "react-redux";

export const LecturerSearch: React.FunctionComponent = () => {
    // const dispatch = useDispatch();

    const handleSubmit = (e: any): void => {
        e.preventDefault();
        // dispatch();
    }

    return (
        <div>
            <p>You can look for a specific lecturer by entering their full name (name + surname) or their DOZIDE.</p>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <InputField type="text" id="text-field" label="Search query"/>
                <Button color="primary" type="submit" style={{justifyContent:'center'}}>Search</Button>
            </Form>
        </div>
    );
}