import React from "react";
import {useSelector} from "react-redux";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {createInstance, MatomoProvider} from "@jonkoops/matomo-tracker-react/es";

import {ProtectedRoute} from "@vseth/auth-components";

import "./App.scss";
import {selectUserRoles} from "../slices/authSlice";
import {CustomAuthProvider} from "../components/auth/CustomAuthProvider";
import {Centered} from "../components/Centered";
import {Footer} from "../components/Footer";
import {Navbar} from "../components/Navbar";

import {HomePage} from "../pages/HomePage";
import {VotingPage} from "../pages/voting/VotingPage";
import {SuccessfullySubscribed} from "../pages/voting/SuccessfullySubscribed";
import {AdminPage} from "../pages/admin/AdminPage";
import {WinnersPage} from "../pages/winners/WinnersPage";
import {ResultsPage} from "../pages/results/ResultsPage";


// Matomo monitoring
const matomoInstance = createInstance({
    urlBase: "https://analytics.vseth.ethz.ch",
    siteId: 1,
    linkTracking: false,
});

const EuleApp: React.FC = () => {
    const appRoles = useSelector(selectUserRoles);

    const isAdmin = () => {
        return appRoles.includes("admin");
    };
     const allowedToView = () => {
        return appRoles.reduce((prev: boolean, role: string) => /^d-[a-z]{4}-view$/.test(role) || role === "view-all" || prev, false);
    };

    return (
        <CustomAuthProvider>
            <MatomoProvider value={matomoInstance}>
                <BrowserRouter>
                    <div className="mobile-capable">
                        <Navbar/>
                        <main className="main__container">
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    component={HomePage}
                                />
                                <Route
                                    exact
                                    path="/winners"
                                    component={WinnersPage}
                                />
                                <Route
                                    exact
                                    path="/subscribed"
                                    component={SuccessfullySubscribed}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/admin"
                                    component={AdminPage}
                                    isAuthorized={() => isAdmin()}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/vote"
                                    component={VotingPage}
                                    isAuthorized={() => true}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/results"
                                    component={ResultsPage}
                                    isAuthorized={() => allowedToView()}
                                />
                                <Route
                                    component={() => (<Centered><h2>404</h2></Centered>)}
                                />
                            </Switch>
                        </main>
                        <Footer/>
                    </div>
                </BrowserRouter>
            </MatomoProvider>
        </CustomAuthProvider>
    );
}

export default EuleApp;
