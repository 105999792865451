import React from "react";
import BootstrapTable, {SelectRowProps} from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';

import {Poll, setSelectedPoll} from "../../slices/adminSlice";
import {timestampObjToDate} from "../../util/proto";
import {enumToStringStatus} from "../../util/enumConverters";
import {useDispatch} from "react-redux";

interface PollInTable extends Poll {
    creation: string,
    start: string,
    end: string,
    announcement: string,
    statusText: string,
}

export const PollsTable: React.FC <{data: Poll[], isLoading: boolean}> = ({data, isLoading}) => {
    const dispatch = useDispatch();

    if (data === undefined || data.length === 0) {
        if (isLoading) {
            return <div>Data is loading</div>;
        }
        return <div>No polls</div>;
    }

    const dataConverted = data.map((element) => ({
        ...element,
        creation: timestampObjToDate(element.createTime).toLocaleString(),
        start: timestampObjToDate(element.startTime).toLocaleString(),
        end: timestampObjToDate(element.endTime).toLocaleString(),
        announcement: timestampObjToDate(element.announcementTime).toLocaleString(),
        statusText: enumToStringStatus(element.status),
    }));

    const headerStyle = { backgroundColor: '#009fe3', color: 'white'};
    const columns = [
        { text: 'Poll ID', dataField: 'pollId', sort: true, headerStyle: headerStyle},
        { text: 'Year', dataField: 'year', sort: true, headerStyle: headerStyle},
        { text: 'PhDs allowed', dataField: 'phdsCanVote', sort: true, headerStyle: headerStyle},
        { text: 'Creation', dataField: 'creation', sort: true, headerStyle: headerStyle},
        { text: 'Start', dataField: 'start', sort: true, headerStyle: headerStyle},
        { text: 'End', dataField: 'end', sort: true, headerStyle: headerStyle},
        { text: 'Announcement', dataField: 'announcement', sort: true, headerStyle: headerStyle},
        { text: 'Status', dataField: 'statusText', sort: true, headerStyle: headerStyle},
        { text: 'Votes', dataField: 'votesCount', headerStyle: headerStyle},
        { text: 'Voters', dataField: 'votersCount', headerStyle: headerStyle},
    ];

    const handleOnSelect = (poll: PollInTable, isSelect: boolean, rowIndex: number) => {
        if (isSelect) {
            dispatch(setSelectedPoll(poll));
        } else {
            dispatch(setSelectedPoll(undefined));
        }
        return;
    }

    const selectRowProp: SelectRowProps<any> = {
        mode: "radio",
        clickToSelect: true,
        onSelect: handleOnSelect,
        headerColumnStyle: headerStyle,
    };

    return (
        <div style={{ maxWidth: '100%' }}>
            <BootstrapTable columns = {columns}
                            data = {dataConverted}
                            keyField = 'pollId'
                            pagination = {paginationFactory({sizePerPage: 10})}
                            selectRow = { selectRowProp }
                            />
        </div>
    );
};