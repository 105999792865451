import {groupBy} from "ramda";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useMatomo} from "@jonkoops/matomo-tracker-react/es";

import {useDispatch, useSelector} from "react-redux";
import {WinnersYearSection} from "./WinnersYearSection";
import {fetchWinners, selectWinners, Winner} from "../../slices/winnersSlice";
import {Spinner} from "@vseth/components";
import {Centered} from "../../components/Centered";
import {AppDispatch} from "../../app/store";


export const WinnersPage = () => {
    // track the page view
    const { trackPageView } = useMatomo();
    useEffect( () => {trackPageView({});}, [trackPageView]);

    const {t} = useTranslation();

    const winners = useSelector(selectWinners);

    const dispatch:AppDispatch = useDispatch();
    useEffect(() => {
        if (winners === undefined){
            dispatch(fetchWinners());
        }
    }, [dispatch, winners]);

    if (winners !== undefined) {
        const groupedByYear = groupBy<Winner>((winner) => winner.award?.year.toString() as string, winners as Winner[]);
        const years = Object.keys(groupedByYear).sort((a, b) => parseInt(b) - parseInt(a),);
        return (
            <section className="professors__wrapper">
                <div className="container">
                    <h1 className="mb-4 mt-5">{t("formerWinners.title")}</h1>
                    <p className="mb-4 mt-5">{t("formerWinners.thankYou")}</p>
                    {years.map((year) => (
                        <WinnersYearSection
                            key={year}
                            year={year}
                            winners={groupedByYear[year]}
                        />
                    ))}
                </div>
            </section>
        );
    }
    else {
        return (
            <section className="professors__wrapper">
                <div className="container">
                    <h2 className="mb-4 mt-5">{t("formerWinners.title")}</h2>
                    <p className="mb-4 mt-5">{t("formerWinners.thankYou")}</p>
                    <Centered><Spinner color="primary"/></Centered>
                </div>
            </section>
        );
    }
};


