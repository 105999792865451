import {
    VSETHNavbar,
    StarHalfFilledIcon,
    HomeIcon,
    LanguageIcon,
    MedalIcon,
    EditIcon,
    ChartIcon
} from "@vseth/components"
import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";

import { Item } from "@vseth/components/dist/components/VSETHNav/VSETHNavbar";
import { LinkProps } from "@vseth/components/dist/types";

import { LoginButton } from "./auth/LoginButton";
import { LoginButtonMobile } from "./auth/LoginButtonMobile";
import { Link, NavLink as RRNavLink } from "react-router-dom";
import {selectIsAuthenticated, selectUserRoles} from "../slices/authSlice";

const EuleLogo = () => {
    const { t } = useTranslation();
    return (<Link className="navbar-brand" to="/">{t("app.name")}</Link>);
};

const NavLink: React.FC<LinkProps> = ({
                                          href,
                                          component: Comp,
                                          disabled,
                                          exact,
                                          replace,
                                          innerRef,
                                          children,
                                      }) => {
    if (Comp) {
        return (
            <RRNavLink to={href || ""} className="nav-link" ref={innerRef}>
                <Comp>{children}</Comp>
            </RRNavLink>
        );
    } else {
        return (
            <RRNavLink to={href || ""} className="nav-link" ref={innerRef}>
                {children}
            </RRNavLink>
        );
    }
};

/**
 * Navbar enhanced with react router
 */
export const Navbar = withRouter(({ history, location }) => {
    // tslint:disable-next-line:no-shadowed-variable
    const { t, i18n } = useTranslation();

    // select the authentication state
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const userRoles = useSelector(selectUserRoles);

    const optionalMenuPoints = [] as Item[];

    if (isAuthenticated && userRoles.includes("admin")) {
        optionalMenuPoints.push({
            title: "admin",
            icon: EditIcon,
            active: location.pathname === "/admin",
            href: "/admin"
        });
    }

    const hasViewRole = userRoles.reduce(
        (prev: boolean, role: string) =>
            /^d-[a-z]{4}-view$/.test(role) || role === "view-all" || prev,
        false
    );

    if (isAuthenticated && hasViewRole) {
      optionalMenuPoints.push({
        title: t("resultsPage.title"),
        icon: ChartIcon,
        active: location.pathname === "/results/",
        href: "/results/",
      });
    }
    return (
        <VSETHNavbar
            NavLink={NavLink}
            lang={t("lang")}
            primaryLogo={
                <a href="https://vseth.ethz.ch" target="_blank" rel="noopener noreferrer">
                    <img
                        src="https://static.vseth.ethz.ch/assets/vseth-0000-vseth/logo-mono.svg"
                        alt="VSETH"
                        style={{ filter: "invert(1) contrast(42)", height: 30 }}
                    />
                </a>
            }
            primarySlot={<LoginButton />}
            mobileTopSlot={<LoginButtonMobile />}
            secondaryLogo={<EuleLogo />}
            primaryActionItems={[
                {
                    title: "language",
                    icon: LanguageIcon,
                    onClick: () => {
                        i18n.changeLanguage(i18n.language === "de" ? "en" : "de");
                    },
                },
            ]}
            secondaryNavItems={[
                {
                    title: t("general.home"),
                    icon: HomeIcon,
                    active: location.pathname === "/",
                    href: "/",
                },
                {
                    title: t("votingPage.title"),
                    icon: StarHalfFilledIcon,
                    active: location.pathname === "/vote",
                    href: "/vote",
                },
                {
                    title: t("formerWinners.title"),
                    icon: MedalIcon,
                    active: location.pathname === "/winners",
                    href: "/winners",
                },
                ...optionalMenuPoints,
            ]}
        />
    );
});
