import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMatomo} from "@jonkoops/matomo-tracker-react/es";

import {VotingSectionComponent} from "./VotingSectionComponent";
import {Centered} from "../../components/Centered";
import {SuccessVoting} from "../../components/voting/SuccessVoting";
import {NotEligible} from "../../components/voting/NotEligible";
import {selectIsAuthenticated} from "../../slices/authSlice";
import {
    fetchVotingData,
    selectAllowedToVote,
    selectError,
    selectHasVoted, selectIsLoading,
    selectOpenVotes,
} from "../../slices/votingSlice";
import {Spinner} from "@vseth/components";
import {AppDispatch} from "../../app/store";

export const VotingPage = () => {
    // track the page view
    const { trackPageView } = useMatomo();
    useEffect( () => {trackPageView({});}, [trackPageView]);

    const isAuthenticated = useSelector(selectIsAuthenticated);
    const openVotes = useSelector(selectOpenVotes);
    const allowedToVote = useSelector(selectAllowedToVote);
    const hasVoted = useSelector(selectHasVoted);
    const error = useSelector(selectError)
    const isLoading = useSelector(selectIsLoading);

    const dispatch:AppDispatch = useDispatch();
    useEffect(() => {
        // Only load if necessary
        if (isAuthenticated && openVotes === undefined) {
            dispatch(fetchVotingData());
        }
    }, [dispatch, isAuthenticated, openVotes]);

    if (!isAuthenticated) {
        return <p>Please log in</p>;
    } else {
        return (
            <section className="voting__wrapper">
                <div className="container mt-3">
                    {error !== undefined && (
                        <Centered>
                            <p>Error :(</p>
                        </Centered>
                    )}
                    {error === undefined && isLoading  &&
                    (<Centered>
                        <Spinner color="primary"/>
                    </Centered>)}
                    {error === undefined && !isLoading && openVotes?.length !== 0  && <VotingSectionComponent/>}
                    {error === undefined && !isLoading && !allowedToVote && !hasVoted && <NotEligible />}
                    {error === undefined && !isLoading && hasVoted && <SuccessVoting />}


                </div>
            </section>
        );
    }
};