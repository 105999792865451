import {Icon, ICONS, Rating, TextareaField} from "@vseth/components";
import React from "react";
import { useTranslation } from "react-i18next";

import {SavedVote, OpenVote, previousVote, nextVote, updateSavedVotes, changeToVotingOverview} from "../../slices/votingSlice";
import {useDispatch} from "react-redux";

export interface singleVoteState {
    openVote: OpenVote | undefined;
    savedVote: SavedVote;
    finished: boolean;
}

export const SingleVotePage: React.FunctionComponent<singleVoteState> = ({
                                                                             openVote,
                                                                             savedVote,
                                                                             finished,
                                                                         }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    return (
        <div className="voting__singlevote">
            <h4 className=" mt-5">{openVote?.lecturerName}</h4>
            <h5 className="voting__subtitle mb-4">{openVote?.lectureName}</h5>

            <p>{t("singleVote.generalExplanation")}</p>

            <div style={{ fontSize: "36px", color: "#009FE3" }}>
                <Rating
                    onChange={(i) => {
                        dispatch(updateSavedVotes(
                            {
                                relationId: savedVote.relationId,
                                field: "rating",
                                value: i}
                            ));
                    }}
                    value={savedVote.rating}
                />
            </div>
            <p className="mt-3">{t("singleVote.commentExplanation")}</p>

            <TextareaField
                label={t("singleVote.comment")}
                helpText={t("singleVote.helpText")}
                textareaProps={{
                    onChange: (event) => {
                        dispatch(updateSavedVotes(
                            {
                                relationId: savedVote.relationId,
                                field: "comment",
                                value: event.target.value}
                        ));
                    },
                    value: savedVote.comment ? savedVote.comment : "",
                }}
            />

            <div className="voting__single-wrapper">
                <div className="voting__single-left" onClick={() => dispatch(previousVote())}>
                    <Icon name="left" icon={ICONS.LEFT}/>
                </div>
                <div className="voting__single-main">
                    <button className="btn btn-primary" onClick={() => dispatch(changeToVotingOverview())}>
                        {t("singleVote.actions.backToOverview")}
                    </button>
                </div>
                <div className="voting__single-right" onClick={() => dispatch(nextVote())}>
                    <Icon name="right" icon={ICONS.RIGHT}/>
                </div>
            </div>
        </div>
    );
};
