import React from "react";
import BootstrapTable, {SelectRowProps} from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';

import {
    setSelectedWinner,
} from "../../slices/adminSlice";
import {enumToStringAward} from "../../util/enumConverters";
import {useDispatch} from "react-redux";
import {Winner} from "../../slices/winnersSlice";

interface WinnerInTable extends Winner {
    fullNameText: string,
    year: number,
    awardText: string,
}

export const WinnersTable: React.FC <{data: Winner[], isLoading: boolean}> = ({data, isLoading}) => {
    const dispatch = useDispatch();

    if (data === undefined || data.length === 0) {
        if (isLoading) {
            return <div>Data is loading</div>;
        }
        return <div>No winners</div>;
    }

    const dataConverted = data.map((element) => ({
        ...element,
        awardText: enumToStringAward(element.award?.awardType as number),
        year: element.award?.year as number,
        fullNameText: element.academicTitle + " " + element.firstName + " " + element.lastName,
    }));

    const headerStyle = { backgroundColor: '#009FE3', color: 'white'};
    const columns = [
        { text: 'Winner ID', dataField: 'winnerId', sort: true, headerStyle: headerStyle},
        { text: 'Name', dataField: 'fullNameText', sort: true, headerStyle: headerStyle},
        { text: 'Year', dataField: 'year', sort: true, headerStyle: headerStyle},
        { text: 'Award', dataField: 'awardText', sort: true, headerStyle: headerStyle},
        { text: 'Department', dataField: 'department', sort: true, headerStyle: headerStyle},
        { text: 'Picture Consent', dataField: 'pictureConsent', sort: true, headerStyle: headerStyle},
        { text: 'DOZIDE', dataField: 'dozId', sort: true, headerStyle: headerStyle},
        { text: 'Lecturer ID', dataField: 'lecturerId', sort: true, headerStyle: headerStyle},
    ];

    const handleOnSelect = (winner: WinnerInTable, isSelect: boolean, rowIndex: number) => {
        if (isSelect) {
            dispatch(setSelectedWinner(winner));
        } else {
            dispatch(setSelectedWinner(undefined));
        }
        return;
    }

    const selectRowProp: SelectRowProps<any> = {
        mode: "radio",
        clickToSelect: true,
        onSelect: handleOnSelect,
        headerColumnStyle: headerStyle,
    };

    return (
        <div style={{ maxWidth: '100%' }}>
            <BootstrapTable columns={columns}
                            data={dataConverted}
                            keyField='winnerId'
                            pagination={paginationFactory({sizePerPage: 10})}
                            selectRow={ selectRowProp }/>
        </div>
    );
};