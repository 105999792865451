import React from "react";
import {useAuth} from "@vseth/auth";
import {Button} from "@vseth/components";

export const LoginButtonMobile: React.FunctionComponent<{}> = () => {
  const auth = useAuth();

  return (
    <Button
      className="btn ml-3"
      onClick={() => (auth.authState.isAuthenticated ? auth.logout() : auth.login())}
    >
      {auth.authState.isAuthenticated ? "Log Out" : "Log In"}
    </Button>
  );
};
