import React, { useEffect } from "react";
import {useMatomo} from "@jonkoops/matomo-tracker-react/es";

import {Icon, ICONS} from "@vseth/components";
import GoldeneEuleLogo from "../images/GoldeneEuleBlack.svg";
import {useTranslation} from "react-i18next";


export const HomePage = () => {
  // Set up translation
  const { t } = useTranslation();
  // track the page view
  const { trackPageView } = useMatomo();
  useEffect( () => {trackPageView({});}, [trackPageView]);

  return (
      <section className="introduction__wrapper">
        <img alt="Golden Owl Logo" src={GoldeneEuleLogo} className="introduction__logo" />

        <div className="container">
          <h2 className="introduction__title">{t("landingPage.title")}</h2>
          <div className="row">
            <div className="col-md">
              <h5>{t("landingPage.titleEule")}</h5>
              <p>{t("landingPage.textEule")}</p>
            </div>
            <div className="col-md">
              <h5>{t("landingPage.titleCS")}</h5>
              <p>{t("landingPage.textCS")}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <h4 className="text-center mt-5">
                {t("landingPage.moreInfoTitle")}
              </h4>
              <p className="text-center mt-3 mb-3">
                {t("landingPage.moreInfoText")}
              </p>
              <div className="introduction__link-container">
                <a href={t("landingPage.ethURL")} className="d-flex py-3">
                  {t("landingPage.ethLink")}
                  <Icon className="ml-2" name="right" icon={ICONS.RIGHT}/>
                </a>
                <a href={t("landingPage.pictureURL")} className="d-flex py-3">
                  {t("landingPage.picturesLink")}
                  <Icon className="ml-2" name="right" icon={ICONS.RIGHT}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};
