import React from "react";
import {Button, Spinner} from "@vseth/components";

interface RBProps {
  isLoading: boolean;
  reloadAction: () => void;
}

const buttonStyle = {justifyContent:'center', marginRight: 10, marginBottom: 10}

export const ReloadButton = ({isLoading, reloadAction}: RBProps) => {
  const item = isLoading ? <Spinner color="primary"/> : "Reload";

  return (
    <Button size="sm" style={buttonStyle} onClick={() => !isLoading && reloadAction()}>
      {item}
    </Button>
  );
}
