import React from "react";
import {InputField, Form, Button, CustomInput} from "@vseth/components";
import {useDispatch} from "react-redux";
import {changeAdminView} from "../../slices/adminSlice";
import {fetchWinners, updateWinner, Winner} from "../../slices/winnersSlice"
import {enumToStringAward, enumToStringAwardCode} from "../../util/enumConverters";
import {AwardType} from "../../proto/eule_pb";
import {AppDispatch} from "../../app/store";

export const UpdateWinnerPage: React.FunctionComponent<{ winner: Winner }> = (props) => {
    const dispatch:AppDispatch = useDispatch();

    const handleSubmit = (e: any): void => {
        e.preventDefault();
        const year = parseInt((document.getElementById("year") as HTMLInputElement).value);
        const awardType = (document.getElementById("award") as HTMLInputElement).value;
        const department = (document.getElementById("department") as HTMLInputElement).value;
        dispatch(updateWinner(props.winner.winnerId, props.winner.lecturerId, year, awardType, department));
        window.alert("Winner updated!");
        dispatch(fetchWinners());
        dispatch(changeAdminView("main"));
    }

    return (
        <div className="mt-5 container">
            <h2>Updating {props.winner.academicTitle + " " + props.winner.firstName + " " + props.winner.lastName}</h2>
            <h6>{enumToStringAward(props.winner.award?.awardType as AwardType)} - {props.winner.department} - {props.winner.award?.year}</h6>

            <Form style={{textAlign: "left"}} onSubmit={ (e) => {handleSubmit(e)} }>
                <InputField type="number" id="year" label="Year" defaultValue={props.winner.award?.year}/>
                <InputField type="text" id="department" label="Department" defaultValue={props.winner.department}/>
                <CustomInput type="select" id="award" label="Award" defaultValue={enumToStringAwardCode(props.winner.award?.awardType as AwardType)}>
                    <option value="goldene-eule">Goldene Eule</option>
                    <option value="csaward">CS Award</option>
                </CustomInput>
                <Button color="primary" type="submit" className="btn btn-primary" style={{justifyContent:'center'}}>Submit</Button>
                <Button onClick={() => { dispatch(changeAdminView("main"));} } style={{justifyContent:'center', margin: 3}}>Go back</Button>
            </Form>
            <br/>
        </div>
    )
}


