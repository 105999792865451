import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {AppThunk} from "../app/store";
import {selectAccessToken} from "./authSlice";
import {dateTimeStringsToTimestamp, getAuthMetadata} from "../util/proto";

import {Winner} from "./winnersSlice";
import {
    FileUrl as FileUrlMessage,
    Poll as PollMessage,
    Job as JobMessage,
    CloseEvaluationRequest,
    CreatePollRequest,
    GetFileUploadUrlRequest,
    GetNotificationMailEntriesRequest,
    ListJobsRequest,
    ListPollsRequest,
    UpdatePollRequest, JobUpdateAction, UpdateJobRequest,
} from "../proto/eule_pb"


export type Poll = PollMessage.AsObject;
export type Job = JobMessage.AsObject;
export type FileUrl = FileUrlMessage.AsObject;

interface AdminState{
    view: string;
    polls?: Poll[];
    jobs?: Job[];
    winners? : Winner[];
    urlEmailEntries?: string,
    isLoading: boolean;
    error?: Error;
    selectedPoll?: Poll,
    selectedJob?: Job,
    selectedWinner?: Winner,
}

export const initialState: AdminState = {
    view: "main",
    polls: undefined,
    jobs: undefined,
    winners: undefined,
    urlEmailEntries: undefined,
    isLoading: false,
    error: undefined,
}

const admin = createSlice({
    name: "admin",
    initialState,
    reducers: {
        listPollsStart: (state) => {
            state.isLoading = true;
            state.error = undefined;
        },
        listPollsSuccess: (state, { payload }: PayloadAction<Poll[]>) => {
            state.polls = payload;
            state.isLoading = false;
            state.error = undefined;
        },
        listPollsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },
        listJobsStart: (state) => {
            state.isLoading = true;
        },

        listJobsSuccess: (state, { payload }: PayloadAction<Job[]>) => {
            state.jobs = payload;
            state.isLoading = false;
            state.error = undefined;
        },
        listJobsFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        getEmailEntriesUrlStart: (state) => {
            state.isLoading = true;
        },
        getEmailEntriesUrlSuccess:(state, {payload}: PayloadAction<FileUrl>) => {
            state.urlEmailEntries = payload.fileUrl;
            state.isLoading = false;
            state.error = undefined;
        },
        getEmailEntriesUrlFailure:(state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        createPollStart: (state) => {
            state.isLoading = false;
        },
        createPollSuccess: (state, {payload}: PayloadAction<Poll>) => {
            state.isLoading = false;
            state.error = undefined;
        },
        createPollFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        updatePollStart: (state) => {
            state.isLoading = false;
        },
        updatePollSuccess: (state, {payload}: PayloadAction<Poll>) => {
            state.isLoading = false;
            state.error = undefined;
        },
        updatePollFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        updateJobStart: (state) => {
            state.isLoading = false;
        },
        updateJobSuccess: (state) => {
            state.isLoading = false;
            state.error = undefined;
        },
        updateJobFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        closeEvaluationStart: (state) => {
            state.isLoading = false;
        },
        closeEvaluationSuccess: (state) => {
            state.isLoading = false;
            state.error = undefined;
        },
        closeEvaluationFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },

        changeAdminView: (state, { payload }: PayloadAction<string>) => {
            state.view = payload;
            if (payload === "main"){
                state.selectedPoll = undefined;
                state.selectedJob = undefined;
                state.selectedWinner = undefined;
            }

        },

        setSelectedPoll: (state, { payload }: PayloadAction<Poll | undefined>) => {
            state.selectedPoll = payload;
        },
        setSelectedJob: (state, { payload }: PayloadAction<Job | undefined>) => {
            state.selectedJob = payload;
        },
        setSelectedWinner: (state, { payload }: PayloadAction<Winner | undefined>) => {
            state.selectedWinner = payload;
        },
    },
});

const {
    listPollsStart,
    listPollsSuccess,
    listPollsFailure,

    listJobsStart,
    listJobsSuccess,
    listJobsFailure,

    getEmailEntriesUrlStart,
    getEmailEntriesUrlSuccess,
    getEmailEntriesUrlFailure,

    createPollStart,
    createPollSuccess,
    createPollFailure,

    updatePollStart,
    updatePollSuccess,
    updatePollFailure,

    updateJobStart,
    updateJobSuccess,
    updateJobFailure,

    closeEvaluationStart,
    closeEvaluationSuccess,
    closeEvaluationFailure,

    changeAdminView,
    setSelectedPoll,
    setSelectedJob,
    setSelectedWinner,
} = admin.actions;

export default admin.reducer;

// Actions
export const fetchPolls = (): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(listPollsStart());
    const state = getState();
    const token = selectAccessToken(state);

    let request = new ListPollsRequest();
    return euleClient.listPolls(request, getAuthMetadata(token))
        .then((polls) => {
            dispatch(
                listPollsSuccess(
                    polls.getPollsList().map((p) => p.toObject())
                )
            );
        })
        .catch((err) => {
            dispatch(listPollsFailure(err));
        });
}

export const fetchJobs = (): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(listJobsStart());
    const state = getState();
    const token = selectAccessToken(state);

    let request = new ListJobsRequest();
    return euleClient.listJobs(request, getAuthMetadata(token))
        .then((jobs) => {
            dispatch(
                listJobsSuccess(
                    jobs.getJobsList().map((j) => j.toObject())
                )
            );
        })
        .catch((err) => {
            dispatch(listJobsFailure(err));
        });
}

export const fetchEmailEntries = (): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(getEmailEntriesUrlStart());
    const state = getState();
    const token = selectAccessToken(state);

    let request = new GetNotificationMailEntriesRequest();
    return euleClient.getNotificationMailEntries(request, getAuthMetadata(token))
        .then((url) => {
            dispatch(getEmailEntriesUrlSuccess(url.toObject()));
        })
        .catch((err) => {
            dispatch(getEmailEntriesUrlFailure(err));
        });
}

export const createPoll = (file: File,
                           phdsAllowed: boolean,
                           year: number,
                           start: any,
                           end: any,
                           announcement: any,
): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(createPollStart());
    const state = getState();
    const token = selectAccessToken(state);
    const metadata = getAuthMetadata(token);

    let createPollRequest = new CreatePollRequest();

    createPollRequest.setPhdsCanVote(phdsAllowed);
    createPollRequest.setYear(year);
    createPollRequest.setStartTime(dateTimeStringsToTimestamp(start.date, start.time));
    createPollRequest.setEndTime(dateTimeStringsToTimestamp(end.date, end.time));
    createPollRequest.setAnnouncementTime(dateTimeStringsToTimestamp(announcement.date, announcement.time));

    return euleClient.createPoll(createPollRequest, metadata)
        .then((createdPoll) => {
            const url_request = new GetFileUploadUrlRequest();
            url_request.setFileName("attendance_data.csv");
            euleClient.getFileUploadUrl(url_request, metadata)
                .then((url) => {
                    console.log("Received url: " + url)
                    // Upload file using HTTP PUT request using url we received earlier
                    fetch(url.getFileUrl(), {method: "PUT", body: file})
                        .then((answer) => {
                            console.log("Request for file " + file.name + " answered with " + answer.status);
                            dispatch(createPollSuccess(createdPoll.toObject()));
                        })
                        .catch((err) => {
                            dispatch(createPollFailure(err));
                        });
                })
                .catch((err) => {
                    dispatch(createPollFailure(err));
                });
        })
        .catch((err) => {
            dispatch(createPollFailure(err));
        });
}

export const updatePoll = (pollId: number,
                           phdsAllowed: boolean,
                           year: number,
                           start: any,
                           end: any,
                           announcement: any): AppThunk => async (dispatch, getState, euleClient) => {

    dispatch(updatePollStart());
    const state = getState();
    const token = selectAccessToken(state);
    const metadata = getAuthMetadata(token);

    let updatePollRequest = new UpdatePollRequest();

    updatePollRequest.setPollId(pollId);
    updatePollRequest.setYear(year);
    updatePollRequest.setPhdsCanVote(phdsAllowed);
    updatePollRequest.setStartTime(dateTimeStringsToTimestamp(start.date, start.time));
    updatePollRequest.setEndTime(dateTimeStringsToTimestamp(end.date, end.time));
    updatePollRequest.setAnnouncementTime(dateTimeStringsToTimestamp(announcement.date, announcement.time));

    return euleClient.updatePoll(updatePollRequest, metadata)
        .then((updatedPoll) => {
            dispatch(updatePollSuccess(updatedPoll.toObject()));
        })
        .catch((err) => {
            dispatch(updatePollFailure(err));
        });
}

export const updateJob = (job: Job, action: JobUpdateAction): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(updateJobStart());
    const state = getState();
    const token = selectAccessToken(state);
    let request = new UpdateJobRequest();
    request.setJobId(job.jobId);
    request.setAction(action);
    return euleClient.updateJob(request, getAuthMetadata(token))
        .then(() => {
            dispatch(updateJobSuccess());
        })
        .catch((err) => {
            dispatch(updateJobFailure((err)));
        });
}

export const closeEvaluation = (): AppThunk => async (dispatch, getState, euleClient) => {
    dispatch(closeEvaluationStart());
    const state = getState();
    const token = selectAccessToken(state);
    let request = new CloseEvaluationRequest();
    return euleClient.closeEvaluation(request, getAuthMetadata(token))
        .then(() => {
            dispatch(closeEvaluationSuccess());
        })
        .catch((err) => {
            dispatch(closeEvaluationFailure((err)));
        });
}

export {
    changeAdminView,
    setSelectedPoll,
    setSelectedJob,
    setSelectedWinner
}

// Selectors
type AdminSliceRoot = {admin: ReturnType<typeof admin.reducer>};

export const selectAdminView = (state: AdminSliceRoot) => state.admin.view;
export const selectPolls = (state: AdminSliceRoot) => state.admin.polls;
export const selectJobs = (state: AdminSliceRoot) => state.admin.jobs;
export const selectSelectedPoll = (state: AdminSliceRoot) => state.admin.selectedPoll;
export const selectSelectedJob = (state: AdminSliceRoot) => state.admin.selectedJob;
export const selectSelectedWinner = (state: AdminSliceRoot) => state.admin.selectedWinner;
export const selectUrlEmailEntries = (state: AdminSliceRoot) => state.admin.urlEmailEntries;
export const selectIsAdminStateLoading = (state: AdminSliceRoot) => state.admin.isLoading
export const selectAdminError = (state: AdminSliceRoot) => state.admin.error;
