import { combineReducers } from "@reduxjs/toolkit";

import auth from "../slices/authSlice";
import voting from "../slices/votingSlice"
import admin from "../slices/adminSlice";
import results from "../slices/resultsSlice";
import winners from "../slices/winnersSlice";

/**
 * Construct and export the root reducer used in this redux application
 */


const rootReducer = combineReducers({
  auth,
  voting,
  admin,
  results,
  winners,
});

export type RootState = ReturnType<typeof rootReducer>;
export  default rootReducer;
