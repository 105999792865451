import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18n_german from "./i18n_german";
import i18n_english from "./i18n_english";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: i18n_english,
  de: i18n_german,
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false; //otherwise we have to cast everything to strings
  }
} 

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    supportedLngs: ["de", "en"],
    fallbackLng: "en",
    debug: true,
    returnNull: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
