import {configureStore, Action, getDefaultMiddleware, ThunkAction} from "@reduxjs/toolkit";

import rootReducer, { RootState } from "./rootReducer";
import {EuleClient} from "../proto/EuleServiceClientPb";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    if (action.type === "auth/setAuthState") {
      // Return a transformed action to remove sensitive information
      return {
        ...action,
        // set the auth tokens to zero
        payload: {
          ...action.payload,
          tokens: null
        },
      };
    }

    return action;
  },
  stateTransformer: state => {
    // Transform the state to remove sensitive information
    return {
      ...state,
      auth: {
        ...state.auth,
        tokens: null,
      },
    };
  },
});


let proxyAddress : string;
if (window.location.hostname === "localhost" || window.location.host === "127.0.0.1"){
  proxyAddress = "http://127.0.0.1:8080";
} else {
  proxyAddress = "https://webapi." + window.location.hostname;
}
let euleClient = new EuleClient(proxyAddress, {}, {})

export const useEuleClient = () => {
  return euleClient;
};

let customizedMiddleware = getDefaultMiddleware({
  thunk: {
    extraArgument: euleClient,
  },
});

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
  enhancers: [sentryReduxEnhancer],
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void>  = ThunkAction<ReturnType, RootState, EuleClient, Action<string>>;
export default store;
