import React, { useEffect, useCallback } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMatomo} from "@jonkoops/matomo-tracker-react/es";

import {
    getDepartments, getResultDepartment,
    setCurrentDepartment, setCurrentLecturer, setCurrentPoll,
    selectCurrentLecturer, selectCurrentPollId, selectDepartments, selectResults,
    selectIsResultsStateLoading, selectResultsError,
} from "../../slices/resultsSlice";
import {fetchPolls, selectPolls, selectIsAdminStateLoading, selectAdminError} from "../../slices/adminSlice";
import { LecturerResultComponent } from "../../components/results/LecturerResultComponent";
import {Button, CustomInput, Form, Spinner} from "@vseth/components";
import {useTranslation} from "react-i18next";
import {PollStatus} from "../../proto/eule_pb";
import {AppDispatch} from "../../app/store";


export const ResultsPage = () => {
    const { t } = useTranslation();
    // track the page view
    const { trackPageView } = useMatomo();
    useEffect( () => {
        trackPageView({});
    }, [trackPageView]);

    const polls = useSelector(selectPolls);
    const departments = useSelector(selectDepartments);
    const currentPollId = useSelector(selectCurrentPollId);
    const results = useSelector(selectResults);
    const currentLecturer = useSelector(selectCurrentLecturer);
    const resultsLoading = useSelector(selectIsResultsStateLoading);
    const pollsLoading = useSelector(selectIsAdminStateLoading);
    const resultsError = useSelector(selectResultsError);
    const adminError = useSelector(selectAdminError);

    const dispatch:AppDispatch = useDispatch();

    const handleChange = useCallback((): void => {
        const _dept = (document.getElementById("department") as HTMLInputElement).value;
        const _poll = parseInt((document.getElementById("poll") as HTMLInputElement).value);
        dispatch(setCurrentDepartment(_dept));
        dispatch(setCurrentPoll(_poll));
        dispatch(getResultDepartment(_dept, _poll as number));
    }, [dispatch]);

    useEffect(() => {
        if (polls === undefined && adminError === undefined) {
            dispatch(fetchPolls());
        }
        if (departments === undefined && polls !== undefined && resultsError === undefined) {
            dispatch(getDepartments());
        }
        if (results === undefined && polls !== undefined && departments !== undefined && resultsError === undefined) {
            handleChange();
        }
    }, [dispatch, polls, departments, results, resultsError, adminError, handleChange]);

    return (
        <div className="mt-5 container">
            <h1>{t("resultsPage.title")}</h1>
            <Form style={{textAlign: "left"}} onChange={ () => {handleChange()}}>
                {t("resultsPage.year")}
                <CustomInput type="select" name="poll" id="poll">
                    {
                        polls?.filter(value => value.status >= PollStatus.EVALUATION_OPEN)
                            .sort(function(a: any, b: any){return b.year - a.year;})
                            .map((value,index) => {
                                return <option value={value.pollId}>{value.year}</option>
                            })
                    }
                </CustomInput>

                {t("resultsPage.department")}
                <CustomInput type="select" name="department" id="department">
                    {departments?.map((value,index) => {
                        return <option value={value}>{value}</option>
                    })}
                </CustomInput>
            </Form>
            <div className="row mt-5">
                {adminError && (<div>
                    <h2>Error</h2>
                    <p>{adminError.message}</p>
                </div>)}
                {resultsError && (<div>
                    <h2>Error</h2>
                    <p>{resultsError.message}</p>
                </div>)}

                {(resultsLoading || pollsLoading) && <Spinner color="primary"/>}
                {!resultsError && !adminError && (results ? (
                    <>
                        <div className="col-md-3">
                            <ul style={{ paddingLeft: 0 }}>
                                {results ? results.lecturerResult.map((lecturer: any) => (
                                    <li
                                        key={lecturer.id}
                                        className="text-truncate py-1"
                                        style={{listStyle: "none"}}
                                    >
                                        <Button onClick={() => {dispatch(setCurrentLecturer(lecturer));}}>
                                            {`${lecturer.firstName} ${lecturer.lastName}`}
                                        </Button>
                                    </li>
                                )) : (<p>{t("resultsPage.nothingToShow")}</p>)}
                            </ul>
                        </div>
                        <div className="col-md-9">
                            {
                                currentLecturer ? (<LecturerResultComponent lecturerInfo={currentLecturer}/>) : (<p>{t("resultsPage.nothingToShow")}</p>)
                            }
                        </div>
                    </>
                ) : (<p>{t("resultsPage.nothingToShow")}{t("resultsPage.forPoll")} {currentPollId}</p>))}
            </div>
        </div>
    );
}
