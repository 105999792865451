    const i18n_english = {
    translation: {
        lang: "en",
        app: {
            name: "Golden Owl",
        },
        general: {
            home: "home",
            pleaseLogIn: "Please log in to use this app",
        },
        landingPage: {
            voteNow: "Vote now",
            title: "Golden Owl",
            titleExplanation: "What is the Golden Owl?",
            textExplanation: "Click here to vote!",
            titleEule: "What is the Golden Owl?",
            textEule: "The Golden Owl honours excellent teachers. The Owl is awarded by VSETH, ETH Zurich’s student association. All ETH members with a teaching assignment can be nominated for the Golden Owl. One lecturer per department is selected for this honour. Opening mid-May, an anonymous online survey created by VSETH is sent to all students asking them to rate the teaching of their lecturers in the past two semesters. The results are given to the respective student associations. They look at a further list of criteria to choose the winner of their department. The winners receive their award from the president of VSETH in November during the ETH Day ceremony.",
            titleCS: "What is the CS Award?",
            textCS: "The Credit Suisse Award for Best Teaching rewards particularly innovative and dedicated teaching. Every lecturer who has won a Golden Owl or who will win one in the same year is eligible. The CS Award is granted to one lecturer every year. Each student association can nominate someone from their department. Then, the VSETH board selects the winner according to several criteria. The award is given to the lecturer during the ETH Day ceremony.",
            moreInfoTitle: "Further information",
            moreInfoText: "",
            picturesLink: "Ceremony Pictures",
            ethLink: "ETH page about the Golden Owl",
            ethURL: "https://www.ethz.ch/en/the-eth-zurich/education/awards/golden-owl.html",
            pictureURL: "https://www.skyfish.com/p/eth-tag/1369795",
        },
        votingPage: {
            title: "Voting",
            generalExplanation: "Click on a card to rate someone, and click on “Submit” when you have rated all lecturers you want to rate. Your submission is final and cannot be undone. Your votes are stored anonymously.",
            status: {
                inProgress: "In progress",
            },
            actions: {
                submitVoting: "Submit",
            },
            confirmText: "You haven’t rated every lecturer yet. Are you sure you want to submit? You won’t be able to vote again after submitting.",
        },
        singleVote: {
            generalExplanation: "Rate this lecturer:",
            commentExplanation: "Please fill in the comment box! Comments really help to select the winner.",

            comment: "Comment",
            helpText: "Please refrain from leaving comments which insult the person or impinge on the honour of the lecturer.",
            actions: {
                backToOverview: "Back to overview",
            },
        },
        formerWinners: {
            title: "Former Owls",
            thankYou: "These are all the lecturers who were awarded the Golden Owl for their extraordinary teaching. We are very grateful for their commitment and enthusiasm for teaching.",
            winners: "Winners",
        },
        countdown: {
            starts_day: "The voting starts in one day.",
            starts_day_plural: "The voting starts in {{days}} days.",
            starts_hour: "The voting starts in {{hours}}:{{minutes}}:{{seconds}}",
            ends_day: "The voting ends in one day.",
            ends_day_plural: "The voting ends in {{days}} days.",
            ends_hour: "The voting ends in {{hours}}:{{minutes}}:{{seconds}}",
        },
        waitForResult: {
            title: "The voting is over",
            text: "We will announce the results at ETH Day in November",
        },
        successVoting: {
            title: "Thank you for voting",
            text: "We will announce the results at ETH Day in November",
        },
        notEligible: {
            title: "There is nothing to vote for",
            text: "This might be because you haven't registered for any lectures in the past two semesters.",
            text2: "If you think this is a mistake, please contact ",
        },
        getNotified: {
            text: "Would you like to receive an email when the winners are announced?",
            button: "Receive E-Mail",
        },
        successfullySubscribed: {
            title: "You will be notified",
            text: "An email will be sent to you after the winners are announced",
            button: "Back to home",
        },
        footer: {
            privacy: "Privacy Policy",
            impressum: "Imprint",
        },

        adminPage: {
            goBack: "Go back",
            submit: "Submit",

            generalParameters: "General parameters",
            year: "Year",
            yearRequirements: "Requirements: must be at least this year",
            phdsAllowed: "PhDs students are allowed to vote",
            startDateTimeTitle: "Start date and time",
            startDateTimeRequirements: "Requirements: Must be at least this year",
            endDateTimeTitle: "End date and time",
            endDateTimeRequirements: "Requirements: Must be after the start date and time",
            announcementDateTimeTitle: "Winners announcement date and time",
            announcementDateTimeRequirements: "Requirements: should be after the ETH day ceremony, you must check the date yourself.",
            date: "Date",
            time: "Time",

            warnings: {
                year: "Year entered must be at least ",
            },

            createPoll: {
                title: "Create a new poll",
                expectedFileFormat: {
                    title: "Expected file format",
                    description: "The file must be a .csv file encoded in UTF-8. All columns listed below must be present and described in a header at the top of the file. " +
                        "If the header is missing, you must add it yourself and/or complain to ETH. Any other column than described will simply be ignored. " +
                        "As the file is uploaded to a cloud storage instance, it is advised to leave as little additional information as possible for privacy reasons.",
                },
                pollCreatedMessage: "Poll created! The file upload may take a few minutes, please be patient. Do not close your browser to be safe.",
                headerFormat: {
                    semester: "shorthand code of semester (e.g. \"FS 2064\")",
                    departmentLecturer: "shorthand code of department of lecturer (e.g. \"D-INFK\")",
                    dozide: "lecturer id used by ETH (e.g. 1000127), must be a number",
                    academicTitleLecturer: "academic title of lecturer (e.g. \"Prof. Dr.\" or \"Dr.\")",
                    surnameLecturer: "surname of lecturer (e.g. \"Doe\")",
                    nameLecturer: "name of lecturer (e.g. \"John\")",
                    codeLecture: "ETH lecture code (e.g. \"123-1230-1234\")",
                    typeLecture: "ETH lecture type: [\"V\", \"G\", \"S\", \"K\", \"P\"], others are ignored",
                    titleLecture: "title of lecture (e.g. \"Owl Flight Mechanics\")",
                    nethz: "ETH username of student (e.g. \"owlg\")",
                    semestersStudent: "number of semesters (e.g. 7) spent as",
                    departmentStudent: "shorthand code of department of student (e.g. \"D-MATH\")",
                    statusStudent: "status of student within ETH (e.g. 1 for Bachelor, 2 for Master, etc.), must be a number",
                    statusStudentDesc: "description of HOERER_STATUS (e.g. \"Studierender\" if HOERER_STATUS is 1), this column is not needed but helpful to see if the HOERER_STATUS makes sense",
                },
                attendanceData: "Attendance data CSV",
                attendanceDataRequirements: "Requirements: see \"Expected file format\"",
            },

        },

        resultsPage: {
            title: "Results",
            department: "Department",
            year: "Year",
            nothingToShow: "Nothing to show",
            forPoll: " for poll",
            currentlySelectedWinner: "Currently selected",
            noWinnerSelected: " nobody",
            selectForEule: "Select for Eule",
            totalVotes: "Total votes",
            averageGrade: "Average grade",
            ranking: "Ranking in",
            rankingETH: "Ranking at ETH",
            byStatus: "By status",
            byDepartment: "By department",
            wins: "Wins",
            noWins: "No wins",
            lectures: "Lectures",

            votes: "Votes",
            comments: "Comments",
            noComments: "No comments",
            averageByDept: "Avg. by department",
            averageByStatus: "Avg. by status",
            votesByDept: "Nbr. votes by department",
            votesByStatus: "Nbr. votes by status",
        },
    },
};

export default i18n_english;
