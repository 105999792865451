import React from "react";
import {InputField, Form, Button} from "@vseth/components";
import {useDispatch} from "react-redux";
import {changeAdminView, createPoll, fetchPolls} from "../../slices/adminSlice";
import {useTranslation} from "react-i18next";
import {addDays, format} from "date-fns";
import {AppDispatch} from "../../app/store";

// TODO: Add translation

export const CreatePollPage = () => {
    const dispatch:AppDispatch = useDispatch();
    const { t } = useTranslation();

    const handleSubmit = (e: any): void => {
        e.preventDefault();
        const filesHtml = document.getElementById("attendance_data") as HTMLInputElement;
        if (filesHtml != null) {
            const filesList = filesHtml.files;
            if (filesList != null) {
                const file = filesList[0];

                const phdsAllowed =  (document.getElementById("phds_allowed") as HTMLInputElement).checked;
                const year = parseInt((document.getElementById("year") as HTMLInputElement).value);

                const start = {
                    date: (document.getElementById("start_date") as HTMLInputElement).value,
                    time: (document.getElementById("start_time") as HTMLInputElement).value,
                }
                const end = {
                    date: (document.getElementById("end_date") as HTMLInputElement).value,
                    time: (document.getElementById("end_time") as HTMLInputElement).value,
                }

                const announcement = {
                    date: (document.getElementById("announcement_date") as HTMLInputElement).value,
                    time: (document.getElementById("announcement_time") as HTMLInputElement).value,
                }
                dispatch(createPoll(file, phdsAllowed, year, start, end, announcement));
            }
        }
        window.alert(t("adminPage.createPoll.pollCreatedMessage"));
        dispatch(fetchPolls());
        dispatch(changeAdminView("main"));
    }

    const tomorrow = format(addDays(new Date(),1), 'yyyy-MM-dd')
    const dayaftertomorrow = format(addDays(new Date(),2), 'yyyy-MM-dd')

    return (
        <div className="mt-5 container">
            <h2>{t("adminPage.createPoll.title")}</h2>
            <Button onClick={() => { dispatch(changeAdminView("main"));} } style={{justifyContent:'center', margin: 3}}>
                {t("adminPage.goBack")}
            </Button>
            <h5>{t("adminPage.createPoll.expectedFileFormat.title")}</h5>
            <p>
                {t("adminPage.createPoll.expectedFileFormat.description")} <br/><br/>
                <code>DOZ_DEPT</code>: {t("adminPage.createPoll.headerFormat.departmentLecturer")}<br/>
                <code>DOZIDE</code>: {t("adminPage.createPoll.headerFormat.dozide")}<br/>
                <code>DOZ_TITEL</code>: {t("adminPage.createPoll.headerFormat.academicTitleLecturer")}<br/>
                <code>DOZ_RUFNAME</code>: {t("adminPage.createPoll.headerFormat.surnameLecturer")}<br/>
                <code>DOZ_NAME</code>: {t("adminPage.createPoll.headerFormat.nameLecturer")}<br/>
                <code>SEM_KURZBEZ</code>: {t("adminPage.createPoll.headerFormat.semester")}<br/>
                <code>VERANSTALTUNGSNR</code>: {t("adminPage.createPoll.headerFormat.codeLecture")}<br/>
                <code>LV_TYP</code>: {t("adminPage.createPoll.headerFormat.typeLecture")}<br/>
                <code>LV_TITEL</code>: {t("adminPage.createPoll.headerFormat.titleLecture")}<br/>
                <code>STUD_NETHZ</code>: {t("adminPage.createPoll.headerFormat.nethz")}<br/>
                <code>HOERER_STATUS</code>: {t("adminPage.createPoll.headerFormat.statusStudent")}<br/>
                <code>HOERER_STATUS_BESCHREIBUNG</code>: {t("adminPage.createPoll.headerFormat.statusStudentDesc")}<br/>
                <code>NSP_SEMESTER</code>:  {t("adminPage.createPoll.headerFormat.semestersStudent")} <code>HOERER_STATUS</code> <br/>
                <code>STUD_DEPT</code>: {t("adminPage.createPoll.headerFormat.departmentStudent")}<br/>
            </p>

            <h5>Import</h5>
            <Form style={{textAlign: "left"}} onSubmit={ (e) => {handleSubmit(e)} }>
                <InputField type="number" id="year" label="Year (must be at least this year)" defaultValue={tomorrow.substr(0,4)}/>
                <InputField type="checkbox" id="phds_allowed" label="PhDs are allowed to vote" defaultChecked/>
                <InputField type="date" id="start_date" label="Start date (must be at least this year)" defaultValue={tomorrow}/>
                <InputField type="time" id="start_time" label="Start time" defaultValue={"00:00"}/>
                <InputField type="date" id="end_date" label="End date (must be after the start date)" defaultValue={tomorrow}/>
                <InputField type="time" id="end_time" label="End time (must be after the start date)" defaultValue={"23:59"}/>
                <InputField type="date" id="announcement_date" label="Announcement date (at least the next ETH Day)" defaultValue={dayaftertomorrow}/>
                <InputField type="time" id="announcement_time" label="Announcement time (make sure it is after the ceremony)" defaultValue={"18:00"}/>
                <InputField type="File" id="attendance_data" accept=".csv" label="Attendance data CSV (see requirements above)"/>

                <Button color="primary" type="submit">
                    {t("adminPage.submit")}
                </Button>
                <Button onClick={() => { dispatch(changeAdminView("main"));} } style={{justifyContent:'center', margin: 3}}>
                    {t("adminPage.goBack")}
                </Button>
            </Form>

            <br/>

        </div>
    )
}
