/* eslint-disable */ 
/**
 * @fileoverview gRPC-Web generated client stub for vseth.sip.eule
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: eule.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as eule_pb from './eule_pb';


export class EuleClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetJob = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/GetJob',
    grpcWeb.MethodType.UNARY,
    eule_pb.GetJobRequest,
    eule_pb.Job,
    (request: eule_pb.GetJobRequest) => {
      return request.serializeBinary();
    },
    eule_pb.Job.deserializeBinary
  );

  getJob(
    request: eule_pb.GetJobRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.Job>;

  getJob(
    request: eule_pb.GetJobRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.Job) => void): grpcWeb.ClientReadableStream<eule_pb.Job>;

  getJob(
    request: eule_pb.GetJobRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/GetJob',
        request,
        metadata || {},
        this.methodDescriptorGetJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/GetJob',
    request,
    metadata || {},
    this.methodDescriptorGetJob);
  }

  methodDescriptorListJobs = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/ListJobs',
    grpcWeb.MethodType.UNARY,
    eule_pb.ListJobsRequest,
    eule_pb.ListJobsResponse,
    (request: eule_pb.ListJobsRequest) => {
      return request.serializeBinary();
    },
    eule_pb.ListJobsResponse.deserializeBinary
  );

  listJobs(
    request: eule_pb.ListJobsRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.ListJobsResponse>;

  listJobs(
    request: eule_pb.ListJobsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.ListJobsResponse) => void): grpcWeb.ClientReadableStream<eule_pb.ListJobsResponse>;

  listJobs(
    request: eule_pb.ListJobsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.ListJobsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/ListJobs',
        request,
        metadata || {},
        this.methodDescriptorListJobs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/ListJobs',
    request,
    metadata || {},
    this.methodDescriptorListJobs);
  }

  methodDescriptorUpdateJob = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/UpdateJob',
    grpcWeb.MethodType.UNARY,
    eule_pb.UpdateJobRequest,
    eule_pb.Job,
    (request: eule_pb.UpdateJobRequest) => {
      return request.serializeBinary();
    },
    eule_pb.Job.deserializeBinary
  );

  updateJob(
    request: eule_pb.UpdateJobRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.Job>;

  updateJob(
    request: eule_pb.UpdateJobRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.Job) => void): grpcWeb.ClientReadableStream<eule_pb.Job>;

  updateJob(
    request: eule_pb.UpdateJobRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/UpdateJob',
        request,
        metadata || {},
        this.methodDescriptorUpdateJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/UpdateJob',
    request,
    metadata || {},
    this.methodDescriptorUpdateJob);
  }

  methodDescriptorGetLecturer = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/GetLecturer',
    grpcWeb.MethodType.UNARY,
    eule_pb.GetLecturerRequest,
    eule_pb.Lecturer,
    (request: eule_pb.GetLecturerRequest) => {
      return request.serializeBinary();
    },
    eule_pb.Lecturer.deserializeBinary
  );

  getLecturer(
    request: eule_pb.GetLecturerRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.Lecturer>;

  getLecturer(
    request: eule_pb.GetLecturerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.Lecturer) => void): grpcWeb.ClientReadableStream<eule_pb.Lecturer>;

  getLecturer(
    request: eule_pb.GetLecturerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.Lecturer) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/GetLecturer',
        request,
        metadata || {},
        this.methodDescriptorGetLecturer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/GetLecturer',
    request,
    metadata || {},
    this.methodDescriptorGetLecturer);
  }

  methodDescriptorListLecturers = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/ListLecturers',
    grpcWeb.MethodType.UNARY,
    eule_pb.ListLecturersRequest,
    eule_pb.ListLecturersResponse,
    (request: eule_pb.ListLecturersRequest) => {
      return request.serializeBinary();
    },
    eule_pb.ListLecturersResponse.deserializeBinary
  );

  listLecturers(
    request: eule_pb.ListLecturersRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.ListLecturersResponse>;

  listLecturers(
    request: eule_pb.ListLecturersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.ListLecturersResponse) => void): grpcWeb.ClientReadableStream<eule_pb.ListLecturersResponse>;

  listLecturers(
    request: eule_pb.ListLecturersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.ListLecturersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/ListLecturers',
        request,
        metadata || {},
        this.methodDescriptorListLecturers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/ListLecturers',
    request,
    metadata || {},
    this.methodDescriptorListLecturers);
  }

  methodDescriptorGetPoll = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/GetPoll',
    grpcWeb.MethodType.UNARY,
    eule_pb.GetPollRequest,
    eule_pb.Poll,
    (request: eule_pb.GetPollRequest) => {
      return request.serializeBinary();
    },
    eule_pb.Poll.deserializeBinary
  );

  getPoll(
    request: eule_pb.GetPollRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.Poll>;

  getPoll(
    request: eule_pb.GetPollRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.Poll) => void): grpcWeb.ClientReadableStream<eule_pb.Poll>;

  getPoll(
    request: eule_pb.GetPollRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.Poll) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/GetPoll',
        request,
        metadata || {},
        this.methodDescriptorGetPoll,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/GetPoll',
    request,
    metadata || {},
    this.methodDescriptorGetPoll);
  }

  methodDescriptorListPolls = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/ListPolls',
    grpcWeb.MethodType.UNARY,
    eule_pb.ListPollsRequest,
    eule_pb.ListPollsResponse,
    (request: eule_pb.ListPollsRequest) => {
      return request.serializeBinary();
    },
    eule_pb.ListPollsResponse.deserializeBinary
  );

  listPolls(
    request: eule_pb.ListPollsRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.ListPollsResponse>;

  listPolls(
    request: eule_pb.ListPollsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.ListPollsResponse) => void): grpcWeb.ClientReadableStream<eule_pb.ListPollsResponse>;

  listPolls(
    request: eule_pb.ListPollsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.ListPollsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/ListPolls',
        request,
        metadata || {},
        this.methodDescriptorListPolls,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/ListPolls',
    request,
    metadata || {},
    this.methodDescriptorListPolls);
  }

  methodDescriptorCreatePoll = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/CreatePoll',
    grpcWeb.MethodType.UNARY,
    eule_pb.CreatePollRequest,
    eule_pb.Poll,
    (request: eule_pb.CreatePollRequest) => {
      return request.serializeBinary();
    },
    eule_pb.Poll.deserializeBinary
  );

  createPoll(
    request: eule_pb.CreatePollRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.Poll>;

  createPoll(
    request: eule_pb.CreatePollRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.Poll) => void): grpcWeb.ClientReadableStream<eule_pb.Poll>;

  createPoll(
    request: eule_pb.CreatePollRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.Poll) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/CreatePoll',
        request,
        metadata || {},
        this.methodDescriptorCreatePoll,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/CreatePoll',
    request,
    metadata || {},
    this.methodDescriptorCreatePoll);
  }

  methodDescriptorUpdatePoll = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/UpdatePoll',
    grpcWeb.MethodType.UNARY,
    eule_pb.UpdatePollRequest,
    eule_pb.Poll,
    (request: eule_pb.UpdatePollRequest) => {
      return request.serializeBinary();
    },
    eule_pb.Poll.deserializeBinary
  );

  updatePoll(
    request: eule_pb.UpdatePollRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.Poll>;

  updatePoll(
    request: eule_pb.UpdatePollRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.Poll) => void): grpcWeb.ClientReadableStream<eule_pb.Poll>;

  updatePoll(
    request: eule_pb.UpdatePollRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.Poll) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/UpdatePoll',
        request,
        metadata || {},
        this.methodDescriptorUpdatePoll,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/UpdatePoll',
    request,
    metadata || {},
    this.methodDescriptorUpdatePoll);
  }

  methodDescriptorCloseEvaluation = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/CloseEvaluation',
    grpcWeb.MethodType.UNARY,
    eule_pb.CloseEvaluationRequest,
    eule_pb.Job,
    (request: eule_pb.CloseEvaluationRequest) => {
      return request.serializeBinary();
    },
    eule_pb.Job.deserializeBinary
  );

  closeEvaluation(
    request: eule_pb.CloseEvaluationRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.Job>;

  closeEvaluation(
    request: eule_pb.CloseEvaluationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.Job) => void): grpcWeb.ClientReadableStream<eule_pb.Job>;

  closeEvaluation(
    request: eule_pb.CloseEvaluationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.Job) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/CloseEvaluation',
        request,
        metadata || {},
        this.methodDescriptorCloseEvaluation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/CloseEvaluation',
    request,
    metadata || {},
    this.methodDescriptorCloseEvaluation);
  }

  methodDescriptorGetFileUploadUrl = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/GetFileUploadUrl',
    grpcWeb.MethodType.UNARY,
    eule_pb.GetFileUploadUrlRequest,
    eule_pb.FileUrl,
    (request: eule_pb.GetFileUploadUrlRequest) => {
      return request.serializeBinary();
    },
    eule_pb.FileUrl.deserializeBinary
  );

  getFileUploadUrl(
    request: eule_pb.GetFileUploadUrlRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.FileUrl>;

  getFileUploadUrl(
    request: eule_pb.GetFileUploadUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.FileUrl) => void): grpcWeb.ClientReadableStream<eule_pb.FileUrl>;

  getFileUploadUrl(
    request: eule_pb.GetFileUploadUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.FileUrl) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/GetFileUploadUrl',
        request,
        metadata || {},
        this.methodDescriptorGetFileUploadUrl,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/GetFileUploadUrl',
    request,
    metadata || {},
    this.methodDescriptorGetFileUploadUrl);
  }

  methodDescriptorGetFileDownloadUrl = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/GetFileDownloadUrl',
    grpcWeb.MethodType.UNARY,
    eule_pb.GetFileDownloadUrlRequest,
    eule_pb.FileUrl,
    (request: eule_pb.GetFileDownloadUrlRequest) => {
      return request.serializeBinary();
    },
    eule_pb.FileUrl.deserializeBinary
  );

  getFileDownloadUrl(
    request: eule_pb.GetFileDownloadUrlRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.FileUrl>;

  getFileDownloadUrl(
    request: eule_pb.GetFileDownloadUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.FileUrl) => void): grpcWeb.ClientReadableStream<eule_pb.FileUrl>;

  getFileDownloadUrl(
    request: eule_pb.GetFileDownloadUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.FileUrl) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/GetFileDownloadUrl',
        request,
        metadata || {},
        this.methodDescriptorGetFileDownloadUrl,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/GetFileDownloadUrl',
    request,
    metadata || {},
    this.methodDescriptorGetFileDownloadUrl);
  }

  methodDescriptorGetVotingData = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/GetVotingData',
    grpcWeb.MethodType.UNARY,
    eule_pb.GetVotingDataRequest,
    eule_pb.VotingData,
    (request: eule_pb.GetVotingDataRequest) => {
      return request.serializeBinary();
    },
    eule_pb.VotingData.deserializeBinary
  );

  getVotingData(
    request: eule_pb.GetVotingDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.VotingData>;

  getVotingData(
    request: eule_pb.GetVotingDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.VotingData) => void): grpcWeb.ClientReadableStream<eule_pb.VotingData>;

  getVotingData(
    request: eule_pb.GetVotingDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.VotingData) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/GetVotingData',
        request,
        metadata || {},
        this.methodDescriptorGetVotingData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/GetVotingData',
    request,
    metadata || {},
    this.methodDescriptorGetVotingData);
  }

  methodDescriptorCreateVotes = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/CreateVotes',
    grpcWeb.MethodType.UNARY,
    eule_pb.CreateVotesRequest,
    eule_pb.CreatedVotes,
    (request: eule_pb.CreateVotesRequest) => {
      return request.serializeBinary();
    },
    eule_pb.CreatedVotes.deserializeBinary
  );

  createVotes(
    request: eule_pb.CreateVotesRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.CreatedVotes>;

  createVotes(
    request: eule_pb.CreateVotesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.CreatedVotes) => void): grpcWeb.ClientReadableStream<eule_pb.CreatedVotes>;

  createVotes(
    request: eule_pb.CreateVotesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.CreatedVotes) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/CreateVotes',
        request,
        metadata || {},
        this.methodDescriptorCreateVotes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/CreateVotes',
    request,
    metadata || {},
    this.methodDescriptorCreateVotes);
  }

  methodDescriptorCreateNotificationMailEntry = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/CreateNotificationMailEntry',
    grpcWeb.MethodType.UNARY,
    eule_pb.CreateNotificationMailEntryRequest,
    eule_pb.NotificationMailEntry,
    (request: eule_pb.CreateNotificationMailEntryRequest) => {
      return request.serializeBinary();
    },
    eule_pb.NotificationMailEntry.deserializeBinary
  );

  createNotificationMailEntry(
    request: eule_pb.CreateNotificationMailEntryRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.NotificationMailEntry>;

  createNotificationMailEntry(
    request: eule_pb.CreateNotificationMailEntryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.NotificationMailEntry) => void): grpcWeb.ClientReadableStream<eule_pb.NotificationMailEntry>;

  createNotificationMailEntry(
    request: eule_pb.CreateNotificationMailEntryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.NotificationMailEntry) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/CreateNotificationMailEntry',
        request,
        metadata || {},
        this.methodDescriptorCreateNotificationMailEntry,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/CreateNotificationMailEntry',
    request,
    metadata || {},
    this.methodDescriptorCreateNotificationMailEntry);
  }

  methodDescriptorGetNotificationMailEntries = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/GetNotificationMailEntries',
    grpcWeb.MethodType.UNARY,
    eule_pb.GetNotificationMailEntriesRequest,
    eule_pb.FileUrl,
    (request: eule_pb.GetNotificationMailEntriesRequest) => {
      return request.serializeBinary();
    },
    eule_pb.FileUrl.deserializeBinary
  );

  getNotificationMailEntries(
    request: eule_pb.GetNotificationMailEntriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.FileUrl>;

  getNotificationMailEntries(
    request: eule_pb.GetNotificationMailEntriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.FileUrl) => void): grpcWeb.ClientReadableStream<eule_pb.FileUrl>;

  getNotificationMailEntries(
    request: eule_pb.GetNotificationMailEntriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.FileUrl) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/GetNotificationMailEntries',
        request,
        metadata || {},
        this.methodDescriptorGetNotificationMailEntries,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/GetNotificationMailEntries',
    request,
    metadata || {},
    this.methodDescriptorGetNotificationMailEntries);
  }

  methodDescriptorListWinners = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/ListWinners',
    grpcWeb.MethodType.UNARY,
    eule_pb.ListWinnersRequest,
    eule_pb.ListWinnersResponse,
    (request: eule_pb.ListWinnersRequest) => {
      return request.serializeBinary();
    },
    eule_pb.ListWinnersResponse.deserializeBinary
  );

  listWinners(
    request: eule_pb.ListWinnersRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.ListWinnersResponse>;

  listWinners(
    request: eule_pb.ListWinnersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.ListWinnersResponse) => void): grpcWeb.ClientReadableStream<eule_pb.ListWinnersResponse>;

  listWinners(
    request: eule_pb.ListWinnersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.ListWinnersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/ListWinners',
        request,
        metadata || {},
        this.methodDescriptorListWinners,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/ListWinners',
    request,
    metadata || {},
    this.methodDescriptorListWinners);
  }

  methodDescriptorCreateWinners = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/CreateWinners',
    grpcWeb.MethodType.UNARY,
    eule_pb.CreateWinnersRequest,
    eule_pb.ListWinnersResponse,
    (request: eule_pb.CreateWinnersRequest) => {
      return request.serializeBinary();
    },
    eule_pb.ListWinnersResponse.deserializeBinary
  );

  createWinners(
    request: eule_pb.CreateWinnersRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.ListWinnersResponse>;

  createWinners(
    request: eule_pb.CreateWinnersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.ListWinnersResponse) => void): grpcWeb.ClientReadableStream<eule_pb.ListWinnersResponse>;

  createWinners(
    request: eule_pb.CreateWinnersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.ListWinnersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/CreateWinners',
        request,
        metadata || {},
        this.methodDescriptorCreateWinners,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/CreateWinners',
    request,
    metadata || {},
    this.methodDescriptorCreateWinners);
  }

  methodDescriptorUpdateWinner = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/UpdateWinner',
    grpcWeb.MethodType.UNARY,
    eule_pb.UpdateWinnerRequest,
    eule_pb.Winner,
    (request: eule_pb.UpdateWinnerRequest) => {
      return request.serializeBinary();
    },
    eule_pb.Winner.deserializeBinary
  );

  updateWinner(
    request: eule_pb.UpdateWinnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.Winner>;

  updateWinner(
    request: eule_pb.UpdateWinnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.Winner) => void): grpcWeb.ClientReadableStream<eule_pb.Winner>;

  updateWinner(
    request: eule_pb.UpdateWinnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.Winner) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/UpdateWinner',
        request,
        metadata || {},
        this.methodDescriptorUpdateWinner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/UpdateWinner',
    request,
    metadata || {},
    this.methodDescriptorUpdateWinner);
  }

  methodDescriptorDeleteWinner = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/DeleteWinner',
    grpcWeb.MethodType.UNARY,
    eule_pb.DeleteWinnerRequest,
    google_protobuf_empty_pb.Empty,
    (request: eule_pb.DeleteWinnerRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteWinner(
    request: eule_pb.DeleteWinnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteWinner(
    request: eule_pb.DeleteWinnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteWinner(
    request: eule_pb.DeleteWinnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/DeleteWinner',
        request,
        metadata || {},
        this.methodDescriptorDeleteWinner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/DeleteWinner',
    request,
    metadata || {},
    this.methodDescriptorDeleteWinner);
  }

  methodDescriptorCreateTemporaryWinner = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/CreateTemporaryWinner',
    grpcWeb.MethodType.UNARY,
    eule_pb.CreateTemporaryWinnerRequest,
    eule_pb.Winner,
    (request: eule_pb.CreateTemporaryWinnerRequest) => {
      return request.serializeBinary();
    },
    eule_pb.Winner.deserializeBinary
  );

  createTemporaryWinner(
    request: eule_pb.CreateTemporaryWinnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.Winner>;

  createTemporaryWinner(
    request: eule_pb.CreateTemporaryWinnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.Winner) => void): grpcWeb.ClientReadableStream<eule_pb.Winner>;

  createTemporaryWinner(
    request: eule_pb.CreateTemporaryWinnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.Winner) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/CreateTemporaryWinner',
        request,
        metadata || {},
        this.methodDescriptorCreateTemporaryWinner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/CreateTemporaryWinner',
    request,
    metadata || {},
    this.methodDescriptorCreateTemporaryWinner);
  }

  methodDescriptorListDepartments = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/ListDepartments',
    grpcWeb.MethodType.UNARY,
    eule_pb.ListDepartmentsRequest,
    eule_pb.ListDepartmentsResponse,
    (request: eule_pb.ListDepartmentsRequest) => {
      return request.serializeBinary();
    },
    eule_pb.ListDepartmentsResponse.deserializeBinary
  );

  listDepartments(
    request: eule_pb.ListDepartmentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.ListDepartmentsResponse>;

  listDepartments(
    request: eule_pb.ListDepartmentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.ListDepartmentsResponse) => void): grpcWeb.ClientReadableStream<eule_pb.ListDepartmentsResponse>;

  listDepartments(
    request: eule_pb.ListDepartmentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.ListDepartmentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/ListDepartments',
        request,
        metadata || {},
        this.methodDescriptorListDepartments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/ListDepartments',
    request,
    metadata || {},
    this.methodDescriptorListDepartments);
  }

  methodDescriptorGetResultsDepartment = new grpcWeb.MethodDescriptor(
    '/vseth.sip.eule.Eule/GetResultsDepartment',
    grpcWeb.MethodType.UNARY,
    eule_pb.GetResultsDepartmentRequest,
    eule_pb.ResultsDepartment,
    (request: eule_pb.GetResultsDepartmentRequest) => {
      return request.serializeBinary();
    },
    eule_pb.ResultsDepartment.deserializeBinary
  );

  getResultsDepartment(
    request: eule_pb.GetResultsDepartmentRequest,
    metadata: grpcWeb.Metadata | null): Promise<eule_pb.ResultsDepartment>;

  getResultsDepartment(
    request: eule_pb.GetResultsDepartmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: eule_pb.ResultsDepartment) => void): grpcWeb.ClientReadableStream<eule_pb.ResultsDepartment>;

  getResultsDepartment(
    request: eule_pb.GetResultsDepartmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: eule_pb.ResultsDepartment) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/vseth.sip.eule.Eule/GetResultsDepartment',
        request,
        metadata || {},
        this.methodDescriptorGetResultsDepartment,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/vseth.sip.eule.Eule/GetResultsDepartment',
    request,
    metadata || {},
    this.methodDescriptorGetResultsDepartment);
  }

}

