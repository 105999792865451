import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

import {Button} from "@vseth/components";
import {LectureResultComponent} from "./LectureResultComponent";
import {
    selectCurrentDepartment,
    selectCurrentLecture,
    selectCurrentPollId,
    selectResults,
    selectSelectedEuleWinner,
    setCurrentLecture,
    submitTemporaryWinner
} from "../../slices/resultsSlice";
import {AwardType, PollStatus} from "../../proto/eule_pb";
import {useTranslation} from "react-i18next";
import {Poll, selectPolls} from "../../slices/adminSlice";
import {AppDispatch} from "../../app/store";

export const LecturerResultComponent: React.FC<{ lecturerInfo: any }> = ({lecturerInfo}) => {
    const { t } = useTranslation();

    const dispatch: AppDispatch = useDispatch();
    const selectedEuleWinner = useSelector(selectSelectedEuleWinner);
    const results = useSelector(selectResults)
    const department = useSelector(selectCurrentDepartment) as string;
    const currentLecture = useSelector(selectCurrentLecture);
    const currentPollId = useSelector(selectCurrentPollId);
    const polls = useSelector(selectPolls);
    let evaluationIsClosed = true;
    const temporaryEuleWinner = results.lecturerResult.filter((lecturer: any) => lecturer.id === selectedEuleWinner)[0];

    if (currentPollId !== undefined && polls !== undefined) {
        const pollsWithEvaluationOpen = polls?.filter((value: Poll) => value.status === PollStatus.EVALUATION_OPEN);
        if (pollsWithEvaluationOpen?.length === 0) {
            evaluationIsClosed = true;
        } else {
            evaluationIsClosed = !(currentPollId === polls?.filter((value: Poll) => value.status === PollStatus.EVALUATION_OPEN)[0].pollId);
        }
    }
    return (lecturerInfo &&
        (<div>
            <div className="d-flex">
                <h2>{`${lecturerInfo.firstName} ${lecturerInfo.lastName}`}</h2>
                <div className="ml-auto">
                    <p>{t("resultsPage.currentlySelectedWinner")}:
                        <i>{temporaryEuleWinner ? ` ${temporaryEuleWinner.firstName} ${temporaryEuleWinner.lastName}` : t("resultsPage.noWinnerSelected")}</i>
                    </p>
                    <Button
                        onClick={() => dispatch(submitTemporaryWinner(department, lecturerInfo.id, AwardType.EULE))}
                        disabled={evaluationIsClosed}
                    >{t("resultsPage.selectForEule")}
                    </Button>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-6">
                    <p>{t("resultsPage.totalVotes")}: {lecturerInfo.totalVotes}</p>
                    <p>{t("resultsPage.averageGrade")}: {Math.round(lecturerInfo.averageScore * 100) / 100}</p>
                </div>
                <div className="col-6">
                    <p>{t("resultsPage.ranking")} {department}: {lecturerInfo.rankDepartment}/{lecturerInfo.totalLecturersDepartment}</p>
                    <p>{t("resultsPage.rankingETH")}: {lecturerInfo.rankETH}/{lecturerInfo.totalLecturersETH}</p>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <h3>{t("resultsPage.wins")}</h3>
                    {lecturerInfo.previousWins.length === 0 && <p>{t("resultsPage.noWins")}</p>}
                    {lecturerInfo.previousWins.map((win: any) => (<p key={win.year}>{win.award}, {win.year}</p>))}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h3>{t("resultsPage.votes")}</h3>

                    <div style={{ width: "100%", height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                height={300}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                data={lecturerInfo.ratings}
                            >
                                <Tooltip />

                                <XAxis dataKey="key" />
                                <YAxis />
                                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                <Bar dataKey="value" fill="#009FE3" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
            <div className="row  mt-5">
                <div className="col-6">
                    <h3>{t("resultsPage.byDepartment")}</h3>
                    <div style={{ width: "100%", height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                height={300}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                data={lecturerInfo.avgByDepartment}
                            >
                                <Tooltip />
                                <XAxis dataKey="key" />
                                <YAxis />
                                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                <Bar dataKey="value" fill="#009FE3" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="col-6">
                    <h3>{t("resultsPage.byStatus")}</h3>
                    <div style={{ width: "100%", height: 300 }}>
                        <ResponsiveContainer>
                            <BarChart
                                height={300}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                data={lecturerInfo.avgByStudentStatus}
                            >
                                <Tooltip />
                                <XAxis dataKey="key" />
                                <YAxis domain={[0, 5]} type="number" tickCount={6} />
                                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                <Bar dataKey="value" fill="#009FE3" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
            {lecturerInfo.lectures.length > 0 && (
                <>
                    <div className="row mt-5">
                        <div className="col-12">
                            <h3>{t("resultsPage.lectures")}</h3>
                            {lecturerInfo.lectures.map((lecture: any) => {
                                return (
                                    <Button
                                        onClick={() => {
                                            dispatch(setCurrentLecture(lecture))
                                        }}
                                    >
                                        {lecture.title}
                                    </Button>
                                );
                            })}
                        </div>
                    </div>
                    {currentLecture ? (<LectureResultComponent data={currentLecture} />) : (<p>{t("resultsPage.nothingToShow")}</p>)}
                </>
            )}
        </div>)
    );
};
