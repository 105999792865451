import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthState } from "@vseth/auth";
import jwt_decode from "jwt-decode";

const initialState: AuthState = {
    isAuthenticated: false,
};

const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthState(state, { payload }: PayloadAction<AuthState>) {
            return payload;
        },
    },
});

export const { setAuthState } = auth.actions;

export default auth.reducer;

// selectors
type AuthSliceRoot = {
    auth: ReturnType<typeof auth.reducer>;
};

export const selectTokens = (state: AuthSliceRoot) => state.auth.tokens;
export const selectUserInfo = (state: AuthSliceRoot) => state.auth.userInfo;
export const selectIsAuthenticated = (state: AuthSliceRoot) => state.auth.isAuthenticated;

export const selectAccessToken = createSelector([selectTokens], (tokens) => {
    if (tokens) {
        return tokens.token;
    }
    return undefined;
});

const clientIdStaging = "vseth_staging_eule_backend";
const clientIdProd = "vseth_prod_eule_backend";

export const selectUserRoles = createSelector([selectUserInfo], (userInfo) => {
    if (
        userInfo &&
        userInfo.resource_access
    ) {
        if (userInfo.resource_access[clientIdProd]) {
            return userInfo.resource_access[clientIdProd].roles;
        }
        if (userInfo.resource_access[clientIdStaging]) {
            return userInfo.resource_access[clientIdStaging].roles;
        }
    }
    return [] as string[];
});

// Workaround to get these fields until supported by auth library
export const selectSubAndEmail = createSelector([selectTokens], (tokens) => {
    if (tokens === undefined)
        return { sub: "", email: "" };
    const decodedJwt = jwt_decode(tokens.idToken);
    return Object.assign({ sub: "", email: "" }, decodedJwt);
});
