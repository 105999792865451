import React from "react";
import BootstrapTable, {SelectRowProps} from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';

import {Job, setSelectedJob} from "../../slices/adminSlice";
import {timestampObjToDate} from "../../util/proto";
import {
    enumToStringJobName,
    enumToStringJobStatus,
    enumToStringStatus,
    enumToStringPollTimestampLabel
} from "../../util/enumConverters";
import {useDispatch} from "react-redux";

interface JobInTable extends Job {
    creation: string,
    update: string,
    statusText: string,
    pollStatusText: string,
    nameText: string,
    pollTimestampLabelText: string,
}

function compareIds(a: JobInTable, b: JobInTable){
    if(a.jobId < b.jobId){
        return 1;
    }
    if(a.jobId > b.jobId){
        return -1;
    }
    return 0;
}

export const JobsTable: React.FC <{data: Job[], isLoading: boolean}> = ({data, isLoading}) => {
    const dispatch = useDispatch();

    if (data === undefined || data.length === 0) {
        if (isLoading) {
            return <div>Data is loading</div>;
        }
        return <div>No jobs</div>;
    }

    const dataConverted = data.map((element) => ({
        ...element,
        nameText: enumToStringJobName(element.jobName),
        statusText: enumToStringJobStatus(element.status),
        creation: timestampObjToDate(element.createTime).toLocaleString(),
        update: timestampObjToDate(element.updateTime).toLocaleString(),
        pollStatusText: enumToStringStatus(element.pollStatus),
        pollTimestampLabelText: enumToStringPollTimestampLabel(element.pollTimestampLabel),
    })).sort(compareIds);

    const headerStyle = { backgroundColor: '#009FE3', color: 'white'};
    const columns = [
        { text: 'Job ID', dataField: 'jobId', sort: true, headerStyle: headerStyle},
        { text: 'Name', dataField: 'nameText', sort: true, headerStyle: headerStyle},
        { text: 'Creation', dataField: 'creation', sort: true, headerStyle: headerStyle},
        { text: 'Argument', dataField: 'argument', sort: true, headerStyle: headerStyle},
        { text: 'Status', dataField: 'statusText', sort: true, headerStyle: headerStyle},
        { text: 'Updated', dataField: 'update', sort: true, headerStyle: headerStyle},
        { text: 'Log', dataField: 'logMessage', sort: true, headerStyle: headerStyle},
        { text: 'Poll ID', dataField: 'pollId', sort: true, headerStyle: headerStyle},
        { text: 'Poll Status', dataField: 'pollStatusText', sort: true, headerStyle: headerStyle},
        { text: 'Relevant Poll Time', dataField: 'pollTimestampLabelText', sort: true, headerStyle: headerStyle},
    ];

    const handleOnSelect = (job: JobInTable, isSelect: boolean, rowIndex: number) => {
        if (isSelect) {
            dispatch(setSelectedJob(job));
        } else {
            dispatch(setSelectedJob(undefined));
        }
        return;
    }

    const selectRowProp: SelectRowProps<any> = {
        mode: "radio",
        clickToSelect: true,
        onSelect: handleOnSelect,
        headerColumnStyle: headerStyle,
    };

    return (
        <div style={{ maxWidth: '100%' }}>
            <BootstrapTable columns={columns}
                            data={dataConverted}
                            keyField='jobId'
                            pagination={paginationFactory({sizePerPageList:[7, 14, 21, 28]})}
                            selectRow={ selectRowProp }
                            />
        </div>
    );
};