import classNames from "classnames";
import React from "react";

interface _Props {
  max: number;
  value: number;
}

const Filled: React.FunctionComponent = () => {
  return <div className="progress__item progress__filled" />;
};

const Unfilled: React.FunctionComponent = () => {
  return <div className="progress__item" />;
};

export const _ProgressBar: React.FunctionComponent<_Props> = ({
  max,
  value,
}) => {
  const unfilled: React.ReactElement[] = [];
  const filled: React.ReactElement[] = [];

  for (let i = 0; i < value; i++) {
    filled.push(<Filled key={i} />);
  }

  for (let i = 0; i < max - value; i++) {
    unfilled.push(<Unfilled key={value + i} />);
  }
  return (
    <div className="progress__container">
      {filled}
      {unfilled}
    </div>
  );
};

export interface ProgressItem {
  onClick?: any;
  active?: boolean;
  filled?: boolean;
}

interface Props {
  items: ProgressItem[];
}

export const ProgressBar: React.FunctionComponent<Props> = ({ items }) => {
  return (
    <div className="progress__container">
      {items.map((item, i) => {
        return (
          <div
            key={i}
            onClick={item.onClick}
            className={classNames("progress__item", {
              progress__filled: item.filled,
              progress__active: item.active,
            })}
          />
        );
      })}
    </div>
  );
};
