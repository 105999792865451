import React from "react";

/**
 * Helper that wraps the child in a centered container
 * @param props
 */
export const Centered: React.FC<{}> = (props) => {
  return (
    <div className="d-flex justify-content-center" style={{ height: "80vh" }}>
      <div className="text-center my-auto">{props.children}</div>
    </div>
  );
};
